import React, { useEffect } from "react";
import { any } from "prop-types";

import Cover from "../components/cover";
import TwoColumns from "../ui-components/columns/two-columns";

import { Column } from "../ui-components/columns/components";
import { FullWidthVideo } from "../ui-components/videos";
import { DiscoverMoreImage } from "../ui-components/discover-more";
import { GigaTitle, LargeText, SmallTitle } from "../ui-components/texts";
import PeopleOfMasamune from "../components/people-of-masamune";
import { CuttingEdgeAnimation } from "../components/about-components";

import imageTopCover from "../media/images/about-page/1.jpg";
import imageBottomCover from "../media/images/about-page/5.jpg";
import previewVideo from "../media/images/home-page/preview-video.jpg";
import discoverMoreImage from "../media/images/about-page/legend-before.jpg";
import { useTranslate } from "../contexts/translate";

const About = ({
	data = [null, null, null, null, null],
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/approach");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.approach
		);
		document.title = "About - Cutting Edge Digital Creative Studio";
	}, [translations]);

	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Ylenia Gentile",
			image: require("../media/team-images/Ylenia Gentile.jpg").default,
			role: translations === null ? "" : translations.about.peoples[3].role,
			description:
				translations === null ? "" : translations.about.peoples[3].description,
		},
		{
			fullname: "Simone Ruiz",
			image: require("../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../media/team-images/Samuele Olindo.png").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
		/*{
			fullname: "Michela Giuffrida",
			image: require("../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			description:
				translations === null ? "" : translations.about.peoples[2].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			description:
				translations === null ? "" : translations.about.peoples[4].description,
		},*/
		{
			fullname: "Gabriele Lanzafame",
			image: require("../media/team-images/Gabriele Lanzafame.jpg").default,
			role: translations === null ? "" : translations.about.peoples[9].role,
			description:
				translations === null ? "" : translations.about.peoples[9].description,
		},
		{
			fullname: "Magda Sgarella",
			image: require("../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		},
		{
			fullname: "Denise Muci",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[10].role,
			description:
				translations === null ? "" : translations.about.peoples[10].description,
		},
		{
			fullname: "Davide Balzano",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[16].role,
			description:
				translations === null ? "" : translations.about.peoples[16].description,
		},
		{
			fullname: "Giuseppe Bussolari",
			image: require("../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Andrea Bolloni",
			image: require("../media/team-images/Andrea Bolloni.jpg").default,
			role: translations === null ? "" : translations.about.peoples[6].role,
			description:
				translations === null ? "" : translations.about.peoples[6].description,
		},
		{
			fullname: "Luca Colombo",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[19].role,
			description:
				translations === null ? "" : translations.about.peoples[19].description,
		},
		{
			fullname: "Simone Galli",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[20].role,
			description:
				translations === null ? "" : translations.about.peoples[20].description,
		},
		{
			fullname: "Samuele Cottone",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[21].role,
			description:
				translations === null ? "" : translations.about.peoples[21].description,
		},
		{
			fullname: "Lorenzo Olindo",
			image: require("../media/team-images/Lorenzo Olindo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[22].role,
			description:
				translations === null ? "" : translations.about.peoples[22].description,
		},
		{
			fullname: "Marco Laureanti",
			image: require("../media/team-images/Marco Laureanti.jpg").default,
			role: translations === null ? "" : translations.about.peoples[23].role,
			description:
				translations === null ? "" : translations.about.peoples[23].description,
		},
		{
			fullname: "Simone Scalia",
			image: require("../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
		/*{
			fullname: "Francesco Graziano",
			image: require("../media/team-images/Francesco Graziano.jpg").default,
			role: translations === null ? "" : translations.about.peoples[11].role,
			description:
				translations === null ? "" : translations.about.peoples[11].description,
		},*/
		{
			fullname: "Claudio Mammana",
			image: require("../media/team-images/Claudio Mammana.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[15].role,
			description:
				translations === null ? "" : translations.about.peoples[15].description,
		},
		{
			fullname: "Adriano Spadaro",
			image: require("../media/team-images/Adriano Spadaro.jpg").default,
			role: translations === null ? "" : translations.about.peoples[12].role,
			description:
				translations === null ? "" : translations.about.peoples[12].description,
		},
		{
			fullname: "Samir Kharrat",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[13].role,
			description:
				translations === null ? "" : translations.about.peoples[13].description,
		},
		{
			fullname: "Gabriele Nicolosi",
			image: require("../media/team-images/Gabriele Nicolosi.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[14].role,
			description:
				translations === null ? "" : translations.about.peoples[14].description,
		},
		{
			fullname: "Samuele Di Mauro",
			image: require("../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[17].role,
			description:
				translations === null ? "" : translations.about.peoples[17].description,
		},
	];

	return (
		<section className="about-page-component">
			<Cover imageTop={imageBottomCover} imageBottom={imageTopCover}>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">
						Cutting-edge
					</span>
					<span className="text-animation animation-delay-05s">people and</span>
					<span className="text-animation animation-delay-05s">approaches</span>
				</GigaTitle>
			</Cover>
			<TwoColumns className="theme-light full-height">
				<Column className="min-height-col mt--10 mt-phone-5">
					<SmallTitle>
						{translations === null ? "" : translations.about.section1}
					</SmallTitle>
				</Column>
				<Column className="min-height-col mb--10 mb-phone-5">
					<LargeText>
						{translations === null ? "" : translations.about.section2}
					</LargeText>
				</Column>
			</TwoColumns>
			<section className="theme-dark">
				<FullWidthVideo
					previewImage={previewVideo}
					title="Play reel"
					srcVideo={require("../media/videos/showreel.mp4").default}
				/>
			</section>
			<DiscoverMoreImage
				title={
					<span>
						From legend <br />
						to reality
					</span>
				}
				linkText="Discover more"
				linkPath="/legend"
				imageBefore={discoverMoreImage}
			/>
			<CuttingEdgeAnimation data={data} />
			<PeopleOfMasamune peoples={peoples} />
		</section>
	);
};

About.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default About;
