import React, { useEffect } from "react";
import "./lang-switch.scss";

import { useThemeContext } from "../../utils/theme-context";
import { useTranslate } from "../../contexts/translate";

const LangSwitch = ({ className }) => {
	const { colorLangDark } = useThemeContext();
	const { updateLang, lang } = useTranslate();

	useEffect(() => {
		document.addEventListener("scroll", () => {
			var winScroll =
				document.body.scrollTop || document.documentElement.scrollTop;
			var height =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;
			var scrolled = (winScroll / height) * 100;
			document.getElementById("myBar").style.height = scrolled + "%";
		});
	}, []);

	return (
		<span className={colorLangDark ? `${className} lang-switch dark` : `${className} lang-switch light`}>
			<span
				className={lang === "it-IT" ? "active" : null}
				onClick={() => updateLang("it-IT")}
			>
				IT
			</span>
			<span>{ className === 'mobile' ? '-' : '|' }</span>
			<span
				className={lang === "en-EN" ? "active" : null}
				onClick={() => updateLang("en-EN")}
			>
				EN
			</span>
		</span>
	);
};

export default LangSwitch;
