import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/panini/image-cover.png";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import { TitleWithScrollXImagesSection } from "./components-design";

const Papini = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/the-rebel-company");
		setNextPageName("The Rebel Company");
		document.title =
			"Papini Store - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Adriano Spadaro",
			image: require("../../media/team-images/Adriano Spadaro.jpg").default,
			role: translations === null ? "" : translations.about.peoples[12].role,
			description: translations === null ? "" : translations.about.peoples[12].description
		},
		{
			fullname: "Samir Kharrat",
			image: require("../../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[13].role,
			description:
				translations === null ? "" : translations.about.peoples[13].description,
		},
	];

	return (
		<div className="project-page project-page-papini">
			<CoverPorfolio
				background="#8c71a7"
				className="theme-dark"
				title="Papini Store"
				white={true}
				text={translations && translations.papini.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Papini Store"}
				services={[
					"Social Video",
				]}
				timeframe={"2022"}
				introduction={
					<>
						<LargeText>
							{translations && translations.papini.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.papini.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.papini.sections[0].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.papini.sections[0].text1}
					</LargeText>
					<LargeText>
						{translations && translations.papini.sections[0].text2}
					</LargeText>
				</ContainerBlock>

				<ContainerBlock className="fluid mt-5" style={{ padding: "0 50px" }}>
					<FullWidthVideo isImage={false} previewImage={"#8c71a7"} srcVideo={require('../../media/images/portfolio-images/panini/video-1.mp4').default} />
				</ContainerBlock>
			</section>


			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.papini.sections[1].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.papini.sections[1].text}
					</LargeText>
				</ContainerBlock>

				<Container>
					<div className="wrapper fluid">
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/panini/video-2.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/panini/video-3.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/panini/video-4.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Papini.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Papini;
