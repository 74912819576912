import styled, { css } from "styled-components";

import { phone } from "../../constants/screens";

export const Container = styled.section`
	a {
		text-decoration: none;
	}

	&.no-left-space {
		.wrapper {
			margin-left: 0;
			padding-left: 0;

			> div:first-of-type {
				padding-left: 0;
			}
		}
	}

	&.image-inside {
		> .wrapper {
			height: 100vh;
			width: 100%;
		}
	}

	.wrapper {
		height: 100%;
		padding: 0 20px;
		max-width: 1800px;
		width: 90%;
		margin: 0 auto;
		display: flex;

		&.no-column {
			flex-direction: column;
		}

		&.full {
			width: 100%;
		}

		&.mid-full {
			width: 95%;
			padding: 10px;
		}

		&.fluid {
			width: 90%;
		}

		&.mid {
			width: 75%;
		}

		&.small {
			width: 65%;
		}

		&.padding-project-page {
			padding: 0 10%;
		}

		&.align-items-center {
			align-items: center;
		}

		&.align-items-baseline {
			align-items: baseline;
		}
	}

	&.two-column {
		.wrapper > div {
			width: 50%;
		}
	}

	&.light {
		background: #fff;
		color: #000;
	}

	&.dark {
		background: #1a1a1a;
		color: #fff;
	}

	${phone} {
		&.image-inside {
			.wrapper {
				height: auto;

				div {
					width: 100%;
					&:first-of-type {
						padding-top: 50px !important;
					}

					&:last-of-type {
						padding-left: 0 !important;
						padding-top: 50px !important;
						padding-bottom: 50px !important;
					}
				}
			}
		}
		.wrapper {
			width: 100%;

			&:not(.flex-direction-row) {
				flex-direction: column !important;
				width: 100% !important;
			}
		}
	}
`;

export const ContainerBlock = styled.div`
	display: block;
	position: relative;
	width: 70%;
	max-width: 1800px;
	margin: 0 auto;
	height: 100%;

	&.h-auto {
		height: auto;
	}

	&.fluid {
		width: 90%;
	}

	&.mid {
		width: 75%;
	}

	&.small {
		width: 65%;
	}

	${phone} {
		width: 100% !important;
		padding: 0 35px !important;
	}
`;

const generateWidthsColumns = () => {
	let styles = ``;

	for (let i = 0; i <= 10; i++) {
		styles += `
			.w-${i * 10} {
				width: ${i * 10}%;
			}
		`;
	}

	return css`
		${styles}
	`;
};

export const Column = styled.div`
	padding: 50px;

	&.padding-0 {
		padding: 0;
	}

	img {
		width: 100%;
	}

	${generateWidthsColumns()};

	${phone} {
		padding: 15px;

		&:not(.mid-w):not(.icon-inline) {
			width: 100% !important;
		}

		&.icon-inline {
			width: 48px;
		}

		&.mid-w {
			width: 50% !important;
		}

		&.mb--10 {
			margin-bottom: 0;
		}

		&.mt--10 {
			margin-top: 0;
		}
	}
`;
