import React, { useEffect, useState } from 'react';
import { ImagesScrollImage, ImagesScrollWrapper } from './images-scroll.style';

const ImagesScroll = ({ images = [], selectedIndex = 0 }) => {
	return (
		<ImagesScrollWrapper>
			{
				images.map((item, index) => <ImagesScrollImage onClick={typeof item === 'object' ? () => window.location.href = item.link : null } className="images-scroll-image" style={{ transform: `translateY(-${selectedIndex * 100}%)` }} src={typeof item === 'object' ? item.image : item} alt="Masamune Scroll Image Item" key={index} />)
			}
		</ImagesScrollWrapper>
	)
};

export default ImagesScroll;