import { any } from "prop-types";
import React from "react";

import { CoverWrapper, CoverContainer } from "./components";

const Cover = ({
	children,
	imageTop = null,
	imageBottom = null,
	classNameImage1 = "",
	classNameImage2 = "",
}) => {
	return (
		<CoverWrapper className="theme-dark overflow-hidden">
			<CoverContainer>
				{imageTop !== null ? (
					<img
						className={`text-animation animation-delay-1s top-right-img ${classNameImage1}`}
						alt="Masamune Cover Image"
						src={imageTop}
					/>
				) : null}
				{imageBottom !== null ? (
					<img
						className={`text-animation animation-delay-1s bottom-left-img ${classNameImage2}`}
						alt="Masamune Cover Image"
						src={imageBottom}
					/>
				) : null}

				{children}
			</CoverContainer>
		</CoverWrapper>
	);
};

Cover.propTypes = {
	title: any,
	imageTop: any,
	imageBottom: any,
	classNameImage1: any,
	classNameImage2: any,
	children: any,
};

export default Cover;
