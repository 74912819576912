import React, { useState } from "react";

import { LargeText, SmallText } from "../../ui-components/texts";
import { Column, Container } from "../../ui-components/columns/components";
import { ImageWithText } from "../../ui-components/images";
import { CallToActionMedium } from "../../ui-components/links";
import { TogglesSection } from "../../ui-components/sections";

import imageAbsolute1 from "../../media/images/services-page/5.jpg";
import imageAbsolute2 from "../../media/images/services-page/2.jpg";
import imageAbsolute3 from "../../media/images/services-page/3.jpg";
import { any } from "prop-types";

export const SectionImagesServices = () => {
	return (
		<section className="theme-dark mid-padding overflow-hidden d-flex">
			<Container className="m-auto h-auto">
				<div className="wrapper align-items-center">
					<Column className="w-15">
						<SmallText className="white">
							GET THE EDGE
							<br /> YOU DESERVE.
							<br />— MASAMUNE. 17 - 22 ©
						</SmallText>
					</Column>
					<Column className="w-85">
						<Container>
							<div className="wrapper">
								<Column style={{ marginTop: 0 }}>
									<ImageWithText src={imageAbsolute1} alt="(01)" text="(01)" />
								</Column>
								<Column
									className="ms-phone-0 mt-phone-2"
									style={{ marginLeft: -100, marginTop: 150 }}
								>
									<ImageWithText
										src={imageAbsolute2}
										alt="(02)"
										text="(02)"
										align="end"
									/>
								</Column>
								<Column
									className="ms-phone-0 mt-phone-2"
									style={{ marginTop: 450, marginLeft: -1000 }}
								>
									<ImageWithText
										src={imageAbsolute3}
										alt="(03)"
										text="(03)"
										align="end"
									/>
								</Column>
							</div>
						</Container>
					</Column>
				</div>
			</Container>
		</section>
	);
};

export const SectionServicesMasamune = ({ translations }) => {
	const [activated, setActivated] = useState(0);

	const [className, setClassName] = useState("");

	const handleChangeActivated = (index) => {
		// close all
		setClassName("close-text-animation");
		// change
		setTimeout(() => {
			setActivated(index);
			setClassName("text-animation");
		}, 600);
		// open all
	};

	const [index, setIndex] = useState(null);

	return (
		<section className="theme-light big-padding light-section">
			<Container>
				<div className="wrapper">
					<Column className="w-50">
						<CallToActionMedium
							onClick={() => handleChangeActivated(0)}
							to="#"
							className={
								activated !== 0
									? "mb-2 black disabled min-font"
									: "mb-2 black min-font"
							}
						>
							Photo & Video Content
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => handleChangeActivated(1)}
							to="#"
							className={
								activated !== 1
									? "mb-2 black disabled min-font"
									: "mb-2 black min-font"
							}
						>
							Design
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => handleChangeActivated(2)}
							to="#"
							className={
								activated !== 2
									? "mb-2 black disabled min-font"
									: "mb-2 black min-font"
							}
						>
							Digital Marketing
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => handleChangeActivated(3)}
							to="#"
							className={
								activated !== 3
									? "mb-2 black disabled min-font"
									: "mb-2 black min-font"
							}
						>
							Digital Products
						</CallToActionMedium>
					</Column>
					<Column className="w-50">
						{activated === 0 ? (
							<>
								<LargeText className={className}>
									{translations === null
										? ""
										: translations.services.photo.description}
								</LargeText>
								<TogglesSection
									isOpen={index === 0}
									onChange={() => setIndex(index === 0 ? null : 0)}
									className={className}
									title={
										translations === null
											? ""
											: translations.services.photo.childs[0].title
									}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.photo.childs[0].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 1}
									onChange={() => setIndex(index === 1 ? null : 1)}
									className={className}
									title={
										translations === null
											? ""
											: translations.services.photo.childs[1].title
									}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.photo.childs[1].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 2}
									onChange={() => setIndex(index === 2 ? null : 2)}
									className={className}
									title={
										translations === null
											? ""
											: translations.services.photo.childs[2].title
									}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.photo.childs[2].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 3}
									onChange={() => setIndex(index === 3 ? null : 3)}
									className={className}
									title={
										translations === null
											? ""
											: translations.services.photo.childs[3].title
									}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.photo.childs[3].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 4}
									onChange={() => setIndex(index === 4 ? null : 4)}
									className={className}
									title={
										translations === null
											? ""
											: translations.services.photo.childs[4].title
									}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.photo.childs[4].description}
									</SmallText>
								</TogglesSection>
							</>
						) : null}
						{activated === 1 ? (
							<>
								<LargeText className={className}>
									{translations === null
										? ""
										: translations.services.design.description}
								</LargeText>
								<TogglesSection
									isOpen={index === 5}
									onChange={() => setIndex(index === 5 ? null : 5)}
									title={
										translations === null
											? ""
											: translations.services.design.childs[0].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.design.childs[0].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 6}
									onChange={() => setIndex(index === 6 ? null : 6)}
									title={
										translations === null
											? ""
											: translations.services.design.childs[1].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.design.childs[1].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 7}
									onChange={() => setIndex(index === 7 ? null : 7)}
									title={
										translations === null
											? ""
											: translations.services.design.childs[2].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.design.childs[2].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 8}
									onChange={() => setIndex(index === 8 ? null : 8)}
									title={
										translations === null
											? ""
											: translations.services.design.childs[3].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.design.childs[3].description}
									</SmallText>
								</TogglesSection>
							</>
						) : null}

						{activated === 2 ? (
							<>
								<LargeText className={className}>
									{translations === null
										? ""
										: translations.services.marketing.description}
								</LargeText>
								<TogglesSection
									isOpen={index === 9}
									onChange={() => setIndex(index === 9 ? null : 9)}
									title={
										translations === null
											? ""
											: translations.services.marketing.childs[0].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.marketing.childs[0].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 10}
									onChange={() => setIndex(index === 10 ? null : 10)}
									title={
										translations === null
											? ""
											: translations.services.marketing.childs[1].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.marketing.childs[1].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 11}
									onChange={() => setIndex(index === 11 ? null : 11)}
									title={
										translations === null
											? ""
											: translations.services.marketing.childs[2].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.marketing.childs[2].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 12}
									onChange={() => setIndex(index === 12 ? null : 12)}
									title={
										translations === null
											? ""
											: translations.services.marketing.childs[3].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.marketing.childs[3].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 13}
									onChange={() => setIndex(index === 13 ? null : 13)}
									title={
										translations === null
											? ""
											: translations.services.marketing.childs[4].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.marketing.childs[4].description}
									</SmallText>
								</TogglesSection>
							</>
						) : null}

						{activated === 3 ? (
							<>
								<LargeText className={className}>
									{translations === null
										? ""
										: translations.services.products.description}
								</LargeText>
								<TogglesSection
									isOpen={index === 14}
									onChange={() => setIndex(index === 14 ? null : 14)}
									title={
										translations === null
											? ""
											: translations.services.products.childs[0].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.products.childs[0].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 15}
									onChange={() => setIndex(index === 15 ? null : 15)}
									title={
										translations === null
											? ""
											: translations.services.products.childs[1].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.products.childs[1].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 16}
									onChange={() => setIndex(index === 16 ? null : 16)}
									title={
										translations === null
											? ""
											: translations.services.products.childs[2].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.products.childs[2].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 17}
									onChange={() => setIndex(index === 17 ? null : 17)}
									title={
										translations === null
											? ""
											: translations.services.products.childs[3].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.products.childs[3].description}
									</SmallText>
								</TogglesSection>
								<TogglesSection
									isOpen={index === 18}
									onChange={() => setIndex(index === 18 ? null : 18)}
									title={
										translations === null
											? ""
											: translations.services.products.childs[4].title
									}
									className={className}
								>
									<SmallText>
										{translations === null
											? ""
											: translations.services.products.childs[4].description}
									</SmallText>
								</TogglesSection>
							</>
						) : null}
					</Column>
				</div>
			</Container>
		</section>
	);
};

SectionServicesMasamune.propTypes = {
	translations: any,
};
