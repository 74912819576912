import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { ScrollXImages } from "../../ui-components/force-scroll-x";

// images
import imageCover from "../../media/images/portfolio-images/vitarmonyl/image-cover.jpeg";
import { useTranslate } from "../../contexts/translate";

const Vitarmonyl = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	useEffect(() => {
		setNextPageHref("/projects/max-boutique");
		setNextPageName("Max Boutique");
		document.title = 'Vitarmonyl - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Denise Muci",
			image: require("../../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[10].role,
			description:
				translations === null ? "" : translations.about.peoples[10].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Magda Sgarella",
			image: require("../../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		},
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#faf0db"
				title="Vitarmonyl"
				text={translations && translations.vitarmonyl.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Vitarmonyl"}
				services={["Digital Strategy"]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.vitarmonyl.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.vitarmonyl.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#f9e3b4" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<SmallTitle>{translations && translations.vitarmonyl.sections[0].title}</SmallTitle>
						</Column>
						<Column className="w-50">
							<LargeText>
								{translations && translations.vitarmonyl.sections[0].text1}
							</LargeText>
							<LargeText>
								{translations && translations.vitarmonyl.sections[0].text2}
							</LargeText>
							<LargeText>
								{translations && translations.vitarmonyl.sections[0].text3}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column>
							<ScrollXImages
								id="scroll-images-1"
								className="theme-light pt-100 no-padding light-section"
								style={{ background: "#f9e3b4" }}
								items={[
									{
										src: require("../../media/images/portfolio-images/vitarmonyl/image-1.jpg")
											.default,
									},
									{
										src: require("../../media/images/portfolio-images/vitarmonyl/image-2.jpg")
											.default,
									},
									{
										src: require("../../media/images/portfolio-images/vitarmonyl/image-3.jpg")
											.default,
									},
								]}
							/>
						</Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Vitarmonyl.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Vitarmonyl;
