import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { bgGrey, primary } from "../../constants/colors";
import { phone } from "../../constants/screens";

export const CallToActionGiga = styled(NavLink)`
	font-size: 96px;
	font-style: normal;
	font-weight: 400;
	line-height: 112px;
	letter-spacing: 0px;
	display: inline-block;
	text-decoration: none;

	&.white {
		color: #ffffff;

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: #fff;
			margin: 10px 0 0 auto;
			visibility: visible;
			transition: all 0.6s ease;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.black {
		color: ${primary};

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: ${primary};
			margin: 10px 0 0 auto;
			visibility: visible;
			transition: all 0.6s ease;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.disabled {
		color: ${bgGrey};

		&::after {
			content: "";
			height: 0;
			margin-top: 0;
		}
	}

	&.reverse {
		&::after {
			content: " ";
			display: block;
			height: 1px;
			background: #fff;
			margin: 10px auto 0 0;
			width: 1px;
			visibility: hidden;
			transition: all 0.6s ease;
		}

		&:hover {
			&::after {
				visibility: visible;
				width: 100%;
			}
		}
	}

	${phone} {
		font-size: 48px;
		line-height: normal;
	}
`;

export const CallToActionMedium = styled(NavLink)`
	font-size: 56px;
	white-space: nowrap;
	font-style: normal;
	font-weight: 400;
	line-height: 64px;
	letter-spacing: 0px;
	display: inline-block;
	text-decoration: none;
	transition: all 0.6s ease;

	@media (max-width: 1440px) {
		&.min-font {
			font-size: 44px;
		}
	}

	@media (max-width: 1140px) {
		&.min-font {
			font-size: 34px;
			line-height: 44px;
		}
	}

	&.white {
		color: #ffffff;

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: #fff;
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.black {
		color: ${primary};

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: ${primary};
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.disabled {
		color: ${bgGrey};

		&::after {
			content: "";
			height: 0;
			margin-top: 0;
		}
	}

	${phone} {
		font-size: 28px!important;
		line-height: normal!important;
		margin-bottom: 10px!important;
	}
`;

export const CallToActionRegular = styled(NavLink)`
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	letter-spacing: 0px;
	display: inline-block;
	text-decoration: none;

	&.white {
		color: #ffffff;

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: #fff;
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.black {
		color: ${primary};

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: ${primary};
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}
`;

export const CallToActionSmall = styled(NavLink)`
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0px;
	display: inline-block;
	text-decoration: none;

	&.white {
		color: #ffffff;

		&:after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: #fff;
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.black {
		color: ${primary};

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: ${primary};
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}
`;

export const CallToActionMicro = styled(NavLink)`
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 0px;
	display: inline-block;
	text-decoration: none;

	&.white {
		color: #ffffff;

		&:after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: #fff;
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}

	&.black {
		color: ${primary};

		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			background: ${primary};
			margin: 10px 0 0 auto;
			transition: all 0.6s ease;
			visibility: visible;
		}
	}

	&:hover {
		&::after {
			width: 1px;
			visibility: hidden;
		}
	}
`;
