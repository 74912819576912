import { any } from "prop-types";
import React from "react";
import { colorGrey3 } from "../../constants/colors";

import { Column, Container } from "../../ui-components/columns/components";
import { LargeText, MediumHeading } from "../../ui-components/texts";

export const EvidenceArticle = ({ link = "#", className = "", image, category, title }) => {
	return (
		<Container className="mb-5">
			<a href={link}>
				<div className="post-container ">
					
				
					<img src={image} alt={title} />
						<LargeText style={{ color: colorGrey3 }} dangerouslySetInnerHTML={{__html: category}}></LargeText>
						<MediumHeading style={{ color: '#1a1a1a', maxWidth:'90%' }} dangerouslySetInnerHTML={{__html: title}}></MediumHeading>
				
				</div>
			</a>
		</Container>
	);
};

EvidenceArticle.propTypes = {
	className: any,
	image: any,
	category: any,
	title: any,
};
