import React, { useEffect } from "react";

import { bgGrey } from "../constants/colors";
import { useTranslate } from "../contexts/translate";

import Cover from "../components/cover";
import {
	CareersOpenPositions,
	CareersScores,
} from "../components/careers-components";

import { GigaTitle, H2GigaTitle, LargeText } from "../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock
} from "../ui-components/columns/components";
import { ScrollXImages } from "../ui-components/force-scroll-x";

import imageTopCover from "../media/images/careers-page/2.jpg";
import imageBottomCover from "../media/images/careers-page/1.jpg";
import imageContent1 from "../media/images/careers-page/3.jpg";
import imageContent2 from "../media/images/careers-page/4.jpg";
import imageContent3 from "../media/images/careers-page/5.jpg";

const Careers = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/magazine");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.magazine
		);
		document.title = 'Careers - Cutting Edge Digital Creative Studio'
	}, [translations]);

	const imagesScrollX = [
		{ src: require("../media/images/careers-page/slide-1.jpg").default },
		{ src: require("../media/images/careers-page/slide-2.jpg").default },
		{ src: require("../media/images/careers-page/slide-3.jpg").default },
		{ src: require("../media/images/careers-page/slide-4.png").default },
		{ src: require("../media/images/careers-page/slide-5.png").default }
	];

	return (
		<section className="careers-page-component">
			<Cover
				classNameImage2="left-45"
				imageTop={imageBottomCover}
				imageBottom={imageTopCover}
			>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">Work</span>
					<span className="text-animation animation-delay-05s">with us</span>
				</GigaTitle>
			</Cover>

			<section className="theme-light mid-padding pb-0 light-section overflow-hidden">
				<Container>
					<div className="wrapper align-items-center">
						<Column className="w-50">
							<img className="mb-5" src={imageContent1} alt="Image content 1" />
							<img
								className="mb-5 ms-5"
								src={imageContent2}
								alt="Image content 2"
							/>
							<img className="mb-5" src={imageContent3} alt="Image content 3" />
						</Column>
						<Column className="w-50">
							<LargeText className="mb-5">
								{translations === null ? "" : translations.careers.section1}
							</LargeText>
							<LargeText className="mb-5">
								{translations === null ? "" : translations.careers.section2}
							</LargeText>
							<LargeText className="mb-5">
								{translations === null ? "" : translations.careers.section3}
							</LargeText>
						</Column>
					</div>
				</Container>

				<H2GigaTitle
					style={{ color: bgGrey }}
					className="pe-5 pt-5 pb-5 mb-0 text-end"
				>
					{translations === null ? "" : translations.careers.title}
				</H2GigaTitle>
			</section>

			<CareersScores
				translationsCore={
					translations === null ? "" : translations.careers.coreValues
				}
			/>
			<section className="theme-light mid-padding light-section overflow-hidden">
				<ContainerBlock className="fluid pe-0">
					<ScrollXImages className="mid-padding pb-0 dark-section" items={imagesScrollX} />
				</ContainerBlock>
			</section>
			<CareersOpenPositions />
		</section>
	);
};

export default Careers;
