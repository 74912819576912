import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/cips/image-cover.png";
import { ContainerBlock } from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

const Cips = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/projects/costumi-di-sicilia");
		setNextPageName("Costumi Di Sicilia");
		document.title = "CIPS - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		}
	];

	return (
		<div className="project-page project-page-cips">
			<CoverPorfolio
				background="#5999ff"
				className="theme-dark"
				title="CIPS"
				white={true}
				text={translations && translations.cips.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"CIPS"}
				services={["Brand Strategy", "Brand Identity", "Website"]}
				timeframe={"2022 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.cips.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.cips.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light small-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.cips.sections[0].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.cips.sections[0].text1}
					</LargeText>
					<LargeText>
						{translations && translations.cips.sections[0].text2}
					</LargeText>
				</ContainerBlock>
			</section>

			{/* video */}
			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<FullWidthVideo isImage={false} previewImage={"#0e15f5"} srcVideo={require('../../media/images/portfolio-images/cips/video.mp4').default} />
				</ContainerBlock>
			</section>

			<TitleWithScrollXImagesSection
				className="theme-dark"
				color={"#5999ff"}
				title={translations && translations.cips.sections[1].title}
				text={translations && translations.cips.sections[1].text}
				items={[ { src: require('../../media/images/portfolio-images/cips/gallery-1/1.png').default }, { src: require('../../media/images/portfolio-images/cips/gallery-1/2.png').default }, { src: require('../../media/images/portfolio-images/cips/gallery-1/3.png').default } ]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<img className="w-100" src={require('../../media/images/portfolio-images/cips/image-1.png').default} alt="CIPS Banner" />
				</ContainerBlock>
			</section>


			<TitleWithScrollXImagesSection
				color={"#5999ff"}
				className="theme-dark"
				title={translations && translations.cips.sections[2].title}
				text={translations && translations.cips.sections[2].text}
				items={[ { src: require('../../media/images/portfolio-images/cips/gallery-2/1.png').default }, { src: require('../../media/images/portfolio-images/cips/gallery-2/2.png').default }, { src: require('../../media/images/portfolio-images/cips/gallery-2/3.png').default } ]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Cips.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Cips;
