import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import "./app.scss";
import "./magic.min.css";
import "./ui-components/animations/masamune-animations.scss";

// components
import Navbar from "./components/navbar";
import Footer from "./components/footer";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Approach from "./pages/Approach";
import Services from "./pages/Services";
import Legend from "./pages/Legend";
import Careers from "./pages/Careers";
import TalentSourcing from "./pages/TalentSourcing";
import Magazine from "./pages/Magazine";

import Projects from "./pages/Projects";
import Ride from "./pages/Portfolio/Ride";
import USLecce from "./pages/Portfolio/USLecce";
import Cips from "./pages/Portfolio/Cips";
import Clinicsoul from "./pages/Portfolio/Clinicsoul";
import Gisira from "./pages/Portfolio/Gisira";
import EtnaDolce from "./pages/Portfolio/EtnaDolce";
import CorrereNaturale from "./pages/Portfolio/CorrereNaturale";
import Error404 from "./pages/Error404";
import Ohoskin from "./pages/Portfolio/Ohoskin";
import Rica from "./pages/Portfolio/Rica";
import Trident from "./pages/Portfolio/Trident";
import Micidial from "./pages/Portfolio/Micidial";
import Peasy from "./pages/Portfolio/Peasy";
import Ofit from "./pages/Portfolio/Ofit";
import Superstylin from "./pages/Portfolio/Superstylin";
import AntonioQuaglietta from "./pages/Portfolio/AntonioQuaglietta";
import Vitarmonyl from "./pages/Portfolio/Vitarmonyl";
import Omnama from "./pages/Portfolio/Omnama";
import BizAcademy from "./pages/Portfolio/BizAcademy";
import MaxBoutique from "./pages/Portfolio/MaxBoutique";
import WordlPadelStore from "./pages/Portfolio/WorldPadelStore";
import Livemefive from "./pages/Portfolio/Livemefive";
import Giglio from "./pages/Portfolio/Giglio";
import ScrollBar from "./components/scrollbar";
import LangSwitch from "./components/lang-switch";
import NeedHelp from "./components/need-help";
import Privacy from "./pages/Privacy";

import {
	detectedForcedScrollFooter,
	initScrollFooter,
	startScrollHandler,
} from "./utils/scroll-handlers";
import { useThemeContext } from "./utils/theme-context";
import CostumiDiSicilia from "./pages/Portfolio/CostumiDiSicilia";
import Papini from "./pages/Portfolio/Papini";
import TheRebelCompany from "./pages/Portfolio/TheRebelCompany";
import Raiontech from "./pages/Portfolio/Raiontech";
import Themu from "./pages/Portfolio/Themu";
import Capable from "./pages/Portfolio/Capable";
import Locomeal from "./pages/Portfolio/Locomeal";
import Tbg from "./pages/Portfolio/Tbg";
import Taormina from "./pages/Portfolio/Taormina";

const App = () => {
	const [nextPageName, setNextPageName] = useState("");
	const [nextPageHref, setNextPageHref] = useState("");

	const [forced, setForced] = useState(false);
	const [onFooter, setOnFooter] = useState(false);

	const {
		setColorIconDark,
		setColorScrollDark,
		setColorArrowDark,
		setColorNeedHelpDark,
		setColorLangDark,
	} = useThemeContext();

	/*const navigate = useNavigate();

	useEffect(() => {
		return initScrollFooter(setForced, setOnFooter);
	}, [onFooter]);

	useEffect(() => {
		return detectedForcedScrollFooter(forced, setForced, nextPageHref, setOnFooter, navigate)
	}, [forced]);
	*/

	useEffect(() => {
		startScrollHandler({
			detect: {
				setColorIconDark,
				setColorLangDark,
				setColorArrowDark,
				setColorNeedHelpDark,
				setColorScrollDark,
			},
			footer: { setOnFooter },
			horizontal: {},
		});
	}, []);

	return (
		<>
			<Navbar />
			<ScrollBar />
			<LangSwitch />
			<NeedHelp />
			<Routes>
				<Route
					exact
					path="/"
					element={
						<Home
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/about"
					element={
						<About
							data={[forced, setForced, onFooter, setOnFooter, nextPageHref]}
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/approach"
					element={
						<Approach
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/services"
					element={
						<Services
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/legend"
					element={
						<Legend
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/careers"
					element={
						<Careers
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/talent-sourcing"
					element={
						<TalentSourcing
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/magazine"
					element={
						<Magazine
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects"
					element={
						<Projects
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/us-lecce"
					element={
						<USLecce
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/clinicsoul"
					element={
						<Clinicsoul
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/asmundo-di-gisira"
					element={
						<Gisira
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/etna-dolce"
					element={
						<EtnaDolce
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/correre-naturale"
					element={
						<CorrereNaturale
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/ohoskin"
					element={
						<Ohoskin
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/rica"
					element={
						<Rica
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/trident"
					element={
						<Trident
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/micidial"
					element={
						<Micidial
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/peasy"
					element={
						<Peasy
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/ofit"
					element={
						<Ofit
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/superstylin"
					element={
						<Superstylin
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/antonio-quaglietta"
					element={
						<AntonioQuaglietta
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/vitarmonyl"
					element={
						<Vitarmonyl
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/omnama"
					element={
						<Omnama
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/biz-academy"
					element={
						<BizAcademy
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/max-boutique"
					element={
						<MaxBoutique
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/world-padel-store"
					element={
						<WordlPadelStore
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/giglio"
					element={
						<Giglio
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/cips"
					element={
						<Cips
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/costumi-di-sicilia"
					element={
						<CostumiDiSicilia
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/papini-store"
					element={
						<Papini
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/the-rebel-company"
					element={
						<TheRebelCompany
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/ride-5"
					element={
						<Ride
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/raiontech"
					element={
						<Raiontech
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/livemefive"
					element={
						<Livemefive
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/themu"
					element={
						<Themu
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/capable"
					element={
						<Capable
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/locomeal"
					element={
						<Locomeal
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/topbuildinggroup"
					element={
						<Tbg
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route
					exact
					path="/projects/taormina-caffè"
					element={
						<Taormina
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>

				<Route
					exact
					path="/privacy"
					element={
						<Privacy
							setNextPageHref={setNextPageHref}
							setNextPageName={setNextPageName}
						/>
					}
				/>
				<Route path="*" element={<Error404 />} />
			</Routes>
			<Footer
				nextPageName={nextPageName}
				nextPageHref={nextPageHref}
				forced={forced}
			/>
		</>
	);
};

export default App;
