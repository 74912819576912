import styled from "styled-components";

export const ImagesScrollWrapper = styled.div`
	width: 100%;
	position: relative;
	height: 0;
	padding-bottom: 100%;
	overflow: hidden;
`;

export const ImagesScrollImage = styled.img`
	width: 100%;
	object-fit: cover;
	transition: all .4s ease;
`;