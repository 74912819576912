import React, { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { GigaTitle, H2GigaTitle } from "../../ui-components/texts";

const PortfolioSection = ({ items = [] }) => {
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		let sections = gsap.utils.toArray(".panel");

		gsap.to(sections, {
			xPercent: -100 * (items.length - 1),
			ease: "none",
			scrollTrigger: {
				trigger: ".container-scroll",
				pin: true,
				scrub: 1.5,
				snap: 1 / (items.length - 1),
				// base vertical scrolling on how wide the container is so it feels more natural.
				end: "+=1500",
			},
		});
	}, []);

	return (
		<section className="portfolio-section theme-dark">
			<div className="container-scroll">
				{items.map((item, index) => {
					return (
						<section
							className="panel view-cursor"
							onClick={() => (window.location.href = item.link)}
							key={index}
						>
							<GigaTitle>{item.name}</GigaTitle>
							<img src={item.src} alt={item.name} />
						</section>
					);
				})}
			</div>
		</section>
	);
};

export default PortfolioSection;
