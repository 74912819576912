import styled from 'styled-components';

export const DiscoverMoreSection = styled.section`
	height: 100vh;
	padding: 100px 20px;
	max-width: 90%;
	margin: 0 auto;
	display: flex;

	div {
		width: 50%;
	}

	.body-column-position {
		position: relative;
		z-index: 2;
		left: 50px;
		bottom: 50px;
		margin-top: auto;
		color: #fff;

		a {
			color: #fff;
		}
	}

	.image-column-position {
		position: relative;
		z-index: 1;
		margin-left: -400px;
		img {
			width: calc(100% + 400px);
		}
	}
`;