import { any } from "prop-types";
import React from "react";
import { Column, Container } from "../../../ui-components/columns/components";
import { MediumTitle, SmallHeading } from "../../../ui-components/texts";

export const ThreeImagesSection = ({ color, images }) => {
	return (
		<section
			className="theme-dark images-portfolio-section"
			style={{ background: color }}
		>
			<img className="image-portfolio-start" src={images[0]} alt={"Image 1"} />
			<img className="image-portfolio-center" src={images[1]} alt={"Image 2"} />
			<img className="image-portfolio-end" src={images[2]} alt={"Image 3"} />
		</section>
	);
};

ThreeImagesSection.propTypes = {
	color: any,
	images: any,
};

export const ImageLeftWithText = ({ className = 'theme-dark', color, title, image, texts, white }) => {
	return (
		<section className={className} style={{ background: color }}>
			<Container className="image-inside">
				<div className="wrapper fluid align-items-center">
					<Column className="w-40">
						<img src={image} alt="Image" />
					</Column>
					<Column className="w-60">
						<MediumTitle className={white ? "white mb-5" : "mb-5"}>
							{title}
						</MediumTitle>
						{texts.map((item, index) => {
							return (
								<SmallHeading
									key={index}
									className={white ? "white mb-5" : "mb-5"}
								>
									{item}
								</SmallHeading>
							);
						})}
					</Column>
				</div>
			</Container>
		</section>
	);
};

ImageLeftWithText.propTypes = {
	color: any,
	title: any,
	image: any,
	texts: any,
	white: any,
};
