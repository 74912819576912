import { any } from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { icons } from "../icons-manager";

import { ForceScrollXSticky, SettingsScrollX } from "./components";

const ForceScrollX = ({ id = null, children, style = {}, className }) => {
	// useEffect(() => {
	// 	let div = horizontalScroll.current;
	// 	div.scrollLeft = 0;

	// 	let index = 0;
	// 	let lastScroll = 0;
	// 	let scrollValue = 0;
	// 	let scrollWidth = (div.scrollWidth - (div.children.length * 270) - (div.offsetWidth * 40 / 100));
	// 	let lastScrollTop = 0;

	// 	startScrollHandler({
	// 		detect: { setColorIconDark },
	// 		footer: {},
	// 		horizontal: {
	// 			index,
	// 			lastScroll,
	// 			scrollValue,
	// 			scrollWidth,
	// 			lastScrollTop
	// 		}
	// 	});
	// }, []);

	useEffect(() => {
		// todo update
		const slider = document.querySelector("#forced-scroll .image-wrapper");
		let isDown = false;
		let startX;
		let scrollLeft;

		if (slider !== null) {
			slider.addEventListener("mousedown", (e) => {
				isDown = true;
				slider.classList.add("active");
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener("mouseleave", () => {
				isDown = false;
				slider.classList.remove("active");
			});

			slider.addEventListener("mouseup", () => {
				isDown = false;
				slider.classList.remove("active");
			});

			slider.addEventListener("mousemove", (e) => {
				if (!isDown) return;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});

			slider.addEventListener("touchstart", (e) => {
				isDown = true;
				startX = e.changedTouches[0].pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener("touchmove", (e) => {
				if (!isDown) return;
				e.preventDefault();
				const x = e.changedTouches[0].pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});

			slider.addEventListener("touchmend", () => {
				isDown = false;
			});
		}

		return function cleanup() {
			if (slider !== null) {
				slider.removeEventListener("mousedown", () => {}, false);
				slider.removeEventListener("mouseleave", () => {}, false);
				slider.removeEventListener("mouseup", () => {}, false);
				slider.removeEventListener("mousemove", () => {}, false);
				slider.removeEventListener("touchstart", () => {}, false);
				slider.removeEventListener("touchmove", () => {}, false);
				slider.removeEventListener("touchend", () => {}, false);
			}
		};
	}, []);

	return (
		<SettingsScrollX
			style={style}
			id={id === null ? "forced-scroll" : id}
			className={`scroll-horizontal ${className}`}
		>
			{children}
		</SettingsScrollX>
	);
};

export const ScrollXImages = ({
	id,
	className,
	style = {},
	items = [],
	removeScroll = false,
}) => {
	const [indexSelected, setIndexSelected] = useState(0);
	const horizontalScrollRef = useRef();

	useEffect(() => {
		const slider = horizontalScrollRef.current;
		let isDown = false;
		let startX;
		let scrollLeft;

		if (slider !== null) {
			slider.addEventListener("mousedown", (e) => {
				isDown = true;
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener("mouseleave", () => {
				isDown = false;
			});

			slider.addEventListener("mouseup", () => {
				isDown = false;
			});

			slider.addEventListener("mousemove", (e) => {
				if (!isDown) return;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});

			slider.addEventListener("touchstart", (e) => {
				isDown = true;
				startX = e.changedTouches[0].pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener("touchmove", (e) => {
				if (!isDown) return;
				e.preventDefault();
				const x = e.changedTouches[0].pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});

			slider.addEventListener("touchmend", () => {
				isDown = false;
			});
		}

		return function cleanup() {
			if (slider !== null) {
				slider.removeEventListener("mousedown", () => {}, false);
				slider.removeEventListener("mouseleave", () => {}, false);
				slider.removeEventListener("mouseup", () => {}, false);
				slider.removeEventListener("mousemove", () => {}, false);
				slider.removeEventListener("touchstart", () => {}, false);
				slider.removeEventListener("touchmove", () => {}, false);
				slider.removeEventListener("touchend", () => {}, false);
			}
		};
	});

	return (
		<ForceScrollX
			style={style}
			className={`${className} no-padding`}
			changeTitlePortfolio={setIndexSelected}
			horizontalScroll={horizontalScrollRef}
			selectedIndex={indexSelected}
			lenItems={items.length}
		>
			<div className="full-vw wrapper p-0 w-100 scroll-horizontal-start">
				<div
					className="image-wrapper start-images-center align-items-center"
					id={id}
					ref={horizontalScrollRef}
				>
					{items.map((item, index) => {
						return (
							<div key={index} className="image-wrapper-portfolio">
								{item.type !== "video" ? (
									<img src={item.src} alt={"Image"} />
								) : (
									<video
										src={item.src}
										autoPlay={true}
										loop={true}
										playsInline={true}
									/>
								)}
							</div>
						);
					})}

					<img
						src={
							String(className).includes("light")
								? icons.arrow.light.right
								: icons.arrow.dark.right
						}
						style={{
							position: "absolute",
							bottom: "-42px",
							right: "0",
							width: "32px",
						}}
						alt="Scroll right"
					/>
				</div>
			</div>
		</ForceScrollX>
	);
};

export const TranslateScrollX = ({ items = [] }) => {
	return (
		<ForceScrollXSticky>
			<div
				className="wrapper-items translate-item-force"
				style={{ translate: "none" }}
			>
				{items.map((item, index) => {
					return (
						<div
							className="item-wrapper"
							style={{ minWidth: 700, maxWidth: 1200 }}
							key={index}
						>
							{item}
						</div>
					);
				})}
			</div>
		</ForceScrollXSticky>
	);
};

ForceScrollX.propTypes = {
	children: any,
	style: any,
	className: any,
};

TranslateScrollX.propTypes = {
	items: any,
};

ScrollXImages.propTypes = {
	className: any,
	id: any,
	style: any,
	items: any,
};

export default ForceScrollX;
