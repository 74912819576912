import React from "react";
import { PopupButton } from '@typeform/embed-react'

import "./need-help.scss";

import { useThemeContext } from "../../utils/theme-context";

const NeedHelp = () => {
	const { colorNeedHelpDark } = useThemeContext();

	return (
		<span className={colorNeedHelpDark ? `need-help dark` : `need-help light`}>
			<PopupButton id="egC3mNyH">
				Need help?
			</PopupButton>
		</span>
	);
};

export default NeedHelp;
