import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/raiontech/Raionfull.png";
import { ContainerBlock } from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

const Raiontech = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/costumi-di-sicilia");
		setNextPageName("Costumi Di Sicilia");
		document.title = "Raiontech - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
			//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		},
		{
			fullname: "Davide Balzano",
			image: require("../../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[16].role,
			description:
				translations === null ? "" : translations.about.peoples[16].description,
		},
	];

	return (
		<div className="project-page project-page-cips">
			<CoverPorfolio
				background="#ab61ff"
				className="theme-dark"
				title="Raiontech"
				white={true}
				text={translations && translations.raiontech.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Raiontech"}
				services={["Brand Strategy", "Brand Identity", "Development"]}
				timeframe={"2022 - 2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.raiontech.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.raiontech.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light small-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.raiontech.sections[0].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.raiontech.sections[0].text1}
					</LargeText>
					<LargeText>
						{translations && translations.raiontech.sections[0].text2}
					</LargeText>
				</ContainerBlock>
			</section>

			<TitleWithScrollXImagesSection
				className="theme-dark"
				color={"#ab61ff"}
				title={translations && translations.raiontech.sections[1].title}
				text={translations && translations.raiontech.sections[1].text}
				items={[
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion5.png")
							.default,
					},
				]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="mid">
					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/raiontech/raion-copertina.jpeg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/raiontech/video1.mp4")
								.default
						}
					/>
				</ContainerBlock>
			</section>

			<TitleWithScrollXImagesSection
				color={"#ab61ff"}
				className="theme-dark"
				title={translations && translations.raiontech.sections[2].title}
				text={translations && translations.raiontech.sections[2].text}
				items={[
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion3.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion_mobile.jpg")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/raiontech/Raion4.png")
							.default,
					},
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Raiontech.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Raiontech;
