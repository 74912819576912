import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from '@typeform/embed-react'

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import {
	LargeText, MediumText, MediumTitle,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

import { TitleWithScrollXImagesSection } from "./components-design";
import { useTranslate } from "../../contexts/translate";

const Giglio = ({ setNextPageHref = () => { }, setNextPageName = () => { } }) => {
	useEffect(() => {
		setNextPageHref("/projects/ohoskin");
		setNextPageName("Ohoskin");
		document.title = 'Giglio.com - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Adriano Spadaro",
			image: require("../../media/team-images/Adriano Spadaro.jpg").default,
			role: translations === null ? "" : translations.about.peoples[12].role,
			description:
				translations === null ? "" : translations.about.peoples[12].description,
		},
		{
			fullname: "Samir Kharrat",
			image: require("../../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[13].role,
			description:
				translations === null ? "" : translations.about.peoples[13].description,
		},
	];

	return (
		<div className="project-page project-page-giglio">
			<CoverPorfolio
				background="#fff"
				title="Giglio.com"
				text={translations && translations.giglio.intro}
				image={require("../../media/images/home-page/work-2.jpg").default}
			/>

			<DetailsProject
				client={"Giglio.com"}
				services={["Social Video"]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.giglio.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.giglio.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.giglio.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<section className="theme-light" style={{ background: "#fff" }}>
				<Container>
					<div className="wrapper fluid  pb-0">
						<Column>
							<MediumTitle>{translations && translations.giglio.sections[0].title}</MediumTitle>
							<LargeText>{translations && translations.giglio.sections[0].text}</LargeText>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid pb-5">
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/1.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/2.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/3.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
					</div>
				</Container>
			</section>

			<section className="theme-light" style={{ background: "#fff" }}>
				<Container>
					<div className="wrapper fluid pb-0">
						<Column>
							<MediumTitle>{translations && translations.giglio.sections[1].title}</MediumTitle>
							<LargeText>{translations && translations.giglio.sections[1].text}</LargeText>
						</Column>
					</div>
				</Container>

				<Container className="pb-5">
					<div className="wrapper fluid pb-5">
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/4.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/5.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/giglio/6.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
					</div>
				</Container>
				
			</section>
           
			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
			
		</div>
	);
};

Giglio.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Giglio;
