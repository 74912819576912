import styled, { keyframes } from "styled-components";
import { colorGrey3, primary } from "../../constants/colors";
import { phone } from "../../constants/screens";

export const FooterWrapper = styled.section`
	background: ${primary};
	padding-top: 400px;
	height: calc(100vh + 50px);
	// z-index: 999;
	// position: relative;

	${phone} {
		padding-top: 200px;
	}
`;

export const FooterWrapperCopy = styled.section`
	max-width: 75%;
	margin: 0 auto;

	${phone} {
		max-width: 80%;
	}
`;

export const FooterNextPageSection = styled.div`
	padding-bottom: 300px;

	p {
		color: #fff;
	}

	a {
		text-decoration: none;
		color: #fff;
	}

	${phone} {
		padding-bottom: 50px;
	}
`;

export const FooterRow = styled.div`
	padding-bottom: 50px;

	display: flex;
	flex-direction: row;
	align-items: flex-end;

	${phone} {
		flex-wrap: wrap;
	}
`;

export const FooterColumn = styled.div`
	min-width: 16.666666%;
	height: 100%;

	a,
	p {
		display: block;
		text-decoration: none;
		color: #fff;
		margin-bottom: 10px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0px;
	}

	${phone} {
		min-width: 100%;
		margin-bottom: 25px;
	}
`;

export const FooterBigColumn = styled.div`
	min-width: 50%;
	height: 100%;

	p {
		color: ${colorGrey3};
		margin-bottom: 10px;
	}
`;

const animationNextPage = keyframes`
	0% { transform: translateY(-10px); }
	50% { transform: translateY(-30px); }
	100% { transform: translateY(-10px); }
`;

export const FooterNextPage = styled.p`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: center;
	width: 100%;
	display: block;
	color: #fff;
	margin-bottom: 0;
	animation-name: ${animationNextPage};
	animation-duration: 2s;
	animation-iteration-count: infinite;
`;
