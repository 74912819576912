import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { primary } from "../../constants/colors";
import { phone, tablet } from "../../constants/screens";

export const NavbarWrapper = styled.nav`
	width: 100%;
	display: flex;
	padding: 20px;
	align-items: center;
	position: fixed;
	z-index: 9999;
	top: 0;

	// ${phone} {
	// 	padding: 10px 20px;
	// }
`;

export const NavbarMasamuneLogo = styled.a`
	width: 42px;
	position: relative;
	z-index: 9999;

	img {
		width: 100%;
	}

	// ${phone} {
	// 	width: 132px;
	// 	img {
	// 		width: 100%;
	// 	}
	// }
`;

export const NavbarPhoneIcon = styled.a`
	width: 32px;
	margin-left: 15px;
	position: relative;
	z-index: 9999;

	&:hover {
		cursor: pointer;
	}

	svg {
		width: 100%;

		&.light {
			path,
			rect {
				fill: ${primary};
			}
		}

		&.dark {
			path,
			rect {
				fill: #fff;
			}
		}
	}
`;

export const NavbarBody = styled.div`
	background: ${primary};
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 0;
	overflow: hidden;
	transition: all 0.6s ease;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0 100px;

	&.open {
		height: 100%;
		overflow: visible;
		padding-top: 100px;
		padding-bottom: 20px;
	}

	${phone} {
		.no-in-mobile {
			display: none!important;
		}

		padding: 0 50px;

		&.open {
			overflow: auto!important;
			padding-top: 75px;
		}
	}
`;

export const NavbarItem = styled(NavLink)`
	display: inline-block;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 48px;
	letter-spacing: 0px;
	color: #ffffff;
	text-decoration: none;
	margin-bottom: 20px;
	transition all .2s cubic-bezier(0.25, 0.13, 0.6, 0.63);

	&:after {
		content: " ";
		display: block;
		width: 1px;
		visibility: hidden;
		height: 1px;
		background: #fff;
		margin: 0;
		margin-top: 10px;
		transition: all .6s ease;
	}

	&:hover {
		color: #ffffff;

		&:after {
			width: 100%;
			visibility: visible;
		}
	}

	${phone} {
		font-size: 24px!important;
		line-height: normal;
		margin-bottom: 20px;
	}
`;

export const NavbarImagesWrapper = styled.div`
	overflow: hidden;
	height: 400px;

	@media (max-width: 1300px) {
		height: 300px;
	}

	${phone} {
		display: none;
	}
`;

export const NavbarImage = styled.img`
	width: auto;
	height: 400px;
	object-fit: cover;
	transition: all .3s ease;

	@media (max-width: 1300px) {
		height: 300px;
	}

	${phone} {
		display: none;
	}
`;
