import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText, SmallHeading, SmallTitle } from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import PeopleOfMasamune from "../../components/people-of-masamune";

// images
import imageCover from "../../media/images/portfolio-images/ohoskin/cover.jpg";
import { ImageLeftWithText } from "./components-content";
import { useTranslate } from "../../contexts/translate";

const Ohoskin = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/projects/micidial");
		setNextPageName("Micidial");
		document.title = 'Ohoskin - Cutting Edge Digital Creative Studio'
	}, []);

	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description:"",
		},
		{
			fullname: "Gabriele Nicolosi",
			image: require("../../media/team-images/Gabriele Nicolosi.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[14].role,
			description:
				translations === null ? "" : translations.about.peoples[14].description,
		},
		{
			fullname: "Luca Distefano",
			image: require("../../media/team-images/no-image.png").default,
			role: "Former Video Maker",
			description: "",
		},
	];

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#fbffed"
				title="Ohoskin"
				text={translations && translations.ohoskin.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Ohoskin"}
				services={["Social Video", "Photography"]}
				timeframe={"2020 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.ohoskin.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.ohoskin.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.ohoskin.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<ImageLeftWithText
				color="#A7B182"
				title={translations && translations.ohoskin.sections[0].title}
				texts={[
					translations && translations.ohoskin.sections[0].text
				]}
				image={
					require("../../media/images/portfolio-images/ohoskin/1.jpg").default
				}
				white={true}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff", paddingBottom: 1 }}
			>
				<ContainerBlock className="mid">
					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/ohoskin/preview.jpg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/ohoskin/ohoskin-1.mp4")
								.default
						}
					/>

					<SmallHeading className="mid-padding">
						{translations && translations.ohoskin.sections[1].text}
					</SmallHeading>

					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/ohoskin/preview-2.jpg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/ohoskin/ohoskin-2.mp4")
								.default
						}
					/>

					<SmallHeading className="mid-padding">
						{translations && translations.ohoskin.sections[2].text}
						sostenibilità.
					</SmallHeading>
				</ContainerBlock>
			</section>

			<section
				className="theme-light pt-0 mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="mid" style={{ padding: "0" }}>
					<SmallTitle>
						{translations && translations.ohoskin.sections[3].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.ohoskin.sections[3].text}
					</LargeText>
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding pt-4"
				style={{ background: "#fbffed" }}
			>
				<Container className="fluid" style={{ padding: "0 25px" }}>
					<div className="wrapper fluid p-1">
						<Column className="w-50">
							<img
								src={
									require("../../media/images/portfolio-images/ohoskin/image-1.jpg")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								className="mt-5"
								src={
									require("../../media/images/portfolio-images/ohoskin/image-2.jpg")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
						<Column className="w-50">
							<img
								src={
									require("../../media/images/portfolio-images/ohoskin/image-3.jpg")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								className="mt-5"
								src={
									require("../../media/images/portfolio-images/ohoskin/image-4.jpg")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
					</div>

					<div className="wrapper" style={{ paddingTop: 100 }}>
						<SmallHeading>
							{translations && translations.ohoskin.sections[4].text}
						</SmallHeading>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Ohoskin.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Ohoskin;
