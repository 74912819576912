import React, { useEffect, useState } from "react";
import { any } from "prop-types";
import { FullHeightSection } from "../ui-components/sections";

import { colorGrey3 } from "../constants/colors";

import Cover from "../components/cover";

import { touchWork } from "../utils";

import {
	GigaTitle,
	H2GigaTitle,
	MediumTitle,
	SmallHeading,
} from "../ui-components/texts";
import {
	Container,
	Column,
	ContainerBlock,
} from "../ui-components/columns/components";
import { CallToActionSmall } from "../ui-components/links";

import imageTopCover from "../media/images/magazine-page/1.jpg";
import imageBottom from "../media/images/talent-sourcing-page/2.jpg"
import { EvidenceArticle } from "../components/magazine-components";
import axios from "axios";

const Magazine = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const [posts, setPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]); 
	const [evidencePosts, setEvidencePosts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const getMediaInfo = async (link) => {
		const { data } = await axios.get(link, {
			headers: { "Content-Type": "application/json" },
		});

		if (data.length < 1) return null;

		return data[0];
	};

	const getCategoryInfo = async (id) => {
		const { data } = await axios.get(
			`https://blog.masamune.it/wp-json/wp/v2/categories/${id}`,
			{ headers: { "Content-Type": "application/json" } }
		);
		return data;
	};

	const getPosts = async () => {
		const { data } = await axios.get(
			"https://blog.masamune.it/wp-json/wp/v2/posts?_fields=id,title,link,categories,_links&per_page=100&orderby=date&order=desc&categories_exclude=9",
			{ headers: { "Content-Type": "application/json" } }
		);

		const items = [];

		for (const item of data) {
			const media = await getMediaInfo(item._links["wp:attachment"][0]["href"]);
			const category = await getCategoryInfo(item.categories[0]);

			if (media === null) continue;

			items.push({
				title: item.title.rendered,
				link: item.link,
				media: media.guid.rendered,
				category: category.name,
			});
		}

		setPosts(items);
	};

	const getEvidencePosts = async () => {
		const { data } = await axios.get(
			"https://blog.masamune.it/wp-json/wp/v2/posts?_fields=id,title,link,categories,_links&per_page=100&orderby=date&order=desc&categories=9",
			{ headers: { "Content-Type": "application/json" } }
		);

		const items = [];

		for (const item of data) {
			const media = await getMediaInfo(item._links["wp:attachment"][0]["href"]);
			const category = await getCategoryInfo(item.categories[0]);

			if (media === null) continue;

			items.push({
				title: item.title.rendered,
				link: item.link,
				media: media.guid.rendered,
				category: category.name,
			});
		}

		setEvidencePosts(items);
	};

	const getCategories = async () => {
		const { data } = await axios.get(
			"https://blog.masamune.it/wp-json/wp/v2/categories?exclude=9",
			{ headers: { "Content-Type": "application/json" } }
		);
		setCategories(data);
	};

	useEffect(() => {
		setNextPageHref("/");
		setNextPageName("Home Page");
		document.title = "Magazine - Cutting Edge Digital Creative Studio";

		// get posts from wp
		getPosts();
		getEvidencePosts();
		getCategories();
	}, []);

	useEffect(() => {
		
		const filtered = selectedCategory
		  ? posts.filter(post => post.category === selectedCategory)
		  : posts;
		setFilteredPosts(filtered);
	  }, [selectedCategory, posts]);

	  useEffect(() => {
		// if (deviceType() !== 'desktop') return;
		if (touchWork() || window.innerWidth <= 1150) return ;
	}, []);  
	

	return (
		<section className="magazine-page-component">
			<Cover imageTop={imageTopCover} imageBottom={imageBottom}>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">Ideas</span>
					<span className="text-animation animation-delay-05s">and News</span>
					<span className="text-animation animation-delay-05s">
						worth sharing
					</span>
				</GigaTitle>
			</Cover>

			{/*<section className="theme-light light-section mid-padding">
				<Container>
					{evidencePosts.map((item, idx) => {
						return (
							<a key={idx} href={item.link}>
								<div className="wrapper align-items-center">
									<Column className="w-50">
										<img src={item.media} alt={item.link} />
									</Column>
									<Column className="w-50">
										<SmallHeading style={{ color: colorGrey3 }} dangerouslySetInnerHTML={{__html: item.category}}></SmallHeading>
										<MediumTitle style={{ color: "#1a1a1a" }} dangerouslySetInnerHTML={{__html: item.title}}></MediumTitle>
									</Column>
								</div>
							</a>
						);
					})}
				</Container>
				</section>*/}

			{/*<section className="theme-light light-section min-padding pb-10">
				<ContainerBlock className="fluid">
					{
						categories.map((item, idx) => {
							return (
								<H2GigaTitle onClick={() => setSelectedCategory(item.name)} key={idx} className={idx % 2 === 0 ? "mb-10 ms-phone-0 ms-10 d-block" : "mb-10 ms-phone-0 ms-40 d-block"} style={{ color: '#111', textDecoration: 'none', marginBottom: 50}}>
									<span>0{idx+1}.</span> <span dangerouslySetInnerHTML={{__html: item.name}}></span>
								</H2GigaTitle>
							)
						})
					}
					{/* <H2GigaTitle className="mb-10 ms-phone-0 ms-10">
						<span>01.</span> TIPS & TRICK
					</H2GigaTitle>
					<H2GigaTitle className="mb-10 ms-phone-0 ms-40">
						<span>02.</span> DIGITAL UPDATES
					</H2GigaTitle>
					<H2GigaTitle className="mb-10 ms-phone-0 ms-20">
						<span>03.</span> FOOD FOR THOUGHTS
					</H2GigaTitle>
					<H2GigaTitle className="mb-10 ms-phone-0 ms-30">
						<span>04.</span> LIFE AT THE STUDIO
					</H2GigaTitle> 
				</ContainerBlock>
			</section>*/}
		   
	 
			
		   <div className="projects-page-component projects-wrapper" style={{ width: "100%", background: '#f1f1f1' }}>
		   {
					touchWork() || window.innerWidth <= 1150 ?
						<section className="theme-light post-list" >
							<div className="post-filters">
								<span>Filtra per</span>
								<div className="items">
												<button onClick={() => setSelectedCategory(null)}>
													<span className={selectedCategory === null ? "active" : null}>Tutti</span>
												</button>
												{categories.map((item, index) => (
													<button
														key={index}
														onClick={() => setSelectedCategory(item.name)}
													>
														<span className={selectedCategory === item.name ? "active" : null} dangerouslySetInnerHTML={{__html: item.name}}></span>
													</button>
												))}
											</div>
							</div>
							
							{filteredPosts.map((item, idx) => {
											return (
												<EvidenceArticle
													key={idx}
													className="add-margin link-portfolio"
													link={item.link}
													image={item.media}
													title={item.title}
													category={item.category}
												/>
											);
										})}
						</section>
						
						
							
								
						: 
						        <FullHeightSection className='d-flex theme-light' style={{ background: '#f1f1f1', height:'100%' }}>
						            <Container >
										<div className="post-filters">
											<span>Filtra per</span>
											<div className="items">
												<button className={selectedCategory === null ? "active" : null} onClick={() => setSelectedCategory(null)}>
													Tutti
												</button>
												{categories.map((item, index) => (
													<button
														key={index}
														
														onClick={() => setSelectedCategory(item.name)}
													>
														<span className={selectedCategory === item.name ? "active" : null} dangerouslySetInnerHTML={{__html: item.name}}></span>
													</button>
												))}
											</div>
										</div>
										<div style={{display:'flex', flexWrap:'wrap', /*margin: '0 80px'*/ }}>
										{filteredPosts.map((item, idx) => {
											return (
												<div style={{ marginTop: idx % 2 === 1 ? '100px' : '0',  width:'50%'}} key={idx}>
												<EvidenceArticle
													
													className="no-left-space"
													link={item.link}
													image={item.media}
													title={item.title}
													category={item.category}
													
												/>
												</div>
											);
										})}
										</div>
									</Container>
								</FullHeightSection>
							
						
						
                }
            </div>
			{/*{ touchWork() ? null : <div className='disabled-on-mobile' style={{  width: '100%', height: 1000 }}></div> }*/}
		</section>
	
	);
};

Magazine.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Magazine;
