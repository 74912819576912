import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText, SmallTitle } from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/us-lecce/image-cover.jpg";
import testSquare from "../../media/test-image-square.jpg";
import { ThreeImagesWithDescription } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";
import { useTranslate } from "../../contexts/translate";

const Peasy = ({ setNextPageHref = () => { }, setNextPageName = () => { } }) => {
	useEffect(() => {
		setNextPageHref("/projects/rica");
		setNextPageName("Rica");
		document.title = 'Peasy - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	// const peoples = [
	// 	{
	// 		image: testSquare,
	// 		fullname: "Andrea Anfuso",
	// 		role: "CEO & Founder",
	// 		description:
	// 			"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo odit consectetur reprehenderit recusandae facilis, voluptate minima fuga reiciendis possimus inventore molestias distinctio earum unde commodi atque dignissimos. Placeat, unde a!",
	// 	}
	// ];

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#ffe7eb"
				title="Peasy"
				text={translations && translations.peasy.intro}
				image={require('../../media/images/portfolio-images/peasy/image-cover.jpg').default}
			/>

			<DetailsProject
				client={"Peasy"}
				services={["Brand Identity", "E-commerce"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.peasy.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.peasy.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.peasy.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<ThreeImagesWithDescription
				color="#FFC0CB"
				imageLeft={require('../../media/images/portfolio-images/peasy/image-1.jpg').default}
				images={[{ src: require('../../media/images/portfolio-images/peasy/gallery/1.jpg').default }, { src: require('../../media/images/portfolio-images/peasy/gallery/2.jpg').default }, { src: require('../../media/images/portfolio-images/peasy/gallery/3.jpg').default }]}
				title={translations && translations.peasy.sections[0].title}
				texts={[
					translations && translations.peasy.sections[0].text
				]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<SmallTitle>{translations && translations.peasy.sections[1].title}</SmallTitle>
							<LargeText>
								{translations && translations.peasy.sections[1].text}
							</LargeText>
						</Column>
						<Column className="w-50">
							<img src={require('../../media/images/portfolio-images/peasy/image-2.jpg').default} alt="" />
						</Column>
					</div>
				</Container>

				<ContainerBlock className="fluid mt-5" style={{ padding: '0 50px' }}>
					<FullWidthVideo srcVideo={require('../../media/images/portfolio-images/peasy/video.mp4').default} previewImage={require('../../media/images/portfolio-images/peasy/preview-video.jpg').default} />
				</ContainerBlock>
			</section>
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Peasy.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Peasy;
