import { any } from "prop-types";
import React from "react";
import { Column, Container } from "./components";

const TwoColumnsWithImage = ({
	className = "",
	dark = false,
	srcImage = null,
	reverse = false,
	alignItems = "center",
	wrapperClassName = "",
	columnClassName = "",
	columnImageClassName = "",
	children,
	classImageEffect = "",
}) => {
	return (
		<Container
			className={
				dark ? `two-column dark ${className}` : `two-column light ${className}`
			}
		>
			<div
				className={`wrapper ${wrapperClassName}`}
				style={{
					flexDirection: reverse ? "row-reverse" : "row",
					alignItems: alignItems,
				}}
			>
				<Column className={columnImageClassName}>
					<img
						className={`image-full-width ${classImageEffect}`}
						src={srcImage}
						alt="Image"
					/>
				</Column>
				<Column className={columnClassName}>{children}</Column>
			</div>
		</Container>
	);
};

TwoColumnsWithImage.propTypes = {
	className: any,
	dark: any,
	srcImage: any,
	reverse: any,
	alignItems: any,
	wrapperClassName: any,
	columnClassName: any,
	columnImageClassName: any,
	children: any,
	classImageEffect: any,
};

export default TwoColumnsWithImage;
