import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText } from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

// images
import imageCover from "../../media/images/portfolio-images/ofit/image-cover.jpg";

import {
	TitleWithScrollXImagesSection,
} from "./components-design";
import { useTranslate } from "../../contexts/translate";

const Ofit = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/projects/antonio-quaglietta");
		setNextPageName("Antonio Quaglietta");
		document.title = 'Ofit - Cutting Edge Digital Creative Studio'
	}, []);

	const peoples = [
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#f9ebda"
				title="Ofit"
				text={translations && translations.ofit.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Ofit"}
				services={["Brand Identity", "Photography"]}
				timeframe={"2019"}
				introduction={
					<>
						<LargeText>
							{translations && translations.ofit.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.ofit.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.ofit.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				className={'theme-dark'}
				color={"#fbce96"}
				title={translations && translations.ofit.sections[0].title}
				text={translations && translations.ofit.sections[0].text}
				items={[
					{ src: require('../../media/images/portfolio-images/ofit/image-1.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/image-2.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/image-3.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/image-4.jpg').default },
				]}
			/>

			<TitleWithScrollXImagesSection
				color={"#fff"}
				white={false}
				className="mb-0 pb-0 theme-light"
				title={translations && translations.ofit.sections[1].title}
				text={translations && translations.ofit.sections[1].text}
				items={[
					{ src: require('../../media/images/portfolio-images/ofit/gallery/1.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/gallery/2.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/gallery/3.jpg').default },
					{ src: require('../../media/images/portfolio-images/ofit/gallery/4.jpg').default },
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Ofit.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Ofit;
