import React, { useEffect } from "react";
import "./scrollbar.scss";

import { useThemeContext } from "../../utils/theme-context";
import { icons } from '../../ui-components/icons-manager';

const ScrollBar = () => {
	const { colorScrollDark, colorArrowDark } = useThemeContext();

	useEffect(() => {
		document.addEventListener("scroll", () => {
			var winScroll =
				document.body.scrollTop || document.documentElement.scrollTop;
			var height =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;
			var scrolled = (winScroll / height) * 100;
			document.getElementById("myBar").style.height = scrolled + "%";
		});
	}, []);

	return (
		<div className="progress-bar-wrapper">
			<span
				className={
					colorScrollDark ? `progress-container dark` : `progress-container light`
				}
			>
				<span className="progress-bar" id="myBar"></span>
			</span>
			<img src={colorArrowDark ? icons.arrow.dark.down : icons.arrow.light.down} alt="Scroll down" />
		</div>
	);
};

export default ScrollBar;
