import styled from 'styled-components';


export const CoverPorfolioWrapper = styled.section`
	background: #fff;
	width: 100%;
	padding: 100px 10%;
	padding-bottom: 100px;
`;

export const CoverPorfolioImage = styled.img`
	width: 100%;
	margin-top: 25px;
`;