import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeHeading, LargeText, SmallTitle } from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { ScrollXImages } from "../../ui-components/force-scroll-x";

// images
import imageCover from "../../media/images/portfolio-images/etna-dolce/image-cover.jpg";

import image3 from "../../media/images/portfolio-images/etna-dolce/image-3.jpg";
import { useTranslate } from "../../contexts/translate";

const EtnaDolce = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	useEffect(() => {
		setNextPageHref("/projects/superstylin");
		setNextPageName("Superstylin");
		document.title = 'Etna Dolce - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
	];

	const itemsList1 = [
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-1.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-2.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-3.jpg")
				.default,
		},
	];

	const itemsList2 = [
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-4.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-5.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-6.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/etna-dolce/gallery-7.jpg")
				.default,
		},
	];

	return (
		<div className="project-page project-page-us-lecce overflow-hidden">
			<CoverPorfolio
				background="#fff"
				title="Etna Dolce"
				text={translations && translations.etnadolce.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Etna Dolce"}
				services={[
					"Brand Identity",
					"Corporate Identity",
					"Packaging Design",
					"Photography",
				]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.etnadolce.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.etnadolce.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.etnadolce.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<SmallTitle>
								{translations && translations.etnadolce.sections[0].title}
							</SmallTitle>
						</Column>
						<Column className="w-50">
							<LargeText>
								{translations && translations.etnadolce.sections[0].text1}
							</LargeText>
							<LargeText>
								{translations && translations.etnadolce.sections[0].text2}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container className="pt-50 pb-50">
					<div className="wrapper fluid align-items-center">
						<Column className="w-100">
							<img src={image3} alt="image 3" className="w-100" />
						</Column>
					</div>
				</Container>

				<Container className="pt-50">
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<img
								src={
									require("../../media/images/portfolio-images/etna-dolce/left-1.jpg")
										.default
								}
								alt="image 6"
								className="w-100"
							/>
						</Column>

						<Column className="w-50">
							<LargeText>
								{translations && translations.etnadolce.sections[1].text}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container className="pt-50">
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<LargeText>
								{translations && translations.etnadolce.sections[2].text}
							</LargeText>
						</Column>
						<Column className="w-50">
							<img
								src={
									require("../../media/images/portfolio-images/etna-dolce/right-1.jpg")
										.default
								}
								alt="image 6"
								className="w-100"
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#f5e5d5" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-80">
							<LargeHeading>{translations && translations.etnadolce.sections[3].title}</LargeHeading>
							<LargeText>
								{translations && translations.etnadolce.sections[3].text1}
							</LargeText>
							<LargeText>
								{translations && translations.etnadolce.sections[3].text2}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column>
							<ScrollXImages
								id="scroll-images-1"
								className="theme-light pt-0 no-padding light-section"
								style={{ background: "#f5e5d5" }}
								items={itemsList1}
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#f9f1e7" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-80">
							<LargeHeading>
								{translations && translations.etnadolce.sections[4].title}
							</LargeHeading>
							<LargeText>
								{translations && translations.etnadolce.sections[4].text1}
							</LargeText>
							<LargeText>
								{translations && translations.etnadolce.sections[4].text2}
							</LargeText>
						</Column>
					</div>
				</Container>

				<section
					style={{ background: "#f9f1e7" }}
				>
					<Container>
						<div className="wrapper fluid">
							<Column>
								<ScrollXImages
									id="scroll-images-2"
									className="theme-light pt-0 no-padding light-section"
									style={{ background: "#f9f1e7" }}
									items={itemsList2}
								/>
							</Column>
						</div>
					</Container>
				</section>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

EtnaDolce.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default EtnaDolce;
