import React, { useState, useRef } from "react";
import {
	NavbarMasamuneLogo,
	NavbarWrapper,
	NavbarPhoneIcon,
	NavbarBody,
	NavbarItem
} from "./components";

// icons
import { icons } from "../../ui-components/icons-manager";
import { SmallText } from "../../ui-components/texts";
import { CallToActionSmall } from "../../ui-components/links";
import { useThemeContext } from "../../utils/theme-context";
import { useTranslate } from "../../contexts/translate";
import ImagesScroll from "../images-scroll";
import { useNavigate } from "react-router-dom";
import LangSwitch from "../lang-switch";

const Navbar = () => {
	const { colorIconDark, setColorIconDark } = useThemeContext();
	const imageWrapper = useRef();

	const { translations } = useTranslate();

	const links = [
		{
			path: "/about",
			name: translations === null ? "" : translations.navbar.links.about,
			image: require("../../media/images/navbar/about.jpg").default,
		},
		{
			path: "/approach",
			name: translations === null ? "" : translations.navbar.links.approach,
			image: require("../../media/images/navbar/approach.jpg").default,
		},
		{
			path: "/services",
			name: translations === null ? "" : translations.navbar.links.services,
			image: require("../../media/images/navbar/services.jpg").default,
		},
		{
			path: "/projects",
			name: translations === null ? "" : translations.navbar.links.works,
			image: require("../../media/images/navbar/works.jpg").default,
		},
		{
			path: "/talent-sourcing",
			name:
				translations === null ? "" : translations.navbar.links.talentSourcing,
			image: require("../../media/images/navbar/talent-sourcing.jpg").default,
		},
		{
			path: "/careers",
			name: translations === null ? "" : translations.navbar.links.careers,
			image: require("../../media/images/navbar/careers.jpg").default,
		},
		{
			path: "/magazine",
			name: translations === null ? "" : translations.navbar.links.magazine,
			image: require("../../media/images/navbar/magazine.jpg").default,
		},
	];
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [lastColor, setLastColor] = useState(null);

	const [imageIndex, setImageIndex] = useState(0);

	const navigate = useNavigate();

	const handleToggleMenu = () => {
		if (!navbarOpen) {
			setLastColor(colorIconDark);
			setColorIconDark(false);
		} else {
			setColorIconDark(lastColor);
		}

		setNavbarOpen(!navbarOpen);
	};

	return (
		<NavbarWrapper>
			<NavbarMasamuneLogo
				onClick={() => {
					setNavbarOpen(false);
					window.scroll({ top: 0, left: 0, behavior: "auto" });
				}}
				href="/"
			>
				<img
					src={
						colorIconDark
							? icons.brands.logoMasamuneSmallBlack
							: icons.brands.logoMasamuneSmallWhite
					}
					alt="logo masamune"
				/>
			</NavbarMasamuneLogo>

			<NavbarBody className={navbarOpen ? "open" : "close"}>
				<div className="row">
					<div className="col-md-4">
						{links.map((item, index) => {
							return (
								<div className="d-block" key={index}>
									<NavbarItem
										as="a"
										onMouseOver={() => setImageIndex(index)}
										className={navbarOpen ? "text-animation" : ""}
										onClick={(e) => {
											e.preventDefault();

											document.querySelector('body').style.overflow = 'auto';
											window.scroll({ top: 0, left: 0, behavior: "smooth" });
											setTimeout(() => {
												setNavbarOpen(false);
												window.location.href = item.path;
											}, 500)
										}}
										href={item.path}
									>
										{item.name}
									</NavbarItem>
								</div>
							)
						})}
					</div>
					<div className="col-md-4 col-0 no-in-mobile" style={{ paddingRight: '5%' }}>
						<ImagesScroll
							images={links.map((item) => item.image)}
							selectedIndex={imageIndex}
						/>
					</div>
					<div className="col-md-4 mt-phone-5 others-links">
						<SmallText
							className={navbarOpen ? "text-animation white" : "white"}
						>
							{translations === null ? "" : translations.navbar.links.legend}
						</SmallText>
						<CallToActionSmall
							className={navbarOpen ? "text-animation white" : "white"}
							to="/legend"
							onClick={() => {
								setNavbarOpen(false);
								window.scroll({ top: 0, left: 0, behavior: "smooth" });
							}}
						>
							{translations === null
								? ""
								: translations.navbar.links.legendText}
						</CallToActionSmall>

						<SmallText
							className={
								navbarOpen ? "text-animation white mt-5" : "white mt-5"
							}
						>
							{translations === null ? "" : translations.navbar.links.contact}
						</SmallText>
						<CallToActionSmall
							as="a"
							className={navbarOpen ? "text-animation white" : "white"}
							href="mailto:info@masamune.it"
							onClick={() => {
								setNavbarOpen(false);
								window.scroll({ top: 0, left: 0, behavior: "smooth" });
							}}
						>
							info@masamune.it
						</CallToActionSmall>
					</div>
				</div>
			</NavbarBody>

			{/* translate toggle */}
			<LangSwitch className="mobile" />

			<NavbarPhoneIcon onClick={handleToggleMenu}>
				{navbarOpen ? (
					<img
						className="float-right"
						src={
							colorIconDark
								? icons.navbar.navbarCloseIconBlack
								: icons.navbar.navbarCloseIcon
						}
						alt="close navbar"
					/>
				) : (
					<img
						className="float-right"
						src={
							colorIconDark
								? icons.navbar.navbarBurgerIconBlack
								: icons.navbar.navbarBurgerIcon
						}
						alt="open navbar"
					/>
				)}
			</NavbarPhoneIcon>
		</NavbarWrapper>
	);
};

export default Navbar;
