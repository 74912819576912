import { any } from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import { icons } from "../../ui-components/icons-manager";
import { SmallText } from "../../ui-components/texts";
import { CallToActionGiga } from "../../ui-components/links";
import { deviceType } from "../../utils";
import { useTranslate } from "../../contexts/translate";
import {
	FooterBigColumn,
	FooterColumn,
	FooterNextPageSection,
	FooterRow,
	FooterWrapper,
	FooterNextPage,
	FooterWrapperCopy,
} from "./components";

const Footer = ({ forced, nextPageHref, nextPageName }) => {
	const { translations } = useTranslate();

	return (
		<FooterWrapper className="theme-dark footer">
			<FooterWrapperCopy className="theme-dark footer">
				<FooterNextPageSection>
					<SmallText className={"animate-on-close"}>
						{translations === null ? "" : translations.footer.nextPage}
					</SmallText>
					<CallToActionGiga
						as="a"
						href={nextPageHref}
						className={"white animate-on-close"}
					>
						{nextPageName}
					</CallToActionGiga>
				</FooterNextPageSection>
				<FooterRow>
					<FooterColumn>
						{/*<SmallText className={"animate-on-close"}>
							{translations === null ? "" : translations.footer.openTo}
	                </SmallText>*/}
					</FooterColumn>
					<FooterColumn>
						<a
							className={"animate-on-close"}
							href="https://www.linkedin.com/company/masamune"
						>
							Linkedin
						</a>
						<a
							className={"animate-on-close"}
							href="https://www.instagram.com/masamune_independent/"
						>
							Instagram
						</a>
						<a
							className={"animate-on-close"}
							href="https://www.facebook.com/Masamuneindependentstudio "
						>
							Facebook
						</a>
					</FooterColumn>
					<FooterColumn>
						{/* <NavLink className={"animate-on-close"} to="/terms">
							{translations === null ? "" : translations.footer.terms}
						</NavLink> */}
						<a className={"animate-on-close"} href="/careers">
							Careers
						</a>
						<a className={"animate-on-close"} href="/privacy">
							Privacy Policy
						</a>
						<a
							className={"animate-on-close"}
							href="https://www.iubenda.com/termini-e-condizioni/32511240"
						>
							Termini e Condizioni
						</a>
					</FooterColumn>
					<FooterBigColumn>
						<SmallText className={"animate-on-close"}>
							{new Date().getFullYear()} Masamune s.r.l - All Rights Reserved -
							P.Iva 05800840877
						</SmallText>
					</FooterBigColumn>
				</FooterRow>
				{!forced && deviceType() === "desktop" ? (
					/*<FooterNextPage className={"animate-on-close destroy"}>
						{translations === null ? "" : translations.footer.scrolling}{" "}
						<img src={icons.arrow.light.down} alt="Go Down Scroll" />
					</FooterNextPage>*/
					<div></div>
				) : null}
			</FooterWrapperCopy>
		</FooterWrapper>
	);
};

Footer.propTypes = {
	forced: any,
	nextPageName: any,
};

export default Footer;
