import styled from "styled-components";
import { phone, tablet } from "../../constants/screens";

import cursorPlay from "../../media/components/play-video.svg";

export const VideoWrapperSettings = styled.section`
	display: block;
	background: #111;
	width: 100%;
	height: 100vh;
	transition: all 0.3s ease;
	position: relative;
	z-index: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	transition: all 0.3 ease;

	&.start {
		background-image: none !important;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999999999;

		h3 {
			opacity: 0;
		}

		video {
			opacity: 1;
		}
	}

	video {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		transition: all 1s ease;
	}

	h3 {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		opacity: 1;
		transition: all 1s ease;
	}

	&:hover {
		cursor: url(${cursorPlay}), pointer;
	}

	.close-video {
		font-size: 44px;
    color: #fff;
    position: absolute;
    top: 5px;
    right: 20px;
	}

	${tablet} {
		max-height: 400px;

		&.start {
			max-height: 100vh;
		}
	}
`;
