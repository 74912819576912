import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import {
	LargeText,
	MediumHeading,
	MediumTitle,
	SmallTitle,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/antonio-quaglietta/image-cover.jpg";
import image1 from "../../media/images/portfolio-images/antonio-quaglietta/image-1.jpeg";
import { useTranslate } from "../../contexts/translate";

const AntonioQuaglietta = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {

	useEffect(() => {
		setNextPageHref("/projects/vitarmonyl");
		setNextPageName("Vitarmonyl");
		document.title = 'Antonio Quaglietta - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Magda Sgarella",
			image: require("../../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#edf2f9"
				title="Antonio Quaglietta"
				text={translations && translations.quaglietta.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Antonio Quaglietta"}
				services={[
					"Digital Marketing",
					"Social Advertising",
					"E-mail Marketing",
				]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.quaglietta.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.quaglietta.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.quaglietta.descriptions[2]}
						</LargeText>
					</>
				}
			/>


			<section
				className="theme-light pb-1 mid-padding"
				style={{ background: "#c2cde0" }}
			>
				<Container>
					<div className="wrapper fluid align-items-center">
						<Column className="w-40">
							<img src={image1} alt="Antonio Quaglietta" />
						</Column>
						<Column className="w-60">
							<SmallTitle>{translations && translations.quaglietta.sections[0].title}</SmallTitle>
							<LargeText>
								{translations && translations.quaglietta.sections[0].text}
							</LargeText>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light pt-1 mid-padding"
				style={{ background: "#c2cde0" }}
			>
				<Container className="pt-50">
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 1095%</MediumTitle>
							<MediumHeading>ROAS</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>1,04%</MediumTitle>
							<MediumHeading>Click-through rate</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 884%</MediumTitle>
							<MediumHeading>Sales</MediumHeading>
						</Column>
						<Column className="w-40"></Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

AntonioQuaglietta.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default AntonioQuaglietta;
