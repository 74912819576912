import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { FullWidthVideo } from "../../ui-components/videos";
import {
	LargeHeading,
	LargeText,
	LargeTitle,
	MediumHeading,
	MediumTitle,
	SmallTitle,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { ScrollXImages } from "../../ui-components/force-scroll-x";

// images
import imageCover from "../../media/images/portfolio-images/superstylin/image-cover.jpg";
import { useTranslate } from "../../contexts/translate";

const Superstylin = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/peasy");
		setNextPageName("Peasy");
		document.title = 'Superstylin - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Magda Sgarella",
			image: require("../../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		},
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
	];

	const itemsList1 = [
		{
			src: require("../../media/images/portfolio-images/superstylin/gallery-1.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/superstylin/gallery-2.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/superstylin/gallery-3.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/superstylin/gallery-4.jpg")
				.default,
		},
	];

	return (
		<div className="project-page project-page-us-lecce overflow-hidden">
			<CoverPorfolio
				background="#fff"
				title="Superstylin'"
				text={translations && translations.superstylin.intro}
				image={imageCover}
			/>

			<DetailsProject
				client="Superstylin'"
				services={["CRO", "Digital Product", "Social Advertising"]}
				timeframe={"2019 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.superstylin.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.superstylin.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#e7ddfc" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-80">
							<LargeHeading>{translations && translations.superstylin.sections[0].title}</LargeHeading>
							<LargeText>
								{translations && translations.superstylin.sections[0].text}
							</LargeText>
						</Column>
					</div>
				</Container>

				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<ScrollXImages
						id="scroll-images-1"
						className="theme-light pt-100 no-padding light-section"
						style={{ background: "#e7ddfc" }}
						items={itemsList1}
					/>
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-100">
							<LargeTitle>{translations && translations.superstylin.sections[1].title}</LargeTitle>
							<MediumHeading>
								{translations && translations.superstylin.sections[1].text}
							</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container className="pt-50">
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 506,20%</MediumTitle>
							<MediumHeading>ROAS</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>2,51%</MediumTitle>
							<MediumHeading>Click-through rate</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 267%</MediumTitle>
							<MediumHeading>Orders</MediumHeading>
						</Column>
						<Column className="w-40"></Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Superstylin.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Superstylin;
