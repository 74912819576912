import styled from "styled-components";
import { phone } from "../../constants/screens";

import dragCursor from "../../media/icons/drag.svg";

export const SettingsScrollX = styled.section`
	position: relative;

	&:not(.no-padding) {
		padding-top: 150px;
		padding-bottom: 150px;

		.image-wrapper {
			padding-bottom: 150px;
		}
	}

	&:not(.no-cursor) {
		cursor: url(${dragCursor}), pointer;
	}

	&.auto-img-size {
		.wrapper {
			.image-wrapper {
				align-items: center;
				padding: 0;
				padding-left: 0 !important;

				> div {
					min-width: 200px;
					width: auto;
					margin-right: 20px;

					img {
						width: auto !important;
						object-fit: cover;
					}
				}
			}
		}
	}

	.wrapper {
		.image-wrapper {
			max-width: 100%;
			z-index: 2;
			display: flex;
			flex-direciton: row;
			overflow-y: hidden;
			overflow-x: hidden;

			&:not(.start-images-center) {
				// padding-left: 40%;
			}

			&.start-images-center {
				// padding-left: 25%;
			}

			> div {
				min-width: 750px;
				margin-right: 20px;

				img {
					width: 100%;
					max-width: 700px;
					object-fit: cover;
				}
			}
		}
	}

	${phone} {
		.wrapper {
			.image-wrapper {
				> div {
					min-width: 300px;
				}
			}
		}
	}
`;

export const ForceScrollXSticky = styled.div`
	width: 100%;
	overflow: hidden;

	.wrapper-items {
		width: 100%;
		display: flex;

		> div {
			min-width: 100%;
			margin-right: 250px;

			img {
				width: 100%;
				object-fit: cover;
			}
		}
	}
`;
