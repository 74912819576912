import logoMasamuneFullBlack from '../../media/icons/logo-masamune-full-black.svg';
import logoMasamuneSmallBlack from '../../media/icons/logo-masamune-small-black.svg';
import logoMasamuneFullWhite from '../../media/icons/logo-masamune-full-white.svg';
import logoMasamuneSmallWhite from '../../media/icons/logo-masamune-small-white.svg';

import chevronUpDark from '../../media/icons/chevron/dark/up.svg';
import chevronDownDark from '../../media/icons/chevron/dark/down.svg';
import chevronLeftDark from '../../media/icons/chevron/dark/left.svg';
import chevronRightDark from '../../media/icons/chevron/dark/right.svg';

import chevronUpWhite from '../../media/icons/chevron/light/up.svg';
import chevronDownWhite from '../../media/icons/chevron/light/down.svg';
import chevronLeftWhite from '../../media/icons/chevron/light/left.svg';
import chevronRightWhite from '../../media/icons/chevron/light/right.svg';

import arrowUpDark from '../../media/icons/arrow/dark/up.svg';
import arrowDownDark from '../../media/icons/arrow/dark/down.svg';
import arrowLeftDark from '../../media/icons/arrow/dark/left.svg';
import arrowRightDark from '../../media/icons/arrow/dark/right.svg';

import arrowUpWhite from '../../media/icons/arrow/light/up.svg';
import arrowDownWhite from '../../media/icons/arrow/light/down.svg';
import arrowLeftWhite from '../../media/icons/arrow/light/left.svg';
import arrowRightWhite from '../../media/icons/arrow/light/right.svg';

import navbarBurgerIcon from '../../media/icons/nav-burger-icon.svg';
import navbarCloseIcon from '../../media/icons/nav-close-icon.svg';

import navbarBurgerIconBlack from '../../media/icons/nav-burger-icon-black.svg';
import navbarCloseIconBlack from '../../media/icons/nav-close-icon-black.svg';

import morePlus from '../../media/icons/more-plus.svg';

export const icons = {
	brands: {
		logoMasamuneFullBlack: logoMasamuneFullBlack,
		logoMasamuneSmallBlack: logoMasamuneSmallBlack,
		logoMasamuneFullWhite: logoMasamuneFullWhite,
		logoMasamuneSmallWhite: logoMasamuneSmallWhite
	},
	morePlus: morePlus,
	chevron: {
		light: {
			up: chevronUpWhite,
			down: chevronDownWhite,
			left: chevronLeftWhite,
			right: chevronRightWhite
		},
		dark: {
			up: chevronUpDark,
			down: chevronDownDark,
			left: chevronLeftDark,
			right: chevronRightDark
		}
	},
	arrow: {
		circle: {
			left: require('../../media/icons/arrow/arrow-circle-left.svg').default,
			right: require('../../media/icons/arrow/arrow-circle-right.svg').default,
		},
		light: {
			up: arrowUpWhite,
			down: arrowDownWhite,
			left: arrowLeftWhite,
			right: arrowRightWhite
		},
		dark: {
			up: arrowUpDark,
			down: arrowDownDark,
			left: arrowLeftDark,
			right: arrowRightDark
		}
	},
	navbar: {
		navbarBurgerIcon: navbarBurgerIcon,
		navbarCloseIcon: navbarCloseIcon,
		navbarBurgerIconBlack: navbarBurgerIconBlack,
		navbarCloseIconBlack: navbarCloseIconBlack
	}
}