import { any } from "prop-types";
import React from "react";
import styled from "styled-components";

import { SmallText } from "../texts";

const ImageWithTextWrapper = styled.div`
	width: 100%;

	img {
		width: 100%;
	}
`;

export const ImageWithText = ({ src, alt, text, align = "start" }) => {
	return (
		<ImageWithTextWrapper>
			<img src={src} alt={alt} />
			<SmallText className={`white text-${align}`}>{text}</SmallText>
		</ImageWithTextWrapper>
	);
};

ImageWithText.propTypes = {
	src: any,
	alt: any,
	text: any,
	align: any,
};
