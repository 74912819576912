import React, { useState, useEffect } from "react";

const TranslateContext = React.createContext();

const TranslateProvider = (props) => {
	const [lang, setLang] = useState(null);
	const [translations, setTranslations] = useState(null);

	useEffect(() => {
		const getTranslations = () => {
			let data = null;

			if (lang !== "it-IT" && lang !== "en-EN") data = require(`../lang/it-IT.json`);
			else data = require(`../lang/${lang}.json`);

			setTranslations(data);
		};

		if (localStorage.getItem("lang") == null) {
			const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
			localStorage.setItem('lang', userLocale.includes('it') ? 'it-IT' : 'en-EN')
		}

		setLang(localStorage.getItem("lang"));
		getTranslations();
	}, [lang]);

	const updateLang = (nLang) => {
		localStorage.setItem("lang", nLang);
		setLang(nLang);
	};

	return (
		<TranslateContext.Provider
			value={{
				updateLang,
				lang,
				translations,
			}}
			{...props}
		/>
	);
};

const useTranslate = () => React.useContext(TranslateContext);
export { useTranslate, TranslateProvider };
