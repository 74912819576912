import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/themu/image-cover.png";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import { TitleWithScrollXImagesSection } from "./components-design";

const Themu = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/talent-sourcing");
		setNextPageName("Talent Sourcing");
		document.title = "Themu - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../../media/team-images/Samuele Olindo.png").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
		{
			fullname: "Claudio Mammana",
			image: require("../../media/team-images/Claudio Mammana.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[15].role,
			description:
				translations === null ? "" : translations.about.peoples[15].description,
		},
	];

	return (
		<div className="project-page project-page-papini">
			<CoverPorfolio
				background="#EFDDDB"
				className="theme-dark"
				title="Themu"
				text={translations && translations.themu.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Policlinico Gemelli"}
				services={["UI/UX Design, Mobile development"]}
				timeframe={"2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.themu.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.themu.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				removeScroll={true}
				className="theme-dark"
				color={"#EFDDDB"}
				white={false}
				title={translations && translations.themu.sections[0].title}
				text={translations && translations.themu.sections[0].text1}
				text2={translations && translations.themu.sections[0].text2}
				items={[
					{
						src: require("../../media/images/portfolio-images/themu/gallery/1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/themu/gallery/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/themu/gallery/3.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/themu/gallery/4.png")
							.default,
					},
				]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="mid">
					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/themu/themu-copertina-def.jpeg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/themu/Themu.mp4")
								.default
						}
					/>
				</ContainerBlock>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Themu.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Themu;
