import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import Cover from "../components/cover";
import PageAnimationHandler from "../ui-components/animations/page-animation-handler";

import { GigaTitle, MediumTitle, SmallText } from "../ui-components/texts";
import { ContainerBlock } from "../ui-components/columns/components";
import {
	SectionImagesServices,
	SectionServicesMasamune,
} from "../components/services-components";

import imageTopCover from "../media/images/services-page/4.jpg";
import imageBottomCover from "../media/images/services-page/1.jpg";
import { useTranslate } from "../contexts/translate";

const Services = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/projects");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.works
		);
		document.title = 'Services - Cutting Edge Digital Creative Studio'
	}, [translations]);

	return (
		<section className="services-page-component">
			<Cover imageTop={imageTopCover} imageBottom={imageBottomCover}>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">
						Get the edge
					</span>
					<span className="text-animation animation-delay-05s">
						you deserve
					</span>
				</GigaTitle>
			</Cover>
			<section className="theme-light mid-padding light-section full-height d-flex">
				<ContainerBlock className="m-auto h-auto">
					<SmallText>
						{translations === null ? "" : translations.services.section1Sub}
					</SmallText>
					<MediumTitle>
						{translations === null ? "" : translations.services.section1Title}
					</MediumTitle>
				</ContainerBlock>
			</section>

			<SectionImagesServices />
			<SectionServicesMasamune translations={translations} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctaservices.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctaservices.button}</PopupButton>
			  </div>
			</div>
		</section>
	);
};

Services.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Services;
