import React, { useEffect } from "react";

import Cover from "../components/cover";

import PageAnimationHandler from "../ui-components/animations/page-animation-handler";
import {
	GigaTitle,
	LargeHeading,
	MediumTitle,
	SmallHeading,
} from "../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../ui-components/columns/components";
import { ScrollXImages } from "../ui-components/force-scroll-x";

import imageTopCover from "../media/images/talent-sourcing-page/1.jpg";
import imageBottomCover from "../media/images/talent-sourcing-page/2.jpg";
import imageContent2 from "../media/images/talent-sourcing-page/4.jpg";
import imageContent3 from "../media/images/talent-sourcing-page/5.jpg";
import imageContent4 from "../media/images/talent-sourcing-page/6.jpg";
import imageContent5 from "../media/images/talent-sourcing-page/8.jpg";
import { useTranslate } from "../contexts/translate";
import { any } from "prop-types";

const TalentSourcing = ({ setNextPageHref, setNextPageName }) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/careers");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.careers
		);

		document.title = 'Talent Sourcing - Cutting Edge Digital Creative Studio'
	}, [translations]);

	const imagesScrollX = [
		{
			src: require("../media/images/talent-sourcing-page/slide-1.jpg").default,
		},
		{
			src: require("../media/images/talent-sourcing-page/slide-2.jpg").default,
		},
		{
			src: require("../media/images/talent-sourcing-page/slide-3.png").default,
		},
		{
			src: require("../media/images/talent-sourcing-page/slide-4.png").default,
		},
	];

	return (
		<section className="talent-sourcing-page-component">
			<Cover
				classNameImage2="left-45"
				imageTop={imageTopCover}
				imageBottom={imageBottomCover}
			>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s animation-delay-05s">
						Your partner
					</span>
					<span className="text-animation animation-delay-05s animation-delay-05s">
						in talent
					</span>
				</GigaTitle>
			</Cover>

			<section className="overflow-hidden theme-light light-section mid-padding">
				<ContainerBlock className="fluid">
					<LargeHeading className="mb-5">
						{translations === null ? "" : translations.talentSourcing.section1}
					</LargeHeading>
					<LargeHeading>
						{translations === null ? "" : translations.talentSourcing.section2}
					</LargeHeading>

					<ScrollXImages className="mid-padding pb-0 dark-section" items={imagesScrollX} />
				</ContainerBlock>
			</section>

			<section className="theme-light light-section small-padding pt-0">
				<Container>
					<div className="wrapper fluid">
						<Column className="d-flex flex-column w-50">
							<MediumTitle className="mb-4">
								{translations === null
									? ""
									: translations.talentSourcing.section3.title}
							</MediumTitle>
							<img src={imageContent3} alt="Image content 2" className="mt-auto" />
						</Column>
						<Column className="d-flex flex-column w-50">
							<SmallHeading className="small-text-laptop">
								{translations === null
									? ""
									: translations.talentSourcing.section3.descriptions[0]}
							</SmallHeading>
							<SmallHeading className="mb-4 small-text-laptop">
								{translations === null
									? ""
									: translations.talentSourcing.section3.descriptions[1]}
							</SmallHeading>
							<img
								src={imageContent2}
								alt="Image content 3"
								className="mt-auto"
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section className="theme-light grey-section mid-padding">
				<Container>
					<div className="wrapper fluid">
						<Column className="d-flex flex-column w-50">
							<MediumTitle className="mb-4">
								{translations === null
									? ""
									: translations.talentSourcing.section4.title}
							</MediumTitle>
							<img src={imageContent5} alt="Image content 4" className="mt-auto" />
						</Column>
						<Column className="d-flex flex-column w-50">
							<SmallHeading className="mb-4 small-text-laptop">
								{translations === null
									? ""
									: translations.talentSourcing.section4.descriptions[0]}
							</SmallHeading>
							<img
								src={imageContent4}
								alt="Image content 5"
								className="mt-auto"
							/>
						</Column>
					</div>
				</Container>
			</section>
		</section>
	);
};

TalentSourcing.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default TalentSourcing;
