import styled from "styled-components";
import { phone, smallPhone } from "../../constants/screens";

export const CoverWrapper = styled.section`
	position: relative;
	z-index: 0;
	height: 100vh;

	h1 {
		color: #fff;
		position: absolute;
		top: 50%;
		z-index: 9999;
		transform: translate(0, -50%);

		span {
			display: block;
			margin-bottom: 10px;
		}
	}

	.top-right-img {
		width: 512px;
		position: absolute;
		top: 50px;
		right: 125px;
	}

	.bottom-left-img {
		width: 256px;
		position: absolute;
		bottom: 100px;
		left: auto;
		right: 650px;
	}

	@media (max-width: 1550px) {
		.bottom-left-img {
			right: 450px;
		}
	}

	@media (max-width: 1310px) {
		.bottom-left-img {
			right: 300px;
		}
	}

	@media (max-width: 1080px) {
		.bottom-left-img {
			right: 50px;
			bottom: 50px;
		}

		.top-right-img {
			top: 60px;
			left: 50px;
			right: auto;
			width: 400px;
		}
	}

	${phone} {
		h1 {
			left: 60px;
		}

		.top-right-img {
			width: 230px;
			top: 100px;
			left: 40px;
			right: auto;
		}

		.bottom-left-img {
			width: 180px;
			right: 90px;
			bottom: 90px;
			left: auto;
		}
	}

	${smallPhone} {
		h1 {
			left: 40px;
		}

		.top-right-img {
			width: 230px;
			top: 80px;
			left: 40px;
			right: auto;
		}

		.bottom-left-img {
			width: 180px;
			right: 50px;
			bottom: 40px;
			left: auto;
		}
	}
`;

export const CoverContainer = styled.div`
	width: 75%;
	margin: 0 auto;
	padding: 0 20px;
`;
