import React, { useEffect, useRef, useState } from "react";

import Cover from "../components/cover";
import PortfolioSection from "../components/portfolio-section";
import { FullWidthVideo } from "../ui-components/videos";
import Intro from "../ui-components/intro";
import { GigaTitle, SmallTitle } from "../ui-components/texts";
import { FullHeightSectionSquare } from "../ui-components/sections";
import { CallToActionGiga } from "../ui-components/links";

import imageTopCover from "../media/images/home-page/1.jpg";
import imageBottomCover from "../media/images/home-page/2.jpg";
import previewVideo from "../media/images/home-page/preview-video.jpg";
import portfolioBg from "../media/images/about-page/3.jpg";
import imageColumnLeft from "../media/images/home-page/3.jpg";

import { useTranslate } from "../contexts/translate";

import imageSquare1 from "../media/images/home-page/image-square-1.jpg";
import imageSquare2 from "../media/images/home-page/image-square-2.jpg";
import imageSquare3 from "../media/images/home-page/image-square-3.jpg";
import { Container, Column } from "../ui-components/columns/components";
import ImagesScroll from "../components/images-scroll";

const Home = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	const squareElementFollow = useRef();

	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/about");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.about
		);
	}, [translations]);

	const [imageIndex, setImageIndex] = useState(imageColumnLeft);

	const itemsPortfolio = [
		{
			name: "Themu",
			link: "/projects/themu",
			src: require("../media/images/home-page/work-5.png").default,
		},
		{
			name: "Ride 5",
			link: "/projects/ride-5",
			src: require("../media/images/home-page/work-4.jpg").default,
		},
		{
			name: "Ohoskin",
			link: "/projects/ohoskin",
			src: require("../media/images/home-page/work-1.jpg").default,
		},
		{
			name: "Giglio.com",
			link: "/projects/giglio",
			src: require("../media/images/home-page/work-2.jpg").default,
		},
		{
			name: "Micidial",
			link: "/projects/micidial",
			src: require("../media/images/home-page/work-3.gif").default,
		},
	];

	const handleMouseMove = (e) => {
		try {
			squareElementFollow.current.style.left = e.clientX - 5250 + "px";
			squareElementFollow.current.style.top = e.clientY - 5350 + "px";
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		document.getElementById("follow-square-wrapper").onmouseleave = () => {
			document.removeEventListener("mousemove", handleMouseMove, true);
		};

		document.getElementById("follow-square-wrapper").onmouseover = () => {
			document.addEventListener("mousemove", handleMouseMove, true);
		};

		if (document.querySelector(".hover-change-image-1") != undefined) {
			document
				.querySelector(".hover-change-image-1")
				.addEventListener("mouseover", () => {
					setImageIndex(0);
				});
		}

		if (document.querySelector(".hover-change-image-2") != undefined) {
			document
				.querySelector(".hover-change-image-2")
				.addEventListener("mouseover", () => {
					setImageIndex(1);
				});
		}

		if (document.querySelector(".hover-change-image-3") != undefined) {
			document
				.querySelector(".hover-change-image-3")
				.addEventListener("mouseover", () => {
					setImageIndex(2);
				});
		}
	});

	return (
		<section className="home-page-component">
			<Cover imageTop={imageBottomCover} imageBottom={imageTopCover}>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">
						Cutting-edge
					</span>
					<span className="text-animation animation-delay-05s">
						Digital Creative
					</span>
					<span className="text-animation animation-delay-05s">Studio</span>
				</GigaTitle>
			</Cover>

			<FullWidthVideo
				title="Play reel"
				srcVideo={require("../media/videos/showreel.mp4").default}
				previewImage={previewVideo}
			/>

			<Container
				style={{ background: "#fff" }}
				className="pt-5 pb-5 full-height theme-light"
			>
				<div className="wrapper mid-full align-items-center">
					<Column className="w-50">
						<ImagesScroll
							images={[imageSquare1, imageSquare2, imageSquare3]}
							selectedIndex={imageIndex}
						/>
					</Column>
					<Column className="w-50">
						<SmallTitle
							dangerouslySetInnerHTML={{
								__html: translations === null ? "" : translations.home.section1,
							}}
						></SmallTitle>
					</Column>
				</div>
			</Container>

			{/* to do fix PortfolioSection */}
			<PortfolioSection items={itemsPortfolio} />

			<FullHeightSectionSquare
				className="theme-dark"
				id="follow-square-wrapper"
				style={{ backgroundImage: `url("${portfolioBg}")` }}
			>
				<div id="follow-square" ref={squareElementFollow}></div>
				<CallToActionGiga
					as="a"
					className="white center-in-content"
					href="/projects"
				>
					Full Portfolio
				</CallToActionGiga>
			</FullHeightSectionSquare>

			<Intro />
		</section>
	);
};

export default Home;
