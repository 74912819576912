import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText, SmallTitle } from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/micidial/cover.jpg";
import { TitleWithScrollXImagesSection } from "./components-design";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { useTranslate } from "../../contexts/translate";

const Micidial = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();
	useEffect(() => {
		setNextPageHref("/projects/world-padel-store");
		setNextPageName("World Padel Store");
		document.title = 'Micidial - Cutting Edge Digital Creative Studio'
	}, []);

	const peoples = [
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
		{
			fullname: "Andrea Bolloni",
			image: require("../../media/team-images/Andrea Bolloni.jpg").default,
			role: translations === null ? "" : translations.about.peoples[6].role,
			description:
				translations === null ? "" : translations.about.peoples[6].description,
		},
		{
			fullname: "Michele Giacchè",
			image: require("../../media/team-images/no-image.png").default,
			role: "Brand Designer",
			description: "",
		},
	];

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#f5f9ff"
				title="Micidial - House of Maccio"
				text={translations && translations.micidial.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Micidial"}
				services={["Photography", "Corporate & Visual Identity"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.micidial.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.micidial.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<img
								src={
									require("../../media/images/portfolio-images/micidial/1.jpg")
										.default
								}
								alt="Image"
							/>
						</Column>
						<Column className="w-50">
							<SmallTitle>{translations && translations.micidial.sections[0].title}</SmallTitle>
							<LargeText>
								{translations && translations.micidial.sections[0].text}
							</LargeText>
						</Column>
					</div>
				</Container>
			</section>

			<TitleWithScrollXImagesSection
				color={"#1e092e"}
				className="theme-dark"
				title={translations && translations.micidial.sections[1].title}
				text={translations && translations.micidial.sections[1].text}
				items={[
					{
						src: require("../../media/images/portfolio-images/micidial/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/micidial/3.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/micidial/4.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/micidial/5.png")
							.default,
					},
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Micidial.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Micidial;
