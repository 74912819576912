import React from "react";

import { DiscoverMoreSection } from "./components";
import { H2GigaTitle } from "../texts";
import { CallToActionSmall } from "../links";
import { Column } from "../columns/components";
import { any } from "prop-types";

export const DiscoverMoreImage = ({
	imageBefore = "",
	imageAfter = null,
	title = "Title for example",
	linkPath = "/discover-more",
	linkText = "Discover more",
}) => {
	return (
		<DiscoverMoreSection className="theme-dark two-column overflow-hidden">
			<Column className="body-column-position left-phone-0">
				<H2GigaTitle>{title}</H2GigaTitle>
				<CallToActionSmall
					className={"white mt-2"}
					href={linkPath}
					as="a"
					onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
				>
					{linkText}
				</CallToActionSmall>
			</Column>
			<Column className="image-column-position">
				{imageAfter !== null ? // to add effect cut on hover
				null : (
					<img src={imageBefore} alt={title} />
				)}
			</Column>
		</DiscoverMoreSection>
	);
};

DiscoverMoreImage.propTypes = {
	imageBefore: any,
	imageAfter: any,
	title: any,
	linkPath: any,
	linkText: any,
};
