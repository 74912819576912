import React, { useEffect } from "react";
import { ContainerBlock } from "../ui-components/columns/components";

export default ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/");
		setNextPageName("Home Page");
		document.title = "Privacy Policiy - Cutting Edge Digital Creative Studio";
	}, []);

	return (
		<section className="theme-dark privacy" style={{ padding: "50px 0" }}>
			<ContainerBlock className="mid">
				<section className="text-block">
					<h2 className="heading">Privacy &amp; Cookie Policy</h2>
					<h2 className="heading">
						Informativa e consenso al trattamento dei dati personali ai sensi
						dell’Art. 13 del d.lgs. n. 196/03 – t.u. sulla privacy
					</h2>
					<p className="text">
						In ottemperanza al D.Lgs. 30 giugno del 2003, n.196, Masamune s.r.l.
						si impegna a trattare tutti i dati personali acquisiti in relazione
						al contratto nel rispetto della normativa in materia di
						riservatezza. A riguardo si offre apposita informativa, ai fini del
						consenso, ai sensi dell’art 13 d.lgs. 196/2003.
					</p>
					<p className="text">
						Questo Sito Web raccoglie alcuni Dati Personali dei propri Utenti.
					</p>
					<h2 className="heading">Titolare del Trattamento dei Dati</h2>
					<p className="text">
						Masamune s.r.l. – Via Monterosso 17 - 95021 Catania, Italia – P.IVA:
						05800840877 - Indirizzo email: amministrazione@masamune.it
					</p>
					<h2 className="heading">Tipologie di Dati raccolti</h2>
					<p className="text">
						Fra i Dati Personali raccolti da questo Sito Web, in modo autonomo o
						tramite terze parti, ci sono: Cookie; Dati di utilizzo; email; nome;
						cognome; numero di telefono; varie tipologie di Dati; dati relativi
						al pagamento; indirizzo; ragione sociale.
					</p>
					<p className="text">
						Dettagli completi su ciascuna tipologia di dati raccolti sono
						forniti nelle sezioni dedicate di questa privacy policy o mediante
						specifici testi informativi visualizzati prima della raccolta dei
						dati stessi.
					</p>
					<p className="text">
						I Dati Personali possono essere liberamente forniti dall’Utente o,
						nel caso di Dati di Utilizzo, raccolti automaticamente durante l’uso
						di questo Sito Web.
					</p>
					<p className="text">
						Se non diversamente specificato, tutti i Dati richiesti da questo
						Sito Web sono obbligatori. Se l’Utente rifiuta di comunicarli,
						potrebbe essere impossibile per questo Sito Web fornire il Servizio.
						Nei casi in cui questo Sito Web indichi alcuni Dati come
						facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali
						Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del
						Servizio o sulla sua operatività.
					</p>
					<p className="text">
						Gli Utenti che dovessero avere dubbi su quali Dati siano
						obbligatori, sono incoraggiati a contattare il Titolare.
					</p>
					<p className="text">
						L’eventuale utilizzo di Cookie – o di altri strumenti di
						tracciamento – da parte di questo Sito Web o dei titolari dei
						servizi terzi utilizzati da questo Sito Web, ove non diversamente
						precisato, ha la finalità di fornire il Servizio richiesto
						dall’Utente, oltre alle ulteriori finalità descritte nel presente
						documento e nella Cookie Policy, se disponibile.
					</p>
					<p className="text">
						L’Utente si assume la responsabilità dei Dati Personali di terzi
						ottenuti, pubblicati o condivisi mediante questo Sito Web e
						garantisce di avere il diritto di comunicarli o diffonderli,
						liberando il Titolare da qualsiasi responsabilità verso terzi.
					</p>
					<h2 className="heading">
						Modalità e luogo del trattamento dei Dati raccolti
					</h2>
					<h2 className="heading">Modalità di trattamento</h2>
					<p className="text">
						Il Titolare adotta le opportune misure di sicurezza volte ad
						impedire l’accesso, la divulgazione, la modifica o la distruzione
						non autorizzate dei Dati Personali.
					</p>
					<p className="text">
						Il trattamento viene effettuato mediante strumenti informatici e/o
						telematici, con modalità organizzative e con logiche strettamente
						correlate alle finalità indicate. Oltre al Titolare, in alcuni casi,
						potrebbero avere accesso ai Dati altri soggetti coinvolti
						nell’organizzazione di questo Sito Web (personale amministrativo,
						commerciale, marketing, legali, amministratori di sistema) ovvero
						soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
						postali, Masamune s.r.l.ing provider, società informatiche, agenzie
						di comunicazione) nominati anche, se necessario, Responsabili del
						Trattamento da parte del Titolare. L’elenco aggiornato dei
						Responsabili potrà sempre essere richiesto al Titolare del
						Trattamento.
					</p>
					<h2 className="heading">Base giuridica del trattamento </h2>
					<p className="text">
						Il Titolare tratta Dati Personali relativi all’Utente in caso
						sussista una delle seguenti condizioni:
					</p>
					<ul className="bullet-points">
						<li>
							l’Utente ha prestato il consenso per una o più finalità
							specifiche; Nota: in alcuni ordinamenti il Titolare può essere
							autorizzato a trattare Dati Personali senza che debba sussistere
							il consenso dell’Utente o un’altra delle basi giuridiche
							specificate di seguito, fino a quando l’Utente non si opponga
							(“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile
							qualora il trattamento di Dati Personali sia regolato dalla
							legislazione europea in materia di protezione dei Dati Personali;
						</li>
						<li>
							il trattamento è necessario all’esecuzione di un contratto con
							l’Utente e/o all’esecuzione di misure precontrattuali;
						</li>
						<li>
							il trattamento è necessario per adempiere un obbligo legale al
							quale è soggetto il Titolare;
						</li>
						<li>
							il trattamento è necessario per l’esecuzione di un compito di
							interesse pubblico o per l’esercizio di pubblici poteri di cui è
							investito il Titolare;
						</li>
						<li>
							il trattamento è necessario per il perseguimento del legittimo
							interesse del Titolare o di terzi.
						</li>
					</ul>
					<p className="text">
						È comunque sempre possibile richiedere al Titolare di chiarire la
						concreta base giuridica di ciascun trattamento ed in particolare di
						specificare se il trattamento sia basato sulla legge, previsto da un
						contratto o necessario per concludere un contratto.
					</p>
					<h2 className="heading">Luogo</h2>
					<p className="text">
						I Dati sono trattati presso le sedi operative del Titolare ed in
						ogni altro luogo in cui le parti coinvolte nel trattamento siano
						localizzate. Per ulteriori informazioni, contatta il Titolare.
					</p>
					<p className="text">
						I Dati Personali dell’Utente potrebbero essere trasferiti in un
						paese diverso da quello in cui l’Utente si trova. Per ottenere
						ulteriori informazioni sul luogo del trattamento l’Utente può fare
						riferimento alla sezione relativa ai dettagli sul trattamento dei
						Dati Personali.
					</p>
					<p className="text">
						L’Utente ha diritto a ottenere informazioni in merito alla base
						giuridica del trasferimento di Dati al di fuori dell’Unione Europea
						o ad un’organizzazione internazionale di diritto internazionale
						pubblico o costituita da due o più paesi, come ad esempio l’ONU,
						nonché in merito alle misure di sicurezza adottate dal Titolare per
						proteggere i Dati.
					</p>
					<p className="text">
						L’Utente può verificare se abbia luogo uno dei trasferimenti appena
						descritti esaminando la sezione di questo documento relativa ai
						dettagli sul trattamento di Dati Personali o chiedere informazioni
						al Titolare contattandolo agli estremi riportati in apertura.
					</p>
					<h2 className="heading">Periodo di conservazione</h2>
					<p className="text">
						I Dati sono trattati e conservati per il tempo richiesto dalle
						finalità per le quali sono stati raccolti.
					</p>
					<p className="text">Pertanto:</p>
					<ul className="bullet-points">
						<li>
							I Dati Personali raccolti per scopi collegati all’esecuzione di un
							contratto tra il Titolare e l’Utente saranno trattenuti sino a
							quando sia completata l’esecuzione di tale contratto.
						</li>
						<li>
							I Dati Personali raccolti per finalità riconducibili all’interesse
							legittimo del Titolare saranno trattenuti sino al soddisfacimento
							di tale interesse. L’Utente può ottenere ulteriori informazioni in
							merito all’interesse legittimo perseguito dal Titolare nelle
							relative sezioni di questo documento o contattando il Titolare.
						</li>
					</ul>
					<p className="text">
						Quando il trattamento è basato sul consenso dell’Utente, il Titolare
						può conservare i Dati Personali più a lungo sino a quando detto
						consenso non venga revocato. Inoltre, il Titolare potrebbe essere
						obbligato a conservare i Dati Personali per un periodo più lungo in
						ottemperanza ad un obbligo di legge o per ordine di un’autorità.
					</p>
					<p className="text">
						Al termine del periodo di conservazione i Dati Personali saranno
						cancellati. Pertanto, allo spirare di tale termine il diritto di
						accesso, cancellazione, rettificazione ed il diritto alla
						portabilità dei Dati non potranno più essere esercitati.
					</p>
					<h2 className="heading">
						Finalità del Trattamento dei Dati raccolti{" "}
					</h2>
					<p className="text">
						I Dati dell’Utente sono raccolti per consentire al Titolare di
						fornire i propri Servizi, così come per le seguenti finalità:
						Protezione dallo SPAM, Statistica, Ottimizzazione e distribuzione
						del traffico, Remarketing e behavioral targeting, Visualizzazione di
						contenuti da piattaforme esterne, Gestione dei tag, Gestione
						contatti e invio di messaggi, Gestione di landing page e pagine di
						invito, Contattare l’Utente, Gestione dei pagamenti e Commento dei
						contenuti.
					</p>
					<p className="text">
						Per ottenere ulteriori informazioni dettagliate sulle finalità del
						trattamento e sui Dati Personali concretamente rilevanti per
						ciascuna finalità, l’Utente può fare riferimento alle relative
						sezioni di questo documento.
					</p>
					<h2 className="heading">
						Dettagli sul trattamento dei Dati Personali{" "}
					</h2>
					<p className="text">
						I Dati Personali sono raccolti per le seguenti finalità ed
						utilizzando i seguenti servizi:
					</p>
					<h2 className="heading">Modulo di contatto (questo Sito Web)</h2>
					<p className="text">
						L’Utente, compilando con i propri Dati il modulo di contatto,
						acconsente al loro utilizzo per rispondere alle richieste di
						informazioni, di preventivo, o di qualunque altra natura indicata
						dall’intestazione del modulo.
					</p>
					<p className="text">
						Dati Personali raccolti: cognome; email; nome; numero di telefono;
						varie tipologie di Dati.
					</p>
					<h2 className="heading">Gestione contatti e invio di messaggi</h2>
					<p className="text">
						Questo tipo di servizi consente di gestire un database di contatti
						email, contatti telefonici o contatti di qualunque altro tipo,
						utilizzati per comunicare con l’Utente.
					</p>
					<p className="text">
						Questi servizi potrebbero inoltre consentire di raccogliere dati
						relativi alla data e all’ora di visualizzazione dei messaggi da
						parte dell’Utente, così come all’interazione dell’Utente con essi,
						come le informazioni sui click sui collegamenti inseriti nei
						messaggi.
					</p>
					<h2 className="heading">Mailchimp (The Rocket Science Group LLC) </h2>
					<p className="text">
						Mailchimp è un servizio di gestione indirizzi e invio di messaggi
						email fornito da The Rocket Science Group LLC.
					</p>
					<p className="text">Dati Personali raccolti: cognome; email; nome.</p>
					<p className="text">
						Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto
						aderente al Privacy Shield.
					</p>
					<h2 className="heading">Gestione dei pagamenti </h2>
					<p className="text">
						Se non diversamente specificato, questo Sito Web elabora tutti i
						pagamenti con carta di credito, bonifico bancario o altri mezzi
						tramite fornitori esterni di servizi di pagamento. In generale, e
						salvo diversa indicazione, gli Utenti sono pregati di fornire i
						dettagli di pagamento e le informazioni personali direttamente a
						tali fornitori di servizi di pagamento.
					</p>
					<p className="text">
						Questo Sito Web non è coinvolta nella raccolta e nell’elaborazione
						di tali informazioni: riceverà invece solo una notifica da parte del
						fornitore di servizi di pagamento in questione circa l’avvenuto
						pagamento.
					</p>
					<h2 className="heading">
						Pagamento a mezzo di bonifico (questo Sito Web){" "}
					</h2>
					<p className="text">
						Nel caso in cui il metodo di pagamento scelto sia il bonifico
						bancario diretto sul conto corrente indicato da questo Sito Web, il
						Titolare raccoglierà i dati relativi al pagamento dell’Utente, ossia
						il numero di conto corrente del disponente, il codice SWIFT, la
						Banca ed il nominativo del disponente. Tali dati saranno raccolti e
						trattati esclusivamente nell’ambito della transazione ed ai soli
						fini della fatturazione.
					</p>
					<p className="text">
						Dati Personali raccolti: cognome; dati relativi al pagamento;
						indirizzo; nome; ragione sociale.
					</p>
					<h2 className="heading">PayPal (Paypal) </h2>
					<p className="text">
						PayPal è un servizio di pagamento fornito da PayPal Inc., che
						consente all’Utente di effettuare pagamenti online.
					</p>
					<p className="text">
						Dati Personali raccolti: varie tipologie di Dati secondo quanto
						specificato dalla privacy policy del servizio.
					</p>
					<p className="text">
						Luogo del trattamento: Consulta la privacy policy di Paypal –
						Privacy Policy.
					</p>
					<h2 className="heading">Gestione dei tag </h2>
					<p className="text">
						Questo tipo di servizi è funzionale alla gestione centralizzata dei
						tag o script utilizzati su questo Sito Web.
					</p>
					<p className="text">
						L’uso di tali servizi comporta il fluire dei Dati dell’Utente
						attraverso gli stessi e, se del caso, la loro ritenzione.
					</p>
					<h2 className="heading">
						Google Tag Manager (Google Ireland Limited){" "}
					</h2>
					<p className="text">
						Google Tag Manager è un servizio di gestione dei tag fornito da
						Google Ireland Limited.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Irlanda – Privacy Policy. Soggetto aderente
						al Privacy Shield.
					</p>
					<h2 className="heading">
						Gestione di landing page e pagine di invito{" "}
					</h2>
					<p className="text">
						Questo tipo di servizi permette di costruire e gestire landing page
						e pagine di invito, ossia pagine di presentazione di un prodotto o
						servizio, che possono consentire l’inserimento delle proprie
						informazioni di contatto, come l’indirizzo email.
					</p>
					<p className="text">
						La gestione di queste pagine comporta il trattamento, da parte di
						questi servizi, dei Dati Personali raccolti tramite tali pagine,
						inclusi quelli di i Dati di Utilizzo.
					</p>
					<h2 className="heading">
						Mailchimp Landing Page (The Rocket Science Group LLC){" "}
					</h2>
					<p className="text">
						Mailchimp Landing Page è un servizio di gestione di landing page
						fornito da The Rocket Science Group LLC che permette a questo Sito
						Web di raccogliere indirizzi email degli Utenti interessati al
						proprio servizio.
					</p>
					<p className="text">
						Mailchimp Landing Page permette al Titolare del Trattamento di tener
						traccia ed analizzare la risposta da parte dell’Utente, in termini
						di traffico o di comportamento, in relazione a modifiche della
						struttura, del testo o di qualunque altro componente delle landing
						page create.
					</p>
					<p className="text">Dati Personali raccolti: cognome; email; nome.</p>
					<p className="text">
						Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto
						aderente al Privacy Shield.
					</p>
					<h2 className="heading">
						Ottimizzazione e distribuzione del traffico{" "}
					</h2>
					<p className="text">
						Questo tipo di servizi permette a questo Sito Web di distribuire i
						propri contenuti tramite dei server dislocati sul territorio e di
						ottimizzare le prestazioni della stessa.
					</p>
					<p className="text">
						I Dati Personali trattati dipendono dalle caratteristiche e della
						modalità d’implementazione di questi servizi, che per loro natura
						filtrano le comunicazioni fra questo Sito Web ed il browser
						dell’Utente.
					</p>
					<p className="text">
						Vista la natura distribuita di questo sistema, è difficile
						determinare i luoghi in cui vengono trasferiti i contenuti, che
						potrebbero contenere Dati Personali dell’Utente.
					</p>
					<h2 className="heading">Cloudflare (Cloudflare Inc.) </h2>
					<p className="text">
						Cloudflare è un servizio di ottimizzazione e distribuzione del
						traffico fornito da Cloudflare Inc.
					</p>
					<p className="text">
						Le modalità di integrazione di Cloudflare prevedono che questo
						filtri tutto il traffico di questo Sito Web, ossia le comunicazioni
						fra questo Sito Web ed il browser dell’Utente, permettendo anche la
						raccolta di dati statistici su di esso.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; varie tipologie di Dati secondo
						quanto specificato dalla privacy policy del servizio.
					</p>
					<p className="text">Luogo del trattamento: Stati Uniti.</p>
					<h2 className="heading">Protezione dallo SPAM</h2>
					<p className="text">
						Questo tipo di servizi analizza il traffico di questo Sito Web,
						potenzialmente contenente Dati Personali degli Utenti, al fine di
						filtrarlo da parti di traffico, messaggi e contenuti riconosciuti
						come SPAM.
					</p>
					<h2 className="heading">Akismet (Automattic Inc.) </h2>
					<p className="text">
						Akismet è un servizio di protezione dallo SPAM fornito da Automattic
						Inc.
					</p>
					<p className="text">
						Dati Personali raccolti: varie tipologie di Dati secondo quanto
						specificato dalla privacy policy del servizio.
					</p>
					<p className="text">
						Luogo del trattamento: Stati Uniti – Privacy Policy.
					</p>
					<h2 className="heading">Google reCAPTCHA (Google Ireland Limited)</h2>
					<p className="text">
						Google reCAPTCHA è un servizio di protezione dallo SPAM fornito da
						Google Ireland Limited.
					</p>
					<p className="text">
						L’utilizzo del sistema reCAPTCHA è soggetto alla privacy policy e ai
						termini di utilizzo di Google.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Irlanda – Privacy Policy. Soggetto aderente
						al Privacy Shield.
					</p>
					<h2 className="heading">Remarketing e behavioral targeting </h2>
					<p className="text">
						Akismet è un servizio di proQuesto tipo di servizi consente a questo
						Sito Web ed ai suoi partner di comunicare, ottimizzare e servire
						annunci pubblicitari basati sull’utilizzo passato di questo Sito Web
						da parte dell’Utente. tezione dallo SPAM fornito da Automattic Inc.
					</p>
					<p className="text">
						Questa attività viene effettuata tramite il tracciamento dei Dati di
						Utilizzo e l’uso di Cookie, informazioni che vengono trasferite ai
						partner a cui l’attività di remarketing e behavioral targeting è
						collegata.
					</p>
					<p className="text">
						Alcuni servizi offrono un’opzione di remarketing basata sulle liste
						di indirizzi email.
					</p>
					<p className="text">
						In aggiunta alle possibilità di effettuare l’opt-out offerte dai
						servizi di seguito riportati, l’Utente può optare per l’esclusione
						dell’uso di cookie da parte di un servizio di terzi per alcune
						funzioni di remarketing visitando la pagina di opt-out della Network
						Advertising Initiative.
					</p>
					<p className="text">
						Gli Utenti possono anche scegliere di non partecipare a determinate
						funzionalità pubblicitarie attraverso le corrispondenti opzioni di
						configurazione del dispositivo, come le opzioni di configurazione
						pubblicitaria del dispositivo mobile o la configurazione
						pubblicitaria generica.
					</p>
					<h2 className="heading">Facebook Remarketing (Facebook, Inc.)</h2>
					<p className="text">
						Facebook Remarketing è un servizio di remarketing e behavioral
						targeting fornito da Facebook, Inc. che collega l’attività di questo
						Sito Web con il network di advertising Facebook.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out.
						Soggetto aderente al Privacy Shield.
					</p>
					<h2 className="heading">
						Hostinger Cloud Enterprise (questo Sito Web)
					</h2>
					<p className="text">
						Hostinger Cloud Enterprise è un software di statistica utilizzato da
						questo Sito Web per analizzare i Dati in maniera diretta e senza
						l’ausilio di terzi.
					</p>
					<p className="text">
						L’Utente può disabilitare la raccolta di queste informazioni
						statistiche disattivando JavaScript nel proprio browser. Tuttavia,
						questa azione potrebbe influire negativamente sull’esperienza di
						navigazione dell’Utente su questo Sito Web e su altre applicazioni.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<h2 className="heading">Google Analytics (Google Ireland Limited)</h2>
					<p className="text">
						Google Analytics è un servizio di analisi web fornito da Google
						Ireland Limited (“Google”). Google utilizza i Dati Personali
						raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo
						Sito Web, compilare report e condividerli con gli altri servizi
						sviluppati da Google.
					</p>
					<p className="text">
						Google potrebbe utilizzare i Dati Personali per contestualizzare e
						personalizzare gli annunci del proprio network pubblicitario.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Irlanda – Privacy Policy – Opt Out. Soggetto
						aderente al Privacy Shield.
					</p>
					<h2 className="heading">
						Google Analytics con IP anonimizzato (Google Ireland Limited)
					</h2>
					<p className="text">
						Google Analytics è un servizio di analisi web fornito da Google
						Ireland Limited (“Google”). Google utilizza i Dati Personali
						raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo
						Sito Web, compilare report e condividerli con gli altri servizi
						sviluppati da Google.
					</p>
					<p className="text">
						Google potrebbe utilizzare i Dati Personali per contestualizzare e
						personalizzare gli annunci del proprio network pubblicitario.
					</p>
					<p className="text">
						Questa integrazione di Google Analytics rende anonimo il tuo
						indirizzo IP. L’anonimizzazione funziona abbreviando entro i confini
						degli stati membri dell’Unione Europea o in altri Paesi aderenti
						all’accordo sullo Spazio Economico Europeo l’indirizzo IP degli
						Utenti. Solo in casi eccezionali, l’indirizzo IP sarà inviato ai
						server di Google ed abbreviato all’interno degli Stati Uniti.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Irlanda – Privacy Policy – Opt Out. Soggetto
						aderente al Privacy Shield.
					</p>
					<h2 className="heading">
						Monitoraggio conversioni di Facebook Ads (pixel di Facebook)
						(Facebook, Inc.)
					</h2>
					<p className="text">
						Il monitoraggio conversioni di Facebook Ads (pixel di Facebook) è un
						servizio di statistiche fornito da Facebook, Inc. che collega i dati
						provenienti dal network di annunci Facebook con le azioni compiute
						all’interno di questo Sito Web. Il pixel di Facebook monitora le
						conversioni che possono essere attribuite alle inserzioni di
						Facebook, Instagram e Audience Network.
					</p>
					<p className="text">
						Dati Personali raccolti: Cookie; Dati di utilizzo.
					</p>
					<p className="text">
						Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto
						aderente al Privacy Shield.
					</p>
					<h2 className="heading">
						Visualizzazione di contenuti da piattaforme esterne
					</h2>
					<p className="text">
						Questo tipo di servizi permette di visualizzare contenuti ospitati
						su piattaforme esterne direttamente dalle pagine di questo Sito Web
						e di interagire con essi.
					</p>
					<p className="text">
						Questo tipo di servizio potrebbe comunque raccogliere dati sul
						traffico web relativo alle pagine dove il servizio è installato,
						anche quando gli utenti non lo utilizzano.
					</p>
					<h2 className="heading">Google Fonts (Google Ireland Limited)</h2>
					<p className="text">
						Google Fonts è un servizio di visualizzazione di stili di carattere
						gestito da Google Ireland Limited che permette a questo Sito Web di
						integrare tali contenuti all’interno delle proprie pagine.
					</p>
					<p className="text">
						Dati Personali raccolti: Dati di utilizzo; varie tipologie di Dati
						secondo quanto specificato dalla privacy policy del servizio.
					</p>
					<p className="text">
						Luogo del trattamento: Irlanda – Privacy Policy. Soggetto aderente
						al Privacy Shield.
					</p>
					<h2 className="heading">Ulteriori informazioni sui Dati Personali</h2>
					<h2 className="heading">
						Il Servizio non è rivolto ai minori di 13 anni
					</h2>
					<p className="text">
						Gli Utenti dichiarano di essere maggiorenni secondo la legislazione
						loro applicabile. I minorenni possono utilizzare questo Sito Web
						solo con l’assistenza di un genitore o di un tutore. In nessun caso
						minori di 13 anni possono usare questo Sito Web.
					</p>
					<h2 className="heading">Diritti dell’Utente </h2>
					<p className="text">
						Gli Utenti possono esercitare determinati diritti con riferimento ai
						Dati trattati dal Titolare.
					</p>
					<p className="text">In particolare, l’Utente ha il diritto di:</p>
					<ul className="bullet-points">
						<li>
							revocare il consenso in ogni momento. L’Utente può revocare il
							consenso al trattamento dei propri Dati Personali precedentemente
							espresso.
						</li>
						<li>
							opporsi al trattamento dei propri Dati. L’Utente può opporsi al
							trattamento dei propri Dati quando esso avviene su una base
							giuridica diversa dal consenso. Ulteriori dettagli sul diritto di
							opposizione sono indicati nella sezione sottostante.
						</li>
						<li>
							accedere ai propri Dati. L’Utente ha diritto ad ottenere
							informazioni sui Dati trattati dal Titolare, su determinati
							aspetti del trattamento ed a ricevere una copia dei Dati trattati.
						</li>
						<li>
							verificare e chiedere la rettificazione. L’Utente può verificare
							la correttezza dei propri Dati e richiederne l’aggiornamento o la
							correzione.
						</li>
						<li>
							ottenere la limitazione del trattamento. Quando ricorrono
							determinate condizioni, l’Utente può richiedere la limitazione del
							trattamento dei propri Dati. In tal caso il Titolare non tratterà
							i Dati per alcun altro scopo se non la loro conservazione.
						</li>
						<li>
							ottenere la cancellazione o rimozione dei propri Dati Personali.
							Quando ricorrono determinate condizioni, l’Utente può richiedere
							la cancellazione dei propri Dati da parte del Titolare.
						</li>
						<li>
							ricevere i propri Dati o farli trasferire ad altro titolare.
							L’Utente ha diritto di ricevere i propri Dati in formato
							strutturato, di uso comune e leggibile da dispositivo automatico
							e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
							ostacoli ad un altro titolare. Questa disposizione è applicabile
							quando i Dati sono trattati con strumenti automatizzati ed il
							trattamento è basato sul consenso dell’Utente, su un contratto di
							cui l’Utente è parte o su misure contrattuali ad esso connesse.
						</li>
						<li>
							proporre reclamo. L’Utente può proporre un reclamo all’autorità di
							controllo della protezione dei dati personali competente o agire
							in sede giudiziale.
						</li>
					</ul>
					<h2 className="heading">Dettagli sul diritto di opposizione</h2>
					<p className="text">
						Quando i Dati Personali sono trattati nell’interesse pubblico,
						nell’esercizio di pubblici poteri di cui è investito il Titolare
						oppure per perseguire un interesse legittimo del Titolare, gli
						Utenti hanno diritto ad opporsi al trattamento per motivi connessi
						alla loro situazione particolare.
					</p>
					<p className="text">
						Si fa presente agli Utenti che, ove i loro Dati fossero trattati con
						finalità di marketing diretto, possono opporsi al trattamento senza
						fornire alcuna motivazione. Per scoprire se il Titolare tratti dati
						con finalità di marketing diretto gli Utenti possono fare
						riferimento alle rispettive sezioni di questo documento.
					</p>
					<h2 className="heading">Come esercitare i diritti</h2>
					<p className="text">
						Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare
						una richiesta agli estremi di contatto del Titolare indicati in
						questo documento. Le richieste sono depositate a titolo gratuito e
						evase dal Titolare nel più breve tempo possibile, in ogni caso entro
						un mese.
					</p>
					<h2 className="heading">Cookie Policy</h2>
					<p className="text">
						Questo Sito Web fa utilizzo di Cookie. Per saperne di più e per
						prendere visione dell’informativa dettagliata, l’Utente può
						consultare la Cookie Policy.
					</p>
					<h2 className="heading">Ulteriori informazioni sul trattamento</h2>
					<h2 className="heading">Difesa in giudizio</h2>
					<p className="text">
						I Dati Personali dell’Utente possono essere utilizzati da parte del
						Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
						instaurazione per la difesa da abusi nell’utilizzo di questo Sito
						Web o dei Servizi connessi da parte dell’Utente.
					</p>
					<p className="text">
						L’Utente dichiara di essere consapevole che il Titolare potrebbe
						essere obbligato a rivelare i Dati per ordine delle autorità
						pubbliche.
					</p>
					<h2 className="heading">Informative specifiche</h2>
					<p className="text">
						Su richiesta dell’Utente, in aggiunta alle informazioni contenute in
						questa privacy policy, questo Sito Web potrebbe fornire all’Utente
						delle informative aggiuntive e contestuali riguardanti Servizi
						specifici, o la raccolta ed il trattamento di Dati Personali.
					</p>
					<h2 className="heading">Log di sistema e manutenzione</h2>
					<p className="text">
						Per necessità legate al funzionamento ed alla manutenzione, questo
						Sito Web e gli eventuali servizi terzi da essa utilizzati potrebbero
						raccogliere log di sistema, ossia file che registrano le interazioni
						e che possono contenere anche Dati Personali, quali l’indirizzo IP
						Utente.
					</p>
					<h2 className="heading">
						Informazioni non contenute in questa policy
					</h2>
					<p className="text">
						Ulteriori informazioni in relazione al trattamento dei Dati
						Personali potranno essere richieste in qualsiasi momento al Titolare
						del Trattamento utilizzando gli estremi di contatto.
					</p>
					<h2 className="heading">Risposta alle richieste “Do Not Track”</h2>
					<p className="text">
						Questo Sito Web non supporta le richieste “Do Not Track”.
					</p>
					<p className="text">
						Per scoprire se gli eventuali servizi di terze parti utilizzati le
						supportino, l’Utente è invitato a consultare le rispettive privacy
						policy.
					</p>
					<h2 className="heading">Modifiche a questa privacy policy</h2>
					<p className="text">
						Il Titolare del Trattamento si riserva il diritto di apportare
						modifiche alla presente privacy policy in qualunque momento dandone
						informazione agli Utenti su questa pagina e, se possibile, su questo
						Sito Web nonché, qualora tecnicamente e legalmente fattibile,
						inviando una notifica agli Utenti attraverso uno degli estremi di
						contatto di cui è in possesso il Titolare . Si prega dunque di
						consultare regolarmente questa pagina, facendo riferimento alla data
						di ultima modifica indicata in fondo.
					</p>
					<p className="text">
						Qualora le modifiche interessino trattamenti la cui base giuridica è
						il consenso, il Titolare provvederà a raccogliere nuovamente il
						consenso dell’Utente, se necessario.
					</p>
				</section>
				<h2 className="heading">Definizioni e riferimenti legali</h2>
				<h2 className="heading">Dati Personali (o Dati)</h2>
				<p className="text">
					Costituisce dato personale qualunque informazione che, direttamente o
					indirettamente, anche in collegamento con qualsiasi altra
					informazione, ivi compreso un numero di identificazione personale,
					renda identificata o identificabile una persona fisica.
				</p>
				<h2 className="heading">Dati di Utilizzo</h2>
				<p className="text">
					Sono le informazioni raccolte automaticamente attraverso questo Sito
					Web (anche da applicazioni di parti terze integrate in questo Sito
					Web), tra cui: gli indirizzi IP o i nomi a dominio dei computer
					utilizzati dall’Utente che si connette con questo Sito Web, gli
					indirizzi in notazione URI (Uniform Resource Identifier), l’orario
					della richiesta, il metodo utilizzato nell’inoltrare la richiesta al
					server, la dimensione del file ottenuto in risposta, il codice
					numerico indicante lo stato della risposta dal server (buon fine,
					errore, ecc.) il paese di provenienza, le caratteristiche del browser
					e del sistema operativo utilizzati dal visitatore, le varie
					connotazioni temporali della visita (ad esempio il tempo di permanenza
					su ciascuna pagina) e i dettagli relativi all’itinerario seguito
					all’interno dell’Applicazione, con particolare riferimento alla
					sequenza delle pagine consultate, ai parametri relativi al sistema
					operativo e all’ambiente informatico dell’Utente.
				</p>
				<h2 className="heading">Utente</h2>
				<p className="text">
					L’individuo che utilizza questo Sito Web che, salvo ove diversamente
					specificato, coincide con l’Interessato.
				</p>
				<h2 className="heading">Interessato</h2>
				<p className="text">
					La persona fisica cui si riferiscono i Dati Personali.
				</p>
				<h2 className="heading">
					Responsabile del Trattamento (o Responsabile)
				</h2>
				<p className="text">
					La persona fisica, giuridica, la pubblica amministrazione e qualsiasi
					altro ente che tratta dati personali per conto del Titolare, secondo
					quanto esposto nella presente privacy policy.
				</p>
				<h2 className="heading">Titolare del Trattamento (o Titolare)</h2>
				<p className="text">
					La persona fisica o giuridica, l’autorità pubblica, il servizio o
					altro organismo che, singolarmente o insieme ad altri, determina le
					finalità e i mezzi del trattamento di dati personali e gli strumenti
					adottati, ivi comprese le misure di sicurezza relative al
					funzionamento ed alla fruizione di questo Sito Web. Il Titolare del
					Trattamento, salvo quanto diversamente specificato, è il titolare di
					questo Sito Web.
				</p>
				<h2 className="heading">Questo Sito Web (o questa Applicazione)</h2>
				<p className="text">
					Lo strumento hardware o software mediante il quale sono raccolti e
					trattati i Dati Personali degli Utenti.
				</p>
				<h2 className="heading">Servizio</h2>
				<p className="text">
					Il Servizio fornito da questo Sito Web così come definito nei relativi
					termini (se presenti) su questo sito/applicazione.
				</p>
				<h2 className="heading">Unione Europea (o UE)</h2>
				<p className="text">
					Salvo ove diversamente specificato, ogni riferimento all’Unione
					Europea contenuto in questo documento si intende esteso a tutti gli
					attuali stati membri dell’Unione Europea e dello Spazio Economico
					Europeo.
				</p>
				<h2 className="heading">Cookie</h2>
				<p className="text">
					Piccola porzione di dati conservata all’interno del dispositivo
					dell’Utente.
				</p>
				<h2 className="heading">Riferimenti legali</h2>
				<p className="text">
					La presente informativa privacy è redatta sulla base di molteplici
					ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento
					(UE) 2016/679.
				</p>
				<p className="text">
					Ove non diversamente specificato, questa informativa privacy riguarda
					esclusivamente questo Sito Web.
				</p>
				<p className="text">
					Il cliente può esercitare i diritti di cui al Codice privacy inviando
					una mail all’indirizzo amministrazione@masamune.it
				</p>
				<h2 className="heading">Foro competente</h2>
				<p className="text">
					Per ogni controversia derivante dal contratto o ad esso relativa sarà
					competente:
				</p>
				<p className="text">
					a) il Foro del luogo di residenza o di domicilio del
					Cliente-Consumatore ai sensi del vigente Decreto legislativo 6
					settembre 2005 n.206 (Codice del consumo); Art.12 Clausole finali
				</p>
				<p className="text">
					Le obbligazioni e gli impegni derivanti dal presente accordo, che per
					loro natura spieghino efficacia anche dopo la scadenza, la risoluzione
					o il recesso (consentito esclusivamente nei limiti di cui all’Art. 9
					del presente contratto) dal contratto, rimarranno validi e operanti
					anche dopo tale data, fino alla loro soddisfazione.
				</p>
			</ContainerBlock>
		</section>
	);
};
