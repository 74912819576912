import React, { useEffect, useState } from "react";

import { Container, Column } from "../ui-components/columns/components";
import { GigaTitle, SmallHeading } from "../ui-components/texts";
import { CallToActionMedium } from "../ui-components/links";

const Error404 = () => {
	const [random, setRandom] = useState(null);

	let items = [
		{
			title: "OOOPS",
			description:
				"Non era previsto che tu finissi qui. Visto che ci sei, goditi il nostro Digital Consultant Andrea che orbita su se stesso.",
			image: require("../media/images/404/image-1.gif").default,
		},
		{
			title: "LUKE, SONO TUO PADRE",
			description: "Sei finito nel lato oscuro del nostro sito web.",
			image: require("../media/images/404/image-3.gif").default,
		},
		{
			title: "OOOPS, LO HAI ROTTO...",
			description:
				"Anche se sei finito in questa pagina di errore, ti giuriamo che sviluppiamo siti web meglio di come balliamo. ",
			image: require("../media/images/404/image-2.gif").default,
		},
	];

	useEffect(() => {
		const randNumber = Math.floor(Math.random() * 3);
		setRandom(items[randNumber]);
	}, []);

	return (
		<div className="wrapper-404">
			<span className="top">404</span>
			<span className="error">Error</span>

			{random !== null ? (
				<Container className="full-h-404">
					<div className="wrapper fluid align-items-center">
						<Column className="w-50 pe-0">
							<GigaTitle className={"mb-3"}>{random.title}</GigaTitle>
							<SmallHeading className={"mb-3"}>
								{random.description}
							</SmallHeading>
							<CallToActionMedium className={"white mt-3"} to="/">
								Torna alla home
							</CallToActionMedium>
						</Column>
						<Column className="w-50 image-404">
							<img src={random.image} alt={random.title} />
						</Column>
					</div>
				</Container>
			) : null}
		</div>
	);
};

export default Error404;
