import React, { useState } from "react";
import { PopupButton } from "@typeform/embed-react";

import {
	H2GigaTitle,
	LargeHeading,
	LargeText,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import {
	CallToActionMedium,
	CallToActionMicro,
} from "../../ui-components/links";
import { TogglesSectionFull } from "../../ui-components/sections";
import { icons } from "../../ui-components/icons-manager";

import image1 from "../../media/images/careers-page/cores/1.jpg";
import image2 from "../../media/images/careers-page/cores/2.jpg";
import image3 from "../../media/images/careers-page/cores/3.jpg";
import image4 from "../../media/images/careers-page/cores/4.jpg";
import image5 from "../../media/images/careers-page/cores/5.jpg";
import image6 from "../../media/images/careers-page/cores/6.jpg";
import { object } from "prop-types";
// import image8 from '../../media/images/careers-page/cores/8.jpg';

import { deviceType } from "../../utils";
import { useTranslate } from "../../contexts/translate";

export const CareersOpenPositions = () => {
	const defaultBackground = "#F1F1F1";

	const [index, setIndex] = useState(null);

	const { lang, translations } = useTranslate();
	const bgByIndex =
		translations && translations.jobs.map((item) => item.background);

	return (
		<section
			className="theme-light careers-positions grey-section animation-1s pt-15 pb-10"
			style={{
				background: index === null ? defaultBackground : bgByIndex[index],
			}}
		>
			<ContainerBlock>
				<LargeHeading>Does that sound good to you?</LargeHeading>
				<H2GigaTitle className="mb-10">
					Join the collective{" "}
					<img
						src={icons.arrow.dark.down}
						alt="Down arrow"
						className="image-item-width-lmid"
					/>
				</H2GigaTitle>

				{translations &&
					translations.jobs.map((item, i) => {
						return (
							<TogglesSectionFull
								isOpen={index === i}
								fun={() => setIndex(index === i ? null : i)}
								title={item.title}
							>
								<div dangerouslySetInnerHTML={{ __html: item.content }}></div>
								<PopupButton id={item.idTypeform}>
									{lang === "it-IT" ? "Candidati" : "Apply Now"}
								</PopupButton>
							</TogglesSectionFull>
						);
					})}
			</ContainerBlock>
		</section>
	);
};

export const CareersScores = ({ translationsCore }) => {
	const [activated, setActivated] = useState(0);

	const [className, setClassName] = useState("");

	const handleChangeActivated = (index) => {
		// close all
		setClassName("close-text-animation animation-time-8ms");
		// change
		setTimeout(() => {
			setActivated(index);
			setClassName("text-animation animation-time-8ms");
		}, 600);
		// open all
	};

	return (
		<section className="theme-light pb-5 pt-5 light-section overflow-hidden">
			<Container>
				<div className="wrapper">
					<Column className="w-50 describe-section-scroll">
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(0);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 0
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[0].title}
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(1);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 1
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[1].title}
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(2);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 2
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[2].title}
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(3);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 3
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[3].title}
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(4);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 4
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[4].title}
						</CallToActionMedium>
						<br />
						<CallToActionMedium
							onClick={() => {
								handleChangeActivated(5);
								if (deviceType() !== "desktop") {
									setTimeout(() => {
										window.scrollTo(
											0,
											document.querySelector(".describe-section-scroll")
												.offsetTop - 50
										);
									}, 750);
								}
							}}
							to="#"
							className={
								activated !== 5
									? "mb-2 black disabled fw-medium min-font"
									: "mb-2 black fw-medium min-font"
							}
						>
							{translationsCore === "" ? "" : translationsCore[5].title}
						</CallToActionMedium>
						<br />
					</Column>
					<Column className="w-50">
						{activated === 0 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[0].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image1}
									alt="Image head in the clouds"
								/>
							</>
						) : null}

						{activated === 1 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[1].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image2}
									alt="Image head in the clouds"
								/>
							</>
						) : null}

						{activated === 2 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[2].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image3}
									alt="Image head in the clouds"
								/>
							</>
						) : null}

						{activated === 3 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[3].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image4}
									alt="Image head in the clouds"
								/>
							</>
						) : null}

						{activated === 4 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[4].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image5}
									alt="Image head in the clouds"
								/>
							</>
						) : null}

						{activated === 5 ? (
							<>
								<LargeText className={className}>
									{translationsCore === ""
										? ""
										: translationsCore[5].description}
								</LargeText>
								<img
									className={`mt-5 ${className}`}
									src={image6}
									alt="Image head in the clouds"
								/>
							</>
						) : null}
					</Column>
				</div>
			</Container>
		</section>
	);
};

CareersScores.propTypes = {
	translationsCore: object,
};
