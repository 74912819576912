import React from "react";

import { CoverPorfolioImage, CoverPorfolioWrapper } from "./components";
import { GigaTitle, LargeHeading } from "../../ui-components/texts";
import { any } from "prop-types";

const CoverPorfolio = ({ white = false, className="theme-light", title, text, image, background = "#fff" }) => {
	return (
		<CoverPorfolioWrapper
			style={{ background: background }}
			className={className}
		>
			<GigaTitle className={white ? 'white' : null}>{title}</GigaTitle>
			<LargeHeading className={white ? 'white' : null}>{text}</LargeHeading>

			<CoverPorfolioImage src={image} alt={title} />
		</CoverPorfolioWrapper>
	);
};

CoverPorfolio.propTypes = {
	title: any,
	text: any,
	image: any,
	background: any,
};

export default CoverPorfolio;
