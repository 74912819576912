import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import {
	LargeText,
	MediumHeading,
	MediumTitle,
} from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/world-padel-store/cover.jpg";
import image1 from "../../media/images/portfolio-images/world-padel-store/image-wps-1.jpg";
import image2 from "../../media/images/portfolio-images/world-padel-store/image-wps-2.jpg";
import image3 from "../../media/images/portfolio-images/world-padel-store/image-wps-3.jpg";
import image4 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-1.jpg";
import image5 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-2.jpg";
import image6 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-3.jpg";
import image7 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-4.jpg";
import image8 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-5.jpg";
import image9 from "../../media/images/portfolio-images/world-padel-store/gallery-wps-6.jpg";

import { useTranslate } from "../../contexts/translate";

const WordlPadelStore = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	useEffect(() => {
		setNextPageHref("/projects/omnama");
		setNextPageName("Omnama");
		document.title = 'World Padel Store - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Magda Sgarella",
			image: require("../../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		},
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#fff"
				title="World Padel Store"
				text={translations && translations.wps.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"World Padel Store"}
				services={[
					"Digital Marketing",
					"E-commerce",
					"Still-life",
					"Social Video"
				]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.wps.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.wps.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#020303" }}
			>
				<Container>
					<div className="wrapper fluid align-items-center">
						<Column className="w-50">
							<img src={image1} alt="image 1" className="w-100" />
						</Column>
						<Column className="w-50">
							<img src={image2} alt="image 2" className="w-100" />
						</Column>
					</div>
				</Container>

				<Container className="pt-50 pb-50">
					<div className="wrapper fluid align-items-center">
						<Column className="w-100">
							<img src={image3} alt="image 3" className="w-100" />
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid">
					<MediumHeading className="black">
						{translations && translations.wps.sections[0].text}
					</MediumHeading>
				</ContainerBlock>
			</section>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#020303" }}
			>
				<Container className="fluid" style={{ padding: "0 50px" }}>
					<div className="wrapper fluid">
						<Column className="pt-0 ps-0 w-50">
							<img
								src={image4}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
							<img
								src={image5}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
							<img
								src={image6}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
						</Column>
						<Column className="pt-0 pe-0 w-50">
							<img
								src={image7}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
							<img
								src={image8}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
							<img
								src={image9}
								alt={"Image Test"}
								style={{ marginBottom: 50 }}
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid">
					<MediumHeading className="black">
						{translations && translations.wps.sections[1].text}
					</MediumHeading>
				</ContainerBlock>
			</section>



			<section
				className="theme-light pt-0 pb-0 mid-padding"
				style={{ background: "#fff" }}
			>
				<Container className="pt-50">
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 338%</MediumTitle>
							<MediumHeading>ROAS</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>2,39%</MediumTitle>
							<MediumHeading>Click-through rate</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 468%</MediumTitle>
							<MediumHeading>Lead franchising</MediumHeading>
						</Column>
						<Column className="w-40"></Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

WordlPadelStore.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default WordlPadelStore;
