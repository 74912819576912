import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import {
	Container,
	Column,
	ContainerBlock,
} from "../../ui-components/columns/components";
import {
	LargeText,
	MediumHeading,
	SmallTitle,
} from "../../ui-components/texts";
import { FullWidthVideo } from "../../ui-components/videos";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

// images
import imageCover from "../../media/images/portfolio-images/clinicsoul/image-cover.jpg";
import {
	ThreeImagesWithDescription,
	TitleWithScrollXImagesSection,
} from "./components-design";
import { useTranslate } from "../../contexts/translate";

const Clinicsoul = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	useEffect(() => {
		setNextPageHref("/projects/asmundo-di-gisira");
		setNextPageName("Asmundo di Gisira");
		document.title = 'ClinicSoul - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Gabriele Lanzafame",
			image: require("../../media/team-images/Gabriele Lanzafame.jpg").default,
			role: translations === null ? "" : translations.about.peoples[9].role,
			description:
				translations === null ? "" : translations.about.peoples[9].description,
		},
		{
			fullname: "Gabriele Nicolosi",
			image: require("../../media/team-images/Gabriele Nicolosi.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[14].role,
			description:
				translations === null ? "" : translations.about.peoples[14].description,
		},
		{
			fullname: "Claudio Mammana",
			image: require("../../media/team-images/Claudio Mammana.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[15].role,
			description:
				translations === null ? "" : translations.about.peoples[15].description,
		},
	];

	const items = [
		{
			src: require("../../media/images/portfolio-images/clinicsoul/gallery-1.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/clinicsoul/gallery-2.jpg")
				.default,
		},
		{
			src: require("../../media/images/portfolio-images/clinicsoul/gallery-3.jpg")
				.default,
		},
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#E8EFFD"
				title="ClinicSoul"
				text={translations && translations.clinicsoul.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"ClinicSoul"}
				services={["Brand Identity", "UI/UX Design", "Sito Web", "CRM"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.clinicsoul.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.clinicsoul.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.clinicsoul.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<ThreeImagesWithDescription
				color={"#2B1C53"}
				imageLeft={
					require("../../media/images/portfolio-images/clinicsoul/image-frame-1.mp4")
						.default
				}
				video={true}
				images={[
					{
						src: require("../../media/images/portfolio-images/clinicsoul/image-frame-1.jpg")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/clinicsoul/image-frame-2.jpg")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/clinicsoul/image-frame-3.jpg")
							.default,
					},
				]}
				texts={[
					translations && translations.clinicsoul.sections[0].text1,
					translations && translations.clinicsoul.sections[0].text2
				]}
				title={translations && translations.clinicsoul.sections[0].title}
				white={true}
			/>

			<TitleWithScrollXImagesSection
				color={"#7758FB"}
				title={translations && translations.clinicsoul.sections[1].title}
				text={translations && translations.clinicsoul.sections[1].text}
				items={items}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle>
						{translations && translations.clinicsoul.sections[2].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.clinicsoul.sections[2].text}
					</LargeText>
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#F0F9FF" }}
			>
				<Container className="fluid" style={{ padding: "0 50px" }}>
					<div className="wrapper fluid">
						<Column className="pt-0 ps-0 w-50">
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-h-1.png")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-s-2.png")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-h-3.png")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
						<Column className="pt-0 pe-0 w-50">
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-s-1.png")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-h-2.png")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								src={
									require("../../media/images/portfolio-images/clinicsoul/gallery-s-3.png")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<MediumHeading>
						{translations && translations.clinicsoul.sections[3].text}
					</MediumHeading>
				</ContainerBlock>
			</section>

			<FullWidthVideo
				className={'theme-dark'}
				srcVideo={
					require("../../media/images/portfolio-images/clinicsoul/video.mp4")
						.default
				}
				isImage={false}
				previewImage={"#2B1C53"}
			/>
			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Clinicsoul.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Clinicsoul;
