import React, { useState } from "react";

import { Column, Container } from "../../ui-components/columns/components";
import {
	PeopleMasamuneBody,
	PeopleMasamuneImage,
	PeopleMasamuneItem,
	PeopleMasamuneItemFullname,
	PeopleMasamuneItemIcon,
	PeopleMasamuneItemRole,
	PeopleOfMasamuneWrapper,
} from "./components";
import { icons } from "../../ui-components/icons-manager";
import { H2GigaTitle, SmallText } from "../../ui-components/texts";
import { any } from "prop-types";

const PeopleOfMasamune = ({ peoples = [], title = "People of Masamune" }) => {
	const [activated, setActivated] = useState(0);

	return (
		<PeopleOfMasamuneWrapper className="theme-light">
			<H2GigaTitle>{title}</H2GigaTitle>
			<Container className="theme-light">
				<div className="wrapper fluid">
					<Column className="width-min">
						<PeopleMasamuneImage
							style={{ position: 'sticky', top: 50 }}
							className="no-phone"
							src={peoples[activated].image}
							alt={peoples[activated].fullname}
						/>
					</Column>
					<Column className="width-max">
						{peoples.map((item, index) => {
							return (
								<PeopleMasamuneItem key={index}>
									<div className="base-info">
										<PeopleMasamuneItemFullname>
											{item.fullname}
										</PeopleMasamuneItemFullname>
										<PeopleMasamuneItemRole>{item.role}</PeopleMasamuneItemRole>
										<PeopleMasamuneItemIcon
											src={icons.morePlus}
											alt="Click for more info"
											onClick={() => setActivated(index)}
										/>
									</div>
									<PeopleMasamuneBody
										className={activated === index && item.description != "" ? "open" : "close"}
									>
										{item.description && item.description != "" &&
											<SmallText
												className="no-phone"
												dangerouslySetInnerHTML={{
													__html: item.description,
												}}
											></SmallText>
										}

										<PeopleMasamuneImage
											className="only-for-phone"
											src={item.image}
											alt={item.fullname}
										/>
									</PeopleMasamuneBody>
								</PeopleMasamuneItem>
							);
						})}
					</Column>
				</div>
			</Container>
		</PeopleOfMasamuneWrapper>
	);
};

PeopleOfMasamune.propTypes = {
	peoples: any,
	title: any,
};

export default PeopleOfMasamune;
