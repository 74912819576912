import { any } from "prop-types";
import React, { useRef, useState } from "react";
import { H3GigaTitle } from "../texts";

import { VideoWrapperSettings } from "./components";
import { deviceType } from '../../utils';

export const FullWidthVideo = ({
	previewImage,
	className,
	isImage = true,
	srcVideo = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
	title = "Play video",
}) => {
	const [pause, setPause] = useState(true);
	const video = useRef();

	const handlePauseVideo = () => {
		if (pause) {
			video.current.play();
			document.querySelector("body").style.overflow = "hidden";
		} else {
			video.current.pause();
			document.querySelector("body").removeAttribute('style');
		}

		setPause(!pause);
	};

	return (
		<VideoWrapperSettings
			onClick={handlePauseVideo}
			className={
				pause ? `${className} full-width-video-wrapper` : `${className} full-width-video-wrapper start`
			}
			style={
				isImage
					? { backgroundImage: `url("${previewImage}")` }
					: { background: previewImage }
			}
		>
			{
				deviceType() !== 'desktop' && !pause && 
				<div className="close-video">&times;</div>
			}
			<video
				width="100%"
				src={srcVideo}
				ref={video}
				id="video-player"
				controls={false}
			></video>
			<H3GigaTitle style={{ textAlign: "center" }}>{title}</H3GigaTitle>
		</VideoWrapperSettings>
	);
};

FullWidthVideo.propTypes = {
	previewImage: any,
	isImage: any,
	srcVideo: any,
	title: any,
};
