import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { Container, Column } from "../../ui-components/columns/components";
import {
	LargeText,
	MediumTitle,
	SmallHeading,
} from "../../ui-components/texts";
import { FullWidthVideo } from "../../ui-components/videos";

// images
import imageCover from "../../media/images/portfolio-images/us-lecce/image-cover.jpg";
import imagePortfolio1 from "../../media/images/portfolio-images/us-lecce/image-frame-1.jpg";
import imagePortfolio2 from "../../media/images/portfolio-images/us-lecce/image-frame-2.jpg";
import imagePortfolio3 from "../../media/images/portfolio-images/us-lecce/image-frame-3.jpg";
import imageBio from "../../media/images/portfolio-images/us-lecce/image-bio.jpg";
import previewVideo from "../../media/images/portfolio-images/us-lecce/image-preview-video.jpg";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { useTranslate } from "../../contexts/translate";

const USLecce = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/clinicsoul");
		setNextPageName("ClinicSoul");
		document.title = 'US Lecce - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Luca Distefano",
			image: require("../../media/team-images/no-image.png").default,
			role: "Former Video Maker",
			description: "",
		},
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#fff"
				title="US Lecce Calcio"
				text={translations && translations.uslecce.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"US Lecce Calcio"}
				services={["Video Social"]}
				timeframe={"2019"}
				introduction={
					<>
						<LargeText>
							{translations && translations.uslecce.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.uslecce.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light images-portfolio-section"
				style={{ background: "#FFFAEE" }}
			>
				<img
					className="image-portfolio-start"
					src={imagePortfolio1}
					alt={"Image 1"}
				/>
				<img
					className="image-portfolio-center"
					src={imagePortfolio2}
					alt={"Image 2"}
				/>
				<img
					className="image-portfolio-end"
					src={imagePortfolio3}
					alt={"Image 3"}
				/>
			</section>

			<section className="theme-dark" style={{ background: "#000" }}>
				<Container className="image-inside">
					<div className="wrapper padding-project-page align-items-center">
						<Column className="w-30 padding-0">
							<img src={imageBio} alt="Image" />
						</Column>
						<Column className="ps-10 w-70 padding-0">
							<MediumTitle className="white mb-5">
								{translations && translations.uslecce.sections[0].title}
							</MediumTitle>
							<SmallHeading className="white mb-5">
								{translations && translations.uslecce.sections[0].text1}
							</SmallHeading>
							<SmallHeading className="white mb-5">
								{translations && translations.uslecce.sections[0].text2}
							</SmallHeading>
						</Column>
					</div>
				</Container>
			</section>

			<FullWidthVideo
				title="Play video"
				previewImage={previewVideo}
				srcVideo={
					require("../../media/images/portfolio-images/us-lecce/video.mp4")
						.default
				}
			/>
			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

USLecce.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default USLecce;
