import { any } from "prop-types";
import React from "react";
import {
	Column,
	Container,
} from "../../../ui-components/columns/components";
import {
	ScrollXImages,
} from "../../../ui-components/force-scroll-x";
import {
	LargeText,
	MediumTitle,
	SmallHeading,
} from "../../../ui-components/texts";

export const TitleWithScrollXImagesSection = ({
	color,
	title,
	className,
	text,
	text2 = null,
	items = [],
	white = true,
	noPadding = false,
}) => {
	return (
		<section
			className={`mid-padding overflow-hidden ${className}`}
			style={{ background: color }}
		>
			<Container>
				<div className="wrapper fluid no-column" style={{ padding: noPadding ? 0 : "0 50px" }}>
					<Column className="px-0">
						<MediumTitle className={white ? "white mb-3" : "mb-3"}>
							{title}
						</MediumTitle>
						<LargeText className={white ? "white mb-5" : "mb-3"}>{text}</LargeText>
						{ text2 && <LargeText className={white ? "white mb-5" : "mb-3"}>{text2}</LargeText> }
					</Column>

					<Column className="px-0 pt-0">
					{items.length > 0 ? (
							<ScrollXImages className={white ? "pt-2 pb-0 light" : "pt-2 pb-0"} items={items} />
						) : null}
					</Column>
				</div>
			</Container>
		</section>
	);
};

TitleWithScrollXImagesSection.propTypes = {
	color: any,
	title: any,
	text: any,
	items: any,
	white: any,
};

export const ThreeImagesWithDescription = ({
	color,
	title,
	texts,
	imageLeft,
	className,
	images = [],
	white,
	video = false,
}) => {
	return (
		<section className={`${className} mid-padding theme-dark overflow-hidden`} style={{ background: color }}>
			<Container>
				<div className="wrapper fluid p-0 align-items-center">
					<Column className="w-50">
						{video ? (
							<video
								className="w-100"
								src={imageLeft}
								autoPlay={true}
								loop={true}
								muted={true}
								controls={false}
								playsInline={true}
							/>
						) : (
							<img src={imageLeft} className="w-100" alt="Icon clinicsoul" />
						)}
					</Column>
					<Column className="w-50">
						<MediumTitle className={white ? "white mb-5" : "mb-5"}>
							{title}
						</MediumTitle>
						{texts.map((item, index) => {
							return (
								<SmallHeading
									key={index}
									className={white ? "white mb-5" : "mb-5"}
								>
									{item}
								</SmallHeading>
							);
						})}
					</Column>
				</div>
			</Container>

			<Container>
				<div className="wrapper fluid p-0 align-items-center">
					<Column>
						{images.length > 0 ? (
							<ScrollXImages className={white ? "pb-0 mb-0 light" : "pb-0 mb-0"} items={images} />
						) : null}
					</Column>
				</div>
			</Container>
		</section>
	);
};

ThreeImagesWithDescription.propTypes = {
	color: any,
	title: any,
	texts: any,
	images: any,
	white: any,
};
