import React, { useEffect, useRef, useState } from "react";

import logoMasamune from "../../media/icons/logo-masamune-full-white.svg";
import logoMasamuneBlack from "../../media/icons/logo-masamune-full-black.svg";

import "./animations.scss";
import { LargeTitle } from "../texts";

import { useTranslate } from '../../contexts/translate';

const Intro = () => {
	const elAnimation = useRef();

	const [step, setStep] = useState(0);
	const [closeText, setCloseText] = useState(false);
	const [text, setText] = useState(0);
	const [destroy, setDestroy] = useState(false);

	const { translations } = useTranslate();

	useEffect(() => {
		document.getElementsByTagName("body")[0].style.overflow = "hidden";

		elAnimation.current.addEventListener(
			"animationend",
			function () {
				setTimeout(() => {
					setStep(1);
				}, 500);
			},
			false
		);
	}, []);

	useEffect(() => {
		document.getElementsByTagName("body")[0].style.overflow = "hidden";

		if (step === 1) {
			/*setInterval(() => {
				setCloseText(true);

				setTimeout(() => {
					if (text !== 2) {
						setText((x) => x + 1);
					}
					setCloseText(false);
				}, 1000);
			}, 4000); */
			//remove this if you want to activate the text animation again
			setTimeout(() => {
				document.getElementsByTagName("body")[0].removeAttribute('style');
				setDestroy(true);
			}, 1000);
		}
	}, [step]);

	

	/*useEffect(() => {
		if (text === 2) {
			setTimeout(() => {
				document.getElementsByTagName("body")[0].removeAttribute('style');
				setDestroy(true);
			}, 3000);
		}
	}, [text]); */

	

	useEffect(() => {
		if (destroy) {
			return localStorage.setItem('intro-settings', new Date().getTime() + (3600 * 24 * 1000));
		}

		if (typeof localStorage.getItem('intro-settings') === 'string') {
			let seconds = parseInt(localStorage.getItem('intro-settings'));

			if (new Date().getTime() <= seconds) {
				setTimeout(() => {
					document.getElementsByTagName("body")[0].removeAttribute('style');
				}, 100)
				setStep(null);
			}
		}
	}, [destroy]);

	if (step === null) return <></>;

	if (step === 0) {
		return (
			<div className="intro step-1">
				{/* Logo animations */}
				<div className="overlay-logo" ref={elAnimation}>
					<img
						className="logo-intro-animation"
						alt="Logo Masamune"
						width={258}
						src={logoMasamune}
					/>
				</div>
			</div>
		);
	}

	if (step === 1) {
		return (
			//remove this line if you want to activate the text animation again
			<div className={"destroy intro step-2" }></div>
			/*
			<div className={destroy ? "destroy intro step-2" : "intro step-2"}>
				<img
					className={destroy ? "close-text-animation" : "text-animation"}
					alt="Logo Masamune"
					src={logoMasamuneBlack}
				/>
				/*<div className="open-white-background">
					{text === 0 ? (
						<div className="center-text-top">
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[0]}
							</LargeTitle>
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[1]}
							</LargeTitle>
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[2]}
							</LargeTitle>
						</div>
					) : null}
					{text === 1 ? (
						<div className="center-text-top">
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[3]}
							</LargeTitle>
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[4]}
							</LargeTitle>
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[5]}
							</LargeTitle>
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[6]}
							</LargeTitle>
						</div>
					) : null}

					{text === 2 ? (
						<div className="center-text-top">
							<LargeTitle
								style={{ fontWeight: 500 }}
								className={
									closeText ? "close-text-animation" : "text-animation"
								}
							>
								{translations && translations.intro[7]}
							</LargeTitle>
						</div>
					) : null}
				</div>
			</div>  */
		);
	}
};

export default Intro;
