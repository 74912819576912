import React, { useEffect, useRef, useState } from "react";
import { bgFirst } from "../../constants/colors";
import { useThemeContext } from "../../utils/theme-context";
import { Container, Column } from "../../ui-components/columns/components";
import { FullHeightSection } from "../../ui-components/sections";
import { deviceType, touchWork } from "../../utils";
import { initScrollFooter } from "../../utils/scroll-handlers";
import { useTranslate } from "../../contexts/translate";

export const CuttingEdgeAnimation = ({ data }) => {
	const wrapper = useRef();
	const {
		setColorIconDark,
		setColorLangDark,
		setColorScrollDark,
		setColorArrowDark,
		setColorNeedHelpDark,
	} = useThemeContext();

	const [classNameWrapper, setClassNameWrapper] = useState(
		"theme-dark block-scroll block-scroll-body center-in-content masamune-animation"
	);

	const [weAre, setWeAre] = useState("we-are text-animation t-center");
	const [index, setIndex] = useState(null);
	const [classNameLink, setClassNameLink] = useState("t-center");
	const [classText, setClassText] = useState([
		"close-text-animation none",
		"close-text-animation none",
		"close-text-animation none",
		"close-text-animation none",
	]);
	const [detectedScroll, setDetectedScroll] = useState(0);
	const [reverse, setReverse] = useState(false);

	const { translations } = useTranslate();

	/*const handleGoNext = async (desktop = false) => {
		if (
			window.scrollY <=
			document.querySelector(".block-scroll-body").offsetTop - 5
		)
			return;

		setTimeout(() => {
			if (index === 0) {
				setIndex(1);

				setClassText([
					"text-animation",
					"close-text-animation none",
					"close-text-animation none",
					"close-text-animation none",
				]);

				return;
			}

			if (index === 1) {
				setIndex(2);

				setTimeout(() => {
					setClassText([
						"close-text-animation",
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"text-animation",
							"close-text-animation none",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 2) {
				setIndex(3);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation",
						"close-text-animation none",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"close-text-animation none",
							"text-animation",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 3) {
				setIndex(4);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
							"text-animation",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 4) {
				setIndex(null);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
						]);

						setClassNameWrapper(
							"theme-dark block-scroll center-in-content masamune-animation"
						);
						setWeAre("we-are text-animation t-center");
						setIndex(null);
						setClassNameLink("t-center");
						document.querySelector(".masamune-animation").style.position =
							"static";
						document.querySelector(".masamune-animation").style.zIndex = 0;
						document.querySelector("body").removeAttribute("style");
					}, 400);
				}, 100);

				return;
			}
		}, 0);
	};

	const handleGoPrev = async (desktop = false) => {
		if (
			window.scrollY >=
			document.querySelector(".block-scroll-body").offsetTop - 5
		)
			return;

		setTimeout(() => {
			if (index === 0) {
				return;
			}

			if (index === 1) {
				setIndex(0);

				setTimeout(() => {
					setClassText([
						"close-text-animation",
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 2) {
				setIndex(1);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation",
						"close-text-animation none",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"text-animation",
							"close-text-animation none",
							"close-text-animation none",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 3) {
				setIndex(2);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation",
						"close-text-animation none",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"text-animation",
							"close-text-animation none",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}

			if (index === 4) {
				setIndex(3);

				setTimeout(() => {
					setClassText([
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation none",
						"close-text-animation",
					]);

					setTimeout(() => {
						setClassText([
							"close-text-animation none",
							"close-text-animation none",
							"text-animation",
							"close-text-animation none",
						]);
					}, 400);
				}, 100);

				return;
			}
		}, 0);
	}; 

	useEffect(() => {
		if (deviceType() === "desktop")
			return console.log("Your device cannot use this function"); // todo fix
		if (!touchWork()) return;
		// squaresContent.current.style.transform = `translateX(-${(index * 100) - 100}%)`;
	}, [index]);

	useEffect(() => {
		if (deviceType() !== "desktop")
			return console.log("Your device cannot use this function"); // todo fix
		if (touchWork()) return;

		window.addEventListener("scroll", () => {
			if (document.querySelector(".block-scroll-body") === null) return;

			if (
				window.scrollY >=
				document.querySelector(".block-scroll-body").offsetTop - 5
			) {
				// window.scrollTo(0, document.querySelector('.block-scroll-body').offsetTop)

				document.querySelector("body").style.overflow = "hidden";

				document.querySelector(".block-scroll-body").style.position = "fixed";
				document.querySelector(".masamune-animation").style.zIndex = 9;
				document.querySelector(".block-scroll-body").style.top = 0;
				document.querySelector(".block-scroll-body").style.bottom = 0;
				document.querySelector(".block-scroll-body").style.right = 0;
				document.querySelector(".block-scroll-body").style.left = 0;

				// fixed theme icons
				setColorIconDark(false);
				setColorLangDark(false);
				setColorScrollDark(false);
				setColorArrowDark(false);
				setColorNeedHelpDark(false);

				setTimeout(() => {
					setWeAre("we-are close-text-animation t-center");

					setTimeout(() => {
						setWeAre("we-are close-text-animation t-left");
						setClassNameLink("t-left");

						setTimeout(() => {
							setWeAre("we-are text-animation t-left");
							// setClassNameLink("t-left");

							setTimeout(() => {
								setIndex(0);
							}, 1000);
						}, 300);
					}, 600);
				}, 250);
			}
		});

		window.addEventListener("wheel", (e) => {
			if (document.querySelector(".block-scroll-body") === null) return;

			if (
				detectedScroll === -1 &&
				e.deltaY >= 100 &&
				e.deltaY <= 120 &&
				window.scrollY >=
					document.querySelector(".block-scroll-body").offsetTop - 5
			) {
				setDetectedScroll(0);
			}

			if (
				detectedScroll === -1 &&
				e.deltaY >= -120 &&
				e.deltaY <= -100 &&
				window.scrollY >=
					document.querySelector(".block-scroll-body").offsetTop - 5
			) {
				setDetectedScroll(1);
			}
		});

		return function cleanup() {
			window.removeEventListener("scroll", () => {});
			window.removeEventListener("wheel", () => {});
		};
	}, [index]);

	useEffect(() => {
		if (detectedScroll === -1) return;
		setDetectedScroll(-1);

		setTimeout(() => {
			if (detectedScroll === 0) handleGoNext(true);
			if (detectedScroll === 1) handleGoPrev(true);
		}, 150);
	}, [detectedScroll, reverse]);

	*/

	return (
		<FullHeightSection
			ref={wrapper}
			className={classNameWrapper}
			style={{ background: bgFirst }}
		>
			<Container className="center">
				<div className="wrapper mid-full align-items-center p-0">
					<Column
						className={
							index !== null
								? "w-50 animation-cutting-edge"
								: "w-100 animation-cutting-edge"
						}
					>
						<div className={weAre}>WE ARE</div>

						<div className={`d-block position-relative`}>
							<div
								className={
									index === null
										? `item min-text-mobile ${classNameLink}`
										: index === 0
										? "item min-text-mobile disabled"
										: index === 1
										? "item min-text-mobile active"
										: "item min-text-mobile disabled"
								}
							>
								Cutting-edge
							</div>
						</div>
						<div className={`d-block position-relative`}>
							<div
								className={
									index === null
										? `item min-text-mobile ${classNameLink}`
										: index === 0
										? "item min-text-mobile disabled"
										: index === 2
										? "item min-text-mobile active"
										: "item min-text-mobile disabled"
								}
							>
								Digital
							</div>
						</div>
						<div className={`d-block position-relative`}>
							<div
								className={
									index === null
										? `item min-text-mobile ${classNameLink}`
										: index === 0
										? "item min-text-mobile disabled"
										: index === 3
										? "item min-text-mobile active"
										: "item min-text-mobile disabled"
								}
							>
								Creative
							</div>
						</div>
						<div className={`d-block position-relative`}>
							<div
								className={
									index === null
										? `item min-text-mobile ${classNameLink}`
										: index === 0
										? "item min-text-mobile disabled"
										: index === 4
										? "item min-text-mobile active"
										: "item min-text-mobile disabled"
								}
							>
								Studio
							</div>
						</div>
					</Column>
					{index !== null ? (
						<Column
							className={index !== null ? "w-50 ps-0 py-0" : "w-0 ps-0 py-0"}
						>
							<div className={`cutting-edge-description ${classText[0]}`}>
								{translations ? translations.about.animations[0] : ""}
							</div>
							<div className={`cutting-edge-description ${classText[1]}`}>
								{translations ? translations.about.animations[1] : ""}
							</div>
							<div className={`cutting-edge-description ${classText[2]}`}>
								{translations ? translations.about.animations[2] : ""}
							</div>
							<div className={`cutting-edge-description ${classText[3]}`}>
								{translations ? translations.about.animations[3] : ""}
							</div>
						</Column>
					) : null}
				</div>
			</Container>
		</FullHeightSection>
	);
};
