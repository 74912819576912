import React, { useState } from "react";
import styled from "styled-components";

import { colorGrey4, secondary } from "../../constants/colors";
import { Column, Container } from "../columns/components";
import { SmallAltTitle, SmallHeading } from "../texts";
import { icons } from "../icons-manager";
import { any } from "prop-types";

export const FullHeightSectionSquare = styled.section`
	height: 102vh;
	width: 100%;
	position: relative;
	z-index: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;

	#follow-square {
		padding: 200px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background: transparent;
		-moz-border-radius: 504em;
		border: 5000px solid ${secondary};
		opacity: 1;
		-webkit-transition: opacity 0.5s ease;
		-moz-transition: opacity 0.5s ease;
		transition: opacity 0.5s ease;
	}

	&:hover {
		#follow-square {
			opacity: 1;
			cursor: none;
		}
	}

	.center-in-content {
		position: relative;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	.absolute-image {
		position: absolute;
		z-index: 1;
		top: 30%;
		left: 50%;
	}
`;

export const FullHeightSection = styled.section`
	height: 100vh;
	width: 100%;
	position: relative;
	z-index: 0;
	background: ${secondary};
	overflow: hidden;

	&.align-center-items-d-flex {
		display: flex;
		align-items: center;
	}

	.center-in-content {
		position: relative;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	.center {
		position: relative;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const TogglesSectionWrapper = styled.div`
	margin-bottom: 10px;
`;

export const TogglesSectionWrapperFull = styled.div`
	border-top: 1px solid ${colorGrey4};

	.wrapper {
		padding: 40px 20px;
	}

	&:last-of-type {
		border-bottom: 1px solid ${colorGrey4};
	}
`;

export const TogglesSectionBody = styled.div`
	max-height: 0;
	width: 100%;
	overflow: hidden;
	transition: all 0.4s ease-in-out;

	&.opened {
		max-height: 900px;
		overflow: auto;
	}
`;

export const TogglesSectionBodyFull = styled.div`
	max-height: 0;
	width: 100%;
	overflow: hidden;
	transition: all 0.6s ease;

	&.opened {
		max-height: 2000px;
		overflow: auto;
		padding-bottom: 40px;
	}
`;

export const TogglesSection = ({ children, title, className, isOpen, onChange }) => {
	return (
		<TogglesSectionWrapper>
			<Container>
				<div className="wrapper align-items-center flex-direction-row m-0 p-0">
					<Column className="p-0">
						<SmallHeading className={className} style={{ fontWeight: 500 }}>
							{title}
						</SmallHeading>
					</Column>
					<Column
						className={"p-0 ps-3 icon-inline icon-animation " + className}
					>
						<img
							onClick={onChange}
							src={icons.chevron.dark.down}
							className={isOpen ? "rotate-180" : null}
							alt="Click for open"
						/>
					</Column>
				</div>
				<TogglesSectionBody className={isOpen ? "opened" : "closed"}>
					{children}
				</TogglesSectionBody>
			</Container>
		</TogglesSectionWrapper>
	);
};

export const TogglesSectionFull = ({
	className,
	children,
	title,
	isOpen,
	fun = () => {},
}) => {

	return (
		<TogglesSectionWrapperFull className={className} id={String(title).toLowerCase().replaceAll(' ', '-')}>
			<Container className="move-here">
				<div className="wrapper full align-items-center flex-direction-row ps-0 pe-0">
					<Column className="p-0">
						<SmallAltTitle style={{ fontWeight: 500 }}>{title}</SmallAltTitle>
					</Column>
					<Column className="p-0 ps-3 ms-auto image-item-width-lmin icon-inline	icon-animation">
						<img
							onClick={(e) => {
								fun(e)
								setTimeout(() => {
									window.location.href = `${window.location.pathname}#${String(title).toLowerCase().replaceAll(' ', '-')}`;
								}, 500);
							}}
							src={icons.morePlus}
							className={isOpen ? "rotate-45" : null}
							alt="Click for open"
						/>
					</Column>
				</div>
				<TogglesSectionBodyFull className={isOpen ? "opened" : "closed"}>
					{children}
				</TogglesSectionBodyFull>
			</Container>
		</TogglesSectionWrapperFull>
	);
};

TogglesSection.propTypes = {
	children: any,
	title: any,
	className: any,
};

TogglesSectionFull.propTypes = {
	fun: any,
	children: any,
	title: any,
	className: any,
};
