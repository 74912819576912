import React, { useEffect } from "react";
import { any } from "prop-types";

import Cover from "../components/cover";

import {
	GigaTitle,
	H2GigaTitle,
	MediumHeading,
	SmallHeading,
	SmallTitle,
} from "../ui-components/texts";
import PageAnimationHandler from "../ui-components/animations/page-animation-handler";
import TwoColumnsWithImage from "../ui-components/columns/two-columns-with-image";

import imageCover1 from "../media/images/legend-page/2.jpg";
import imageCover2 from "../media/images/legend-page/1.jpg";
import imageSection1 from "../media/images/legend-page/image-content-1.jpg";
import imageSection2 from "../media/images/legend-page/image-content-2.jpg";
import imageSection3 from "../media/images/legend-page/3.jpeg";
import imageSectionFull from "../media/images/legend-page/4.jpg";
import { bgGrey } from "../constants/colors";
import { useTranslate } from "../contexts/translate";

const Legend = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/about");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.about
		);
		document.title = 'Legend - Cutting Edge Digital Creative Studio'
	}, [translations]);

	return (
		<section className="legend-page-component">
			<Cover imageTop={imageCover1} imageBottom={imageCover2}>
				<GigaTitle className="min-line-height">
					<span className="text-animation animation-delay-05s">
						From Legend
					</span>
					<span className="text-animation animation-delay-05s">to Reality</span>
				</GigaTitle>
			</Cover>

			<section className="theme-light mid-padding light-section">
				<TwoColumnsWithImage
					srcImage={imageSection1}
					wrapperClassName={"fluid"}
					columnImageClassName="w-40"
					columnClassName="w-60"
				>
					<SmallTitle>
						{translations === null ? "" : translations.legend.section1}
					</SmallTitle>
				</TwoColumnsWithImage>

				<H2GigaTitle
					style={{ color: bgGrey }}
					className="pe-5 pt-5 pb-5 text-end"
				>
					{translations === null ? "" : translations.legend.section2}
				</H2GigaTitle>

				<TwoColumnsWithImage
					srcImage={imageSection2}
					reverse={true}
					className="fix-on-mobile-legend me-0 pe-0"
					wrapperClassName="fluid me-0 pe-0"
					columnClassName="w-60"
					columnImageClassName="pe-0 w-40"
				>
					<SmallHeading className="mb-4" style={{ fontWeight: 500 }}>
						{translations === null ? "" : translations.legend.section3}
					</SmallHeading>
					<SmallHeading style={{ fontWeight: 500 }}>
						{translations === null ? "" : translations.legend.section4}
					</SmallHeading>
				</TwoColumnsWithImage>

				<img
					src={imageSectionFull}
					alt="Full image of legend"
					className="w-100 d-block mid-padding"
				/>

				<TwoColumnsWithImage
					srcImage={imageSection3}
					reverse={true}
					className="me-0 pe-0"
					wrapperClassName="fluid"
					columnClassName="w-60"
					columnImageClassName="w-40"
				>
					<MediumHeading className="mb-5" style={{ fontWeight: 500 }}>
						{translations === null ? "" : translations.legend.section5}
					</MediumHeading>
					<MediumHeading style={{ fontWeight: 500 }}>
						{translations === null ? "" : translations.legend.section6}
					</MediumHeading>
				</TwoColumnsWithImage>
			</section>
		</section>
	);
};

Legend.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Legend;
