import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/tbg/image-cover.png";
import { ContainerBlock } from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

const Tbg = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/capable");
		setNextPageName("Capable");
		document.title = "Top Building Group - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../../media/team-images/Samuele Olindo.png").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
	];

	return (
		<div className="project-page project-page-cips">
			<CoverPorfolio
				background="#121B1D"
				className="theme-dark"
				title="Top Building Group"
				white={true}
				text={translations && translations.tbg.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Top Building Group"}
				services={[ "Brand Identity", "Development"]}
				timeframe={"2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.tbg.descriptions[0]}
						</LargeText>
						
					</>
				}
			/>

		
			<TitleWithScrollXImagesSection
				className="theme-dark"
				color={"#121B1D"}
				title={translations && translations.tbg.sections[0].title}
				text={translations && translations.tbg.sections[0].text1}
				items={[
					{
						src: require("../../media/images/portfolio-images/tbg/gallery1/1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/tbg/gallery1/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/tbg/gallery1/3.png")
							.default,
					},
                    {
						src: require("../../media/images/portfolio-images/tbg/gallery1/4.png")
							.default,
					},
				]}
			/>

			

			<TitleWithScrollXImagesSection
				color={"#AC8C66"}
				className="theme-dark"
				title={translations && translations.tbg.sections[1].title}
				text={translations && translations.tbg.sections[1].text}
				items={[
					{
						src: require("../../media/images/portfolio-images/tbg/gallery2/1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/tbg/gallery2/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/tbg/gallery2/3.png")
							.default,
					},
                    {
						src: require("../../media/images/portfolio-images/tbg/gallery2/4.png")
							.default,
					},
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Tbg.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Tbg;
