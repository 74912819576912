import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { FullWidthVideo } from "../../ui-components/videos";
import {
	LargeText,
	MediumHeading,
	MediumTitle,
	SmallTitle,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/correre-naturale/image-cover.jpg";
import image1 from "../../media/images/portfolio-images/correre-naturale/image-1.jpg";
import image2 from "../../media/images/portfolio-images/correre-naturale/image-2.jpg";
import testImage from "../../media/test-image-square.jpg";
import { useTranslate } from "../../contexts/translate";

const CorrereNaturale = ({
	setNextPageHref = () => { },
	setNextPageName = () => { },
}) => {
	useEffect(() => {
		setNextPageHref("/projects/ofit");
		setNextPageName("Ofit");
		document.title = 'Correre Naturale - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Magda Sgarella",
			image: require("../../media/team-images/Magda Sgarella.jpg").default,
			role: translations === null ? "" : translations.about.peoples[5].role,
			description:
				translations === null ? "" : translations.about.peoples[5].description,
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#fff"
				title="Correre Naturale"
				text={translations && translations.correrenaturale.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Correre Naturale"}
				services={["Digital Strategy", "Social Advertising"]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.correrenaturale.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.correrenaturale.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.correrenaturale.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#F0FFC9" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="d-flex flex-column w-50">
							<SmallTitle className="mb-5">
								{translations && translations.correrenaturale.sections[0].title}
							</SmallTitle>

							<img src={image2} className="mt-auto" alt="image 1" />
						</Column>
						<Column className="d-flex flex-column w-50">
							<LargeText>
								{translations && translations.correrenaturale.sections[0].text}
							</LargeText>

							<img src={image1} className="mt-5" alt="image 2" />
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-100">
							<MediumHeading>
								{translations && translations.correrenaturale.sections[1].text}
							</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container className="pt-50">
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 1040%</MediumTitle>
							<MediumHeading>ROAS</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>1,10%</MediumTitle>
							<MediumHeading>Click-through rate</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>6,60%</MediumTitle>
							<MediumHeading>Conversion Rate Webinar</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>2,45%</MediumTitle>
							<MediumHeading>Conversion Rate Running School</MediumHeading>
						</Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

CorrereNaturale.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default CorrereNaturale;
