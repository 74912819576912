import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText } from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

// images
import imageCover from "../../media/images/portfolio-images/biz-academy/image-cover.jpg";

import { TitleWithScrollXImagesSection } from "./components-design";
import { useTranslate } from "../../contexts/translate";

const BizAcademy = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/us-lecce");
		setNextPageName("US Lecce");
		document.title = 'Biz Academy - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
		{
			fullname: "Claudio Mammana",
			image: require("../../media/team-images/Claudio Mammana.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[15].role,
			description:
				translations === null ? "" : translations.about.peoples[15].description,
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#ffedf0"
				title="Biz Academy"
				text={translations && translations.biz.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Biz Academy"}
				services={["UI/UX Design", "Sito Web", "CRO"]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.biz.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.biz.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				color={"#FFC0CB"}
				white={false}
				title={translations && translations.biz.sections[0].title}
				text={translations && translations.biz.sections[0].text}
				items={[
					{ src: require('../../media/images/portfolio-images/biz-academy/gallery/1.jpg').default },
					{ src: require('../../media/images/portfolio-images/biz-academy/gallery/2.jpg').default },
					{ src: require('../../media/images/portfolio-images/biz-academy/gallery/3.jpg').default }
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

BizAcademy.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default BizAcademy;
