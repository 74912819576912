import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText, MediumTitle } from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";

// images
import imageCover from "../../media/images/portfolio-images/us-lecce/image-cover.jpg";
import testSquare from "../../media/test-image-square.jpg";
import { ImageLeftWithText, ThreeImagesSection } from "./components-content";
import { TitleWithScrollXImagesSection } from "./components-design";
import { useTranslate } from "../../contexts/translate";

const Trident = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/cips")
		setNextPageName("CIPS")
		document.title = 'Trident - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#c2cde0"
				title="Trident"
				text={translations && translations.trident.intro}
				image={require('../../media/images/portfolio-images/trident/cover.jpg').default}
			/>

			<DetailsProject
				client={"Trident"}
				services={["Photography", "Corporate & Visual Identity"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.trident.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.trident.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<ImageLeftWithText
				className="theme-light"
				color="#c2cde0"
				title={translations && translations.trident.sections[0].title}
				texts={[
					translations && translations.trident.sections[0].text
				]}
				image={require('../../media/images/portfolio-images/trident/image-1.jpg').default}
				white={false}
			/>

			<TitleWithScrollXImagesSection
				color={"#fff"}
				className="theme-dark"
				white={false}
				noPadding={true}
				items={[
					{ src: require('../../media/images/portfolio-images/trident/gallery-1/trident-1.jpg').default },
					{ src: require('../../media/images/portfolio-images/trident/gallery-1/trident-2.jpg').default },
					{ src: require('../../media/images/portfolio-images/trident/gallery-1/trident-4.jpg').default },
					{ src: require('../../media/images/portfolio-images/trident/gallery-1/trident-5.jpg').default },
				]}
			/>

			<section className="pt-1 theme-light mid-padding" style={{ background: '#c2cde0' }}>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50 d-flex flex-column">
							<MediumTitle className="mb-4">{translations && translations.trident.sections[1].title}</MediumTitle>
							<img src={require('../../media/images/portfolio-images/trident/image-2.png').default} alt="Image 1" className="mt-5" />
						</Column>
						<Column className="w-50 d-flex flex-column">
							<LargeText className="mb-4">{translations && translations.trident.sections[1].text}</LargeText>
							<img src={require('../../media/images/portfolio-images/trident/image-3.png').default} alt="Image 1" className="mt-auto" />
						</Column>
					</div>
				</Container>
			</section>
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Trident.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Trident;
