import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/costumi-di-sicilia/image-cover.png";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

const CostumiDiSicilia = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/papini-store");
		setNextPageName("Papini Store");
		document.title =
			"Costumi Di Sicilia - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		},
		{
			fullname: "Fabrizio Lo Faro",
			image: require("../../media/team-images/no-image.png").default,
			role: 'n/a',
			description: 'n/a',
		},
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
	];

	return (
		<div className="project-page project-page-cds">
			<CoverPorfolio
				background="#d4ce91"
				className="theme-dark"
				title="Costumi di Sicilia"
				white={true}
				text={translations && translations.cds.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Costumi di Sicilia"}
				services={[
					"Social Media Management",
					"E-commerce Development",
					"Photography",
				]}
				timeframe={"2022"}
				introduction={
					<>
						<LargeText>
							{translations && translations.cds.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.cds.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				className="theme-dark"
				color={"#d4ce91"}
				title={translations && translations.cds.sections[0].title}
				text={translations && translations.cds.sections[0].text}
				items={[
					{
						src: require("../../media/images/portfolio-images/costumi-di-sicilia/gallery-1/1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/costumi-di-sicilia/gallery-1/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/costumi-di-sicilia/gallery-1/3.png")
							.default,
					},
				]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.cds.sections[1].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.cds.sections[1].text}
					</LargeText>
				</ContainerBlock>
			</section>

			<section
				className="theme-light images-portfolio-section"
				style={{ background: "#d4ce91" }}
			>
				<img
					className="image-portfolio-start"
					src={
						require("../../media/images/portfolio-images/costumi-di-sicilia/image-1.jpeg")
							.default
					}
					alt={"Image 1"}
				/>
				<img
					className="image-portfolio-center"
					src={
						require("../../media/images/portfolio-images/costumi-di-sicilia/image-2.jpeg")
							.default
					}
					alt={"Image 2"}
				/>
				<img
					className="image-portfolio-end"
					src={
						require("../../media/images/portfolio-images/costumi-di-sicilia/image-3.jpeg")
							.default
					}
					alt={"Image 3"}
				/>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.cds.sections[2].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.cds.sections[2].text1}
					</LargeText>
					<LargeText>
						{translations && translations.cds.sections[2].text2}
					</LargeText>
				</ContainerBlock>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/costumi-di-sicilia/video-1.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column className="w-50">
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/costumi-di-sicilia/video-2.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

CostumiDiSicilia.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default CostumiDiSicilia;
