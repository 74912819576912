import styled from "styled-components";
import { bgGrey, colorGrey4 } from "../../constants/colors";
import { phone } from "../../constants/screens";

export const PeopleOfMasamuneWrapper = styled.section`
	background: #fff;
	padding: 150px 0;

	> h2 {
		text-align: center;
		color: ${bgGrey};
		margin-bottom: 1em;
	}

	.base-info {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding-bottom: 10px;
	}

	.wrapper {
		.width-min {
			width: 30%;
		}

		.width-max {
			width: 70%;
		}
	}

	${phone} {
		padding: 50px 0;
	}
`;

export const PeopleMasamuneImage = styled.img`
	width: 100%;

	&.only-for-phone {
		display: none;
	}

	${phone} {
		&.no-phone {
			display: none;
		}

		&.only-for-phone {
			display: block;
			margin-bottom: 10px;
		}
	}
`;

export const PeopleMasamuneItem = styled.div`
	border-bottom: 1px solid ${colorGrey4};
	margin-bottom: 20px;
`;

export const PeopleMasamuneItemFullname = styled.span`
	width: 33.3333%;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0px;
	text-transform: uppercase;
`;

export const PeopleMasamuneItemRole = styled.span`
	width: 33.3333%;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0px;
	margin-left: auto;
`;

export const PeopleMasamuneItemIcon = styled.img`
	width: 22px !important;
	margin-left: auto;

	&:hover {
		cursor: pointer;
	}
`;

export const PeopleMasamuneBody = styled.div`
	width: 100%;
	max-height: 0;
	overflow: hidden;
	padding-top: 10px;
	transition: max-height 0.6s ease-in-out;

	&.open {
		border-top: 1px solid ${colorGrey4};
		max-height: 1000px;
		overflow: auto;
	}
`;
