import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { any } from "prop-types";

const PageAnimationHandler = ({
	children,
	className,
	from = { overflow: "hidden", width: "0%", height: "0%" },
	to = { overflow: "visible", width: "100%", height: "100%" },
}) => {
	const content = useRef();

	useEffect(() => {
		gsap.to(content.current, to);
	}, []);

	return (
		<div className={className} style={from} ref={content}>
			{children}
		</div>
	);
};

PageAnimationHandler.propTypes = {
	children: any,
	className: any,
	from: any,
	to: any,
};

export default PageAnimationHandler;
