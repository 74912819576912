import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { FullWidthVideo } from "../../ui-components/videos";
import {
	LargeText,
	MediumHeading,
	MediumTitle,
	SmallTitle,
} from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

// images
import imageCover from "../../media/images/portfolio-images/max-boutique/image-cover.jpg";
import image1 from "../../media/images/portfolio-images/max-boutique/image-1.jpg";
import image2 from "../../media/images/portfolio-images/max-boutique/image-2.jpg";

import { useTranslate } from "../../contexts/translate";

const MaxBoutique = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/trident");
		setNextPageName("Trident");
		document.title = 'Max Boutique - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Andrea Bolloni",
			image: require("../../media/team-images/Andrea Bolloni.jpg").default,
			role: translations === null ? "" : translations.about.peoples[6].role,
			description:
				translations === null ? "" : translations.about.peoples[6].description,
		},
		{
			fullname: "Luca Distefano",
			image: require("../../media/team-images/no-image.png").default,
			role: "Former Video Maker",
			description: ""
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#fff"
				title="Max Boutique"
				text={translations && translations.maxboutique.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Max Boutique"}
				services={["Digital Marketing"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.maxboutique.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.maxboutique.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.maxboutique.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<img src={image1} className="w-70" alt="image 1" />
						</Column>
						<Column className="w-60 d-flex">
							<img src={image2} className="mt-25perc" alt="image 2" />
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#020303" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<SmallTitle className="white">
								{translations && translations.maxboutique.sections[0].title}
							</SmallTitle>
						</Column>
						<Column className="w-50">
							<LargeText className="white">
								{translations && translations.maxboutique.sections[0].text1}
							</LargeText>
							<LargeText className="white">
								{translations && translations.maxboutique.sections[0].text2}
							</LargeText>
						</Column>
					</div>
				</Container>

				<ContainerBlock className="fluid" style={{ padding: "100px 50px" }}>
					<FullWidthVideo previewImage={require('../../media/images/portfolio-images/max-boutique/preview-video-1.png').default} srcVideo={require('../../media/images/portfolio-images/max-boutique/video-1.mp4').default} />
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<SmallTitle>{translations && translations.maxboutique.sections[1].title}</SmallTitle>
						</Column>
						<Column className="w-50">
							<LargeText>
								{translations && translations.maxboutique.sections[1].text}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container className="pt-50">
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>+ 800%</MediumTitle>
							<MediumHeading>Monthly orders</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>+ 1575,76%</MediumTitle>
							<MediumHeading>ROAS</MediumHeading>
						</Column>
					</div>
				</Container>

				<Container>
					<div className="wrapper fluid">
						<Column className="w-40">
							<MediumTitle>6,60%</MediumTitle>
							<MediumHeading>Conversion Rate Webinar</MediumHeading>
						</Column>
						<Column className="w-40">
							<MediumTitle>2,45%</MediumTitle>
							<MediumHeading>Conversion Rate Running School</MediumHeading>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-light mid-padding pt-0"
				style={{ background: "#fff" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-100">
							<SmallTitle>{translations && translations.maxboutique.sections[2].title}</SmallTitle>
							<LargeText className="mb-4">
								{translations && translations.maxboutique.sections[2].text}
							</LargeText>

							<FullWidthVideo previewImage={require('../../media/images/portfolio-images/max-boutique/preview-video-2.jpg').default} srcVideo={require('../../media/images/portfolio-images/max-boutique/video-2.mp4').default} />
						</Column>

					</div>
				</Container>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

MaxBoutique.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default MaxBoutique;
