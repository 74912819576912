import React, { useEffect, useState } from "react";

const ThemeContext = React.createContext();

const ThemeProvider = (props) => {
	const [colorIconDark, setColorIconDark] = useState(false);
	const [colorLangDark, setColorLangDark] = useState(false);
	const [colorScrollDark, setColorScrollDark] = useState(false);
	const [colorArrowDark, setColorArrowDark] = useState(false);
	const [colorNeedHelpDark, setColorNeedHelpDark] = useState(false);

	useEffect(() => {
		let currentTop = window.scrollY + 0;
		let elems = document.getElementsByTagName("section");

		for (let i = 0; i < elems.length; i++) {
			let item = elems[i];
			let itemTop = item.offsetTop;

			if (currentTop >= itemTop) {
				if (item.classList.contains("theme-light")) {
					setColorIconDark(true);
					setColorLangDark(true);
					setColorScrollDark(true);
					setColorArrowDark(true);
					setColorNeedHelpDark(true);
				}

				if (item.classList.contains("theme-dark")) {
					setColorIconDark(false);
					setColorLangDark(false);
					setColorScrollDark(false);
					setColorArrowDark(false);
					setColorNeedHelpDark(false);
				}
			}
		}
	}, [])

	return (
		<ThemeContext.Provider
			value={{
				colorIconDark,
				colorLangDark,
				colorScrollDark,
				colorArrowDark,
				colorNeedHelpDark,
				setColorIconDark,
				setColorLangDark,
				setColorScrollDark,
				setColorArrowDark,
				setColorNeedHelpDark
			}}
			{...props}
		/>
	);
};
const useThemeContext = () => React.useContext(ThemeContext);

export { ThemeProvider, useThemeContext };
