import { any } from "prop-types";
import React from "react";
import { colorGrey3 } from "../../constants/colors";

import { Container, Column } from "../../ui-components/columns/components";
import { LargeText } from "../../ui-components/texts";

export const DetailsProject = ({
	client,
	services = [],
	timeframe,
	introduction,
}) => {
	return (
		<section className="theme-light" style={{ background: "#fff" }}>
			<Container className="mid-padding">
				<div
					className="wrapper align-items-baseline fluid"
					style={{ maxWidth: "100%" }}
				>
					<Column className="w-30">
						<LargeText style={{ color: colorGrey3 }}>CLIENTE</LargeText>
						<LargeText>{client}</LargeText>

						<LargeText style={{ color: colorGrey3 }}>SERVIZI</LargeText>
						{services.map((item, index) => (
							<LargeText key={index}>{item}</LargeText>
						))}

						<LargeText style={{ color: colorGrey3 }}>TIMEFRAME</LargeText>
						<LargeText>{timeframe}</LargeText>
					</Column>
					<Column className="w-70">
						<LargeText style={{ color: colorGrey3 }}>INTRODUZIONE</LargeText>
						{introduction}
					</Column>
				</div>
			</Container>
		</section>
	);
};

DetailsProject.propTypes = {
	services: any,
	client: any,
	timeframe: any,
	introduction: any,
};
