import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText } from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

// images
import imageCover from "../../media/images/portfolio-images/omnama/image-cover.jpg";

import { TitleWithScrollXImagesSection } from "./components-design";
import { useTranslate } from "../../contexts/translate";

const Omnama = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/projects/biz-academy");
		setNextPageName("Biz Academy");
		document.title = 'Omnama - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Simone Scalia",
			image: require("../../media/team-images/Simone Scalia.jpg").default,
			role: translations === null ? "" : translations.about.peoples[7].role,
			description:
				translations === null ? "" : translations.about.peoples[7].description,
		},
		{
			fullname: "Claudio Mammana",
			image: require("../../media/team-images/Claudio Mammana.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[15].role,
			description:
				translations === null ? "" : translations.about.peoples[15].description,
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#f3f8ff"
				title="Omnama"
				text={translations && translations.omnama.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Omnama"}
				services={["UI/UX Design", "Sito Web", "CRO"]}
				timeframe={"2021 - Today"}
				introduction={
					<>
						<LargeText>
							{translations && translations.omnama.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.omnama.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				color={"#c2cde0"}
				white={false}
				title={translations && translations.omnama.sections[0].title}
				text={translations && translations.omnama.sections[0].text}
				items={[
					{ src: require('../../media/images/portfolio-images/omnama/gallery/1.jpg').default},
					{ src: require('../../media/images/portfolio-images/omnama/gallery/2.jpg').default},
					{ src: require('../../media/images/portfolio-images/omnama/gallery/3.jpg').default},
				]}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Omnama.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Omnama;
