import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/trc/image-cover.png";
import { Column, Container, ContainerBlock } from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import { TitleWithScrollXImagesSection } from "./components-design";

const TheRebelCompany = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/talent-sourcing");
		setNextPageName("Talent Sourcing");
		document.title =
			"The Rebel Company - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		}
	];

	return (
		<div className="project-page project-page-papini">
			<CoverPorfolio
				background="#FFEC6F"
				className="theme-dark"
				title="The Rebel Company"
				white={true}
				text={translations && translations.trc.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"The Rebel Company"}
				services={[
					"Social Video",
				]}
				timeframe={"2022"}
				introduction={
					<>
						<LargeText>
							{translations && translations.trc.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.trc.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				removeScroll={true}
				className="theme-dark"
				color={"#FFEC6F"}
				title={translations && translations.trc.sections[0].title}
				text={translations && translations.trc.sections[0].text1}
				text2={translations && translations.trc.sections[0].text2}
				items={[ { src: require('../../media/images/portfolio-images/trc/image-1.png').default }, { src: require('../../media/images/portfolio-images/trc/image-2.png').default }, { src: require('../../media/images/portfolio-images/trc/image-3.png').default } ]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<SmallTitle className="mb-4">
						{translations && translations.trc.sections[1].title}
					</SmallTitle>
					<LargeText>
						{translations && translations.trc.sections[1].text}
					</LargeText>
				</ContainerBlock>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

TheRebelCompany.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default TheRebelCompany;
