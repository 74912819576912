import { any } from "prop-types";
import React from "react";
import { Container } from "./components";

const TwoColumns = ({
	className = "",
	dark = false,
	reverse = false,
	alignItems = "center",
	children,
}) => {
	return (
		<Container
			className={
				dark ? `two-column dark ${className}` : `two-column light ${className}`
			}
		>
			<div
				className="wrapper"
				style={{
					flexDirection: reverse ? "row-reverse" : "row",
					alignItems: alignItems,
				}}
			>
				{children}
			</div>
		</Container>
	);
};

TwoColumns.propTypes = {
	className: any,
	dark: any,
	reverse: any,
	alignItems: any,
	children: any,
};

export default TwoColumns;
