import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import { LargeText, SmallHeading, SmallTitle } from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { ContainerBlock } from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";

// images
import imageCover from "../../media/images/portfolio-images/rica/cover.jpg";
import testSquare from "../../media/test-image-square.jpg";
import { ImageLeftWithText, ThreeImagesSection } from "./components-content";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { useTranslate } from "../../contexts/translate";

const Rica = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/projects/correre-naturale");
		setNextPageName("Correre Naturale");
		document.title = 'RICA - Cutting Edge Digital Creative Studio'
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		},
		{
			fullname: "Gabriele Nicolosi",
			image: require("../../media/team-images/Gabriele Nicolosi.jpeg").default,
			role: translations === null ? "" : translations.about.peoples[14].role,
			description:
				translations === null ? "" : translations.about.peoples[14].description,
		},
		{
			fullname: "Luca Distefano",
			image: require("../../media/team-images/no-image.png").default,
			role: "Former Video Maker",
			description: "",
		},
	];

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#fff"
				title="Rica"
				text={translations && translations.rica.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Rica"}
				services={["Social Video", "Photography", "Motion Graphics"]}
				timeframe={"2019"}
				introduction={
					<>
						<LargeText>
							{translations && translations.rica.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.rica.descriptions[1]}
						</LargeText>
						<LargeText>
							{translations && translations.rica.descriptions[2]}
						</LargeText>
					</>
				}
			/>

			<ImageLeftWithText
				color="#000"
				image={
					require("../../media/images/portfolio-images/rica/1.jpg").default
				}
				title={translations && translations.rica.sections[0].title}
				texts={[
					translations && translations.rica.sections[0].text1,
					translations && translations.rica.sections[0].text2
				]}
				className="pt-5 pb-5 theme-dark"
				white={true}
			/>

			<section
				className="theme-light pb-1 mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="mid" style={{ padding: "0" }}>
					<SmallTitle className="mb-5">{translations && translations.rica.sections[1].title}</SmallTitle>
					<SmallHeading>
						{translations && translations.rica.sections[1].text}
					</SmallHeading>
				</ContainerBlock>
			</section>

			<div className="theme-light mid-padding" style={{ background: "#fff" }}>
				<ContainerBlock className="mid">
					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/rica/preview-1.jpg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/rica/video-1.mp4")
								.default
						}
					/>

					<SmallHeading className="mid-padding">
						{translations && translations.rica.sections[2].text}
					</SmallHeading>

					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/rica/preview-2.jpg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/rica/video-2.mp4")
								.default
						}
					/>
				</ContainerBlock>
			</div>

			<ImageLeftWithText
				color="#000"
				image={
					require("../../media/images/portfolio-images/rica/2.jpg").default
				}
				title={translations && translations.rica.sections[3].title}
				texts={[
					translations && translations.rica.sections[3].text
				]}
				white={true}
			/>

			<ThreeImagesSection
				images={[
					require("../../media/images/portfolio-images/rica/gallery-1.jpeg")
						.default,
					require("../../media/images/portfolio-images/rica/gallery-2.jpeg")
						.default,
					require("../../media/images/portfolio-images/rica/gallery-3.jpg")
						.default,
				]}
				color="#000"
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Rica.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Rica;
