export const scrollForcedFooterHandler = (params) => {
	let currentTop = window.scrollY + 0;
	let elems = document.getElementsByTagName("section");

	for (let i = 0; i < elems.length; i++) {
		let item = elems[i];
		let itemTop = item.offsetTop;

		if (currentTop + 1 >= itemTop) {
			if (item.classList.contains("footer")) {
				params.setOnFooter(true);
			}
		}
	}
};

export const scrollDetectBackground = (params) => {
	let currentTop = window.scrollY + 0;
	let elems = document.getElementsByTagName("section");

	for (let i = 0; i < elems.length; i++) {
		let item = elems[i];
		let itemTop = item.offsetTop;

		if (currentTop + 40 >= itemTop) {
			if (item.classList.contains("theme-light")) {
				params.setColorIconDark(true);
			}
			if (item.classList.contains("theme-dark")) {
				params.setColorIconDark(false);
			}
		}

		if (window.innerWidth <= 767) {
			if (currentTop + 40 >= itemTop) {
				if (item.classList.contains("theme-light")) {
					params.setColorLangDark(true);
				}
				if (item.classList.contains("theme-dark")) {
					params.setColorLangDark(false);
				}
			}
		} else {
			if (currentTop + (window.innerHeight - 45) >= itemTop) {
				if (item.classList.contains("theme-light")) {
					params.setColorLangDark(true);
				}
				if (item.classList.contains("theme-dark")) {
					params.setColorLangDark(false);
				}
			}
		}

		if (currentTop + (window.innerHeight - 40) >= itemTop) {
			if (item.classList.contains("theme-light")) {
				params.setColorArrowDark(true);
			}
			if (item.classList.contains("theme-dark")) {
				params.setColorArrowDark(false);
			}
		}

		if (currentTop + window.innerHeight / 2 >= itemTop) {
			if (item.classList.contains("theme-light")) {
				params.setColorScrollDark(true);
			}
			if (item.classList.contains("theme-dark")) {
				params.setColorScrollDark(false);
			}
		}


		if (currentTop + (window.innerHeight / 2) + 10 >= itemTop) {
			if (item.classList.contains("theme-light")) {
				params.setColorNeedHelpDark(true);
			}
			if (item.classList.contains("theme-dark")) {
				params.setColorNeedHelpDark(false);
			}
		}
	}
};

var startSticky = null;
var startTop = null;
var startChangeTop = false;

export const scrollDetectXForced = () => {
	let currentTop = window.scrollY + 0;
	let elems = document.getElementsByTagName("section");

	let wWrapper = document.getElementsByClassName("translate-item-force")[0]
		.clientWidth;
	let nWrapperChildrens = document.getElementsByClassName(
		"translate-item-force"
	)[0].children.length;
	let maxScrollPx = wWrapper * nWrapperChildrens;

	for (let i = 0; i < elems.length; i++) {
		let item = elems[i];
		let itemTop = item.offsetTop;

		if (currentTop >= itemTop) {
			if (item.style.position == "sticky") {
				if (startSticky === null) {
					startSticky = window.scrollY;
				}

				if (!startChangeTop) {
					if (startSticky - window.scrollY >= 0) {
						document.getElementsByClassName(
							"translate-item-force"
						)[0].style.transform = `translateX(${0}px) translateZ(0)`;
					} else if (Math.abs(startSticky - window.scrollY) >= maxScrollPx) {
						document.getElementsByClassName(
							"translate-item-force"
						)[0].style.transform = `translateX(-${maxScrollPx - 250 / 2
						}px) translateZ(0)`;
						startTop = window.scrollY;
						startChangeTop = true;
					} else {
						document.getElementsByClassName(
							"translate-item-force"
						)[0].style.transform = `translateX(${startSticky - window.scrollY
						}px) translateZ(0)`;
					}
				} else {
					document.getElementsByClassName("sticky-scroll-x")[0].style.top =
						startTop - window.scrollY + "px";
				}
			}
		}
	}
};

export const startScrollHandler = (params) => {
	document.addEventListener("scroll", (e) => {
		try {
			scrollDetectBackground(params.detect);
			scrollForcedFooterHandler(params.footer);

			// prevent overflow hidden on body element
			// const element = document.querySelector(".block-scroll-body");

			// if (element == undefined) {
			// 	document.querySelector("body").removeAttribute('style');
			// 	return;
			// }

			// if (window.scrollY >= element.offsetTop) {
			// 	document.querySelector("body").style.overflow = "hidden";
			// } else {
			// 	document.querySelector("body").removeAttribute('style');
			// }
		} catch (err) {
			console.log("error", err);
		}
	});
};

export const initScrollFooter = (setForced, setOnFooter) => {
	document.querySelector("body").removeAttribute('style');

	window.addEventListener(
		"wheel",
		function (e) {
			if (
				e.deltaY > 100 &&
				window.scrollY >=
				document.querySelector("section.footer").offsetTop
			) {
				setForced(true);
			}
		},
		false
	);

	return function cleanup() {
		setOnFooter(false);
		window.removeEventListener('wheel', () => {})
	};
}

export const detectedForcedScrollFooter = (forced, setForced, nextPageHref, setOnFooter, navigate) => {
	if (!(
		forced &&
		window.scrollY >= document.querySelector("section.footer").offsetTop + 10
	)) return;
	
	setForced(false);
	let items = document.getElementsByClassName("animate-on-close");

	for (let i = 0; i < items.length; i++) {
		items[i].classList.add("close-text-animation");
	}

	setTimeout(() => {
		let sections = document.getElementsByTagName("section");
		let divs = document.getElementsByTagName("div");

		for (let i = 0; i < sections.length; i++) {
			sections[i].classList.add("bg-dark");
		}

		for (let i = 0; i < divs.length; i++) {
			divs[i].classList.add("bg-dark");
		}

		document.getElementById("root").style.display = "none";
		// setTimeout(() => window.scrollTo(0, 0), 5);
		setTimeout(() => {
			document.getElementById("root").style.display = "block";
			let items = document.getElementsByClassName("animate-on-close");

			for (let i = 0; i < items.length; i++) {
				items[i].classList.remove("close-text-animation");
			}
			
			setOnFooter(false);
			window.location.href = nextPageHref == undefined ? "/" : nextPageHref;

			// setTimeout(() => {
			// 	window.scrollTo(0, 0)

			// 	setTimeout(() => {
			// 		for (let i = 0; i < sections.length; i++) {
			// 			sections[i].classList.remove("bg-dark");
			// 		}
			
			// 		for (let i = 0; i < divs.length; i++) {
			// 			divs[i].classList.remove("bg-dark");
			// 		}
	
			// 		for (let i = 0; i < items.length; i++) {
			// 			items[i].classList.remove("close-text-animation");
			// 			items[i].classList.remove("d-none");
			// 		}
			// 	}, 200)
			// }, 100);

		}, 5);

	}, 1500);

	return function cleanup() {
		setForced(false);
	};
}