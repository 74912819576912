import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import {
	LargeText,
	MediumTitle,
	SmallHeading,
	SmallTitle,
} from "../../ui-components/texts";

import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import { FullWidthVideo } from "../../ui-components/videos";
import { ScrollXImages } from "../../ui-components/force-scroll-x";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";

import { useTranslate } from "../../contexts/translate";

const Gisira = ({ setNextPageHref = () => { }, setNextPageName = () => { } }) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/projects/etna-dolce");
		setNextPageName("Etna Dolce");
		document.title = 'Asmundo di Gisira - Cutting Edge Digital Creative Studio'
	}, []);

	const peoples = [
		{
			fullname: "Simone Ruiz",
			image: require("../../media/team-images/Simone Ruiz.jpg").default,
			role: translations === null ? "" : translations.about.peoples[1].role,
			description:
				translations === null ? "" : translations.about.peoples[1].description,
		},
		{
			fullname: "Andrea Carollo",
			image: require("../../media/team-images/Andrea Carollo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[4].role,
			//description:
				//translations === null ? "" : translations.about.peoples[4].description,
			description: "",
		},
		{
			fullname: "Michela Giuffrida",
			image: require("../../media/team-images/Michela Giuffrida.jpg").default,
			role: translations === null ? "" : translations.about.peoples[2].role,
			//description:
				//translations === null ? "" : translations.about.peoples[2].description,
			description: "",
		}
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#fff"
				title="Asmundo di Gisira"
				text={translations && translations.gisira.intro}
				image={require('../../media/images/portfolio-images/gisira/image-cover.jpg').default}
			/>

			<DetailsProject
				client={"Asmundo di Gisira"}
				services={["Social Video", "Photography", "Digital Strategy"]}
				timeframe={"2020"}
				introduction={
					<>
						<LargeText>
							{translations && translations.gisira.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.gisira.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<FullWidthVideo previewImage={require('../../media/images/portfolio-images/gisira/preview-video-1.jpg').default} srcVideo={require('../../media/images/portfolio-images/gisira/video-1.mp4').default} />
				</ContainerBlock>
			</section>

			<section
				className="theme-dark mid-padding"
				style={{ background: "#323042" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<MediumTitle className="white">
								{translations && translations.gisira.sections[0].title}
							</MediumTitle>
						</Column>
						<Column className="w-50">
							<SmallHeading className="white mb-5">
								{translations && translations.gisira.sections[0].text}
							</SmallHeading>
						</Column>
					</div>
				</Container>

				<ContainerBlock className="fluid" style={{ padding: "0 50px" }}>
					<FullWidthVideo previewImage={require('../../media/images/portfolio-images/gisira/preview-video-2.jpg').default} srcVideo={require('../../media/images/portfolio-images/gisira/video-2.mp4').default} />

					<SmallHeading className="white" style={{ padding: "50px 0" }}>
						{translations && translations.gisira.sections[1].text}
					</SmallHeading>

					<FullWidthVideo previewImage={require('../../media/images/portfolio-images/gisira/preview-video-3.jpeg').default} srcVideo={require('../../media/images/portfolio-images/gisira/video-3.mp4').default} />
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="fluid pt-1" style={{ padding: "0 50px" }}>
					<MediumTitle className="black">
						{translations && translations.gisira.sections[2].title}
					</MediumTitle>
					<SmallHeading className="black mb-5">
						{translations && translations.gisira.sections[2].text}
					</SmallHeading>
				</ContainerBlock>
			</section>

			<section
				className="theme-light mid-padding pt-0"
				style={{ background: "#fff" }}
			>
				<Container className="fluid" style={{ padding: "0 50px" }}>
					<div className="wrapper fluid">
						<Column className="pt-0 ps-0 w-50">
							<img
								src={
									require("../../media/images/portfolio-images/gisira/gallery-1/1.jpg")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								className="mt-5"
								src={
									require("../../media/images/portfolio-images/gisira/gallery-1/3.jpg")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
						<Column className="pt-0 pe-0 w-50">
							<img
								src={
									require("../../media/images/portfolio-images/gisira/gallery-1/2.jpg")
										.default
								}
								alt={"Image Test"}
							/>
							<img
								className="mt-5"
								src={
									require("../../media/images/portfolio-images/gisira/gallery-1/4.jpg")
										.default
								}
								alt={"Image Test"}
							/>
						</Column>
					</div>
				</Container>
			</section>

			<section
				className="theme-dark mid-padding overflow-hidden"
				style={{ background: "#FDE493" }}
			>
				<Container>
					<div className="wrapper fluid">
						<Column className="w-50">
							<SmallTitle>
								{translations && translations.gisira.sections[3].title}
							</SmallTitle>
						</Column>
						<Column className="w-50">
							<LargeText>
								{translations && translations.gisira.sections[3].text}
							</LargeText>
						</Column>
					</div>
				</Container>
				<Container>
					<div className="wrapper fluid">
						<Column>
							<ScrollXImages
								className="theme-light no-padding light-section"
								style={{ background: "#FDE493" }}
								items={[
									{ src: require('../../media/images/portfolio-images/gisira/gallery-2/1.jpeg').default },
									{ src: require('../../media/images/portfolio-images/gisira/gallery-2/2.jpg').default },
									{ src: require('../../media/images/portfolio-images/gisira/gallery-2/3.jpg').default },
								]}
							/>
						</Column>
					</div>
				</Container>

			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
			   <div className="cta-single-project-inner">
                    <p>{translations && translations.ctasingleproject.text}</p>
			        <PopupButton id="egC3mNyH">{translations && translations.ctasingleproject.button}</PopupButton>
			  </div>
			</div>
		</div>
	);
};

Gisira.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Gisira;
